type ToggleTagProps = {
  text: string;
  checked?: boolean;
  onClick: () => void;
  disabled?: boolean;
  clasName?: string;
};

function ToggleTag({
  text,
  checked,
  onClick,
  disabled,
  clasName,
}: ToggleTagProps) {
  return (
    <div
      role="button"
      onKeyDown={() => {}}
      tabIndex={0}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      className={`inline-block px-3 py-2.5 text-sm text-white100 rounded-lg border border-white10 transition ${
        checked ? "bg-white10 border-transparent" : "border-white10"
      } ${
        disabled
          ? "cursor-not-allowed text-white10"
          : "cursor-pointer hover:opacity-60"
      } ${clasName}`}
    >
      {text}
    </div>
  );
}
ToggleTag.defaultProps = {
  checked: false,
  disabled: false,
  clasName: "",
};

export default ToggleTag;
