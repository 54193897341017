import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { otherServicesData } from "./data";
import OtherServiceItem from "./OtherServiceItem";
import alejandro from "../assets/team/alejandro.png";
import BookCallWith from "../common/bookCallWith";
import serviceBg1 from "../assets/services/bgMain1Gen.png";
import serviceBg2 from "../assets/services/bgSecondary1.png";
import serviceBg3 from "../assets/services/bgMain2Gen.png";
import BookCallDontFind from "../common/bookCallDontFind";
import OffersSelector from "../home/OffersSelector";
import ProjectsMetrics from "../common/projectsMetrics/ProjectsMetrics";
import Button from "../common/button/Button";
import { ReactComponent as ChevronRight } from "../assets/images/chevronRight.svg";

function Services() {
  const location = useLocation();
  useEffect(() => {
    if (location?.hash) {
      const scrollTop =
        document.getElementById(location.hash.slice(1, location.hash.length))
          ?.offsetTop || 100;
      window.scrollTo({
        top: scrollTop - 100,
        behavior: "smooth",
      });
    }
  }, [location?.hash]);

  const handleScrollToSelector = () => {
    const protofireOffersId = document.getElementById("protofireOffers");
    if (protofireOffersId) {
      window.scrollTo({
        top: protofireOffersId.offsetTop - 100,
        behavior: "smooth",
      });
    }
  };

  const handleScrollToAllServices = () => {
    const allServicesId = document.getElementById("allServices");
    if (allServicesId) {
      window.scrollTo({
        top: allServicesId.offsetTop - 100,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="pb-[150px]">
      <Helmet>
        <title>Custom Blockchain Solutions & Services | Protofire</title>
        <meta
          name="description"
          content="Explore Protofire's comprehensive blockchain solutions and services. From Safe deployment to custom DeFi protocols, we offer tailored Web3 development to grow TVL, attract developers, and enhance user engagement. Book a free consultation today."
        />
        <meta
          name="keywords"
          // content="blockchain solutions, Web3 development, DeFi services, Smart Contract Development, Safe deployment, Blockscout, Uniswap integration, The Graph, RPC Node Hosting, blockchain audit, oracle integration, staking modules, CDP development, blockchain insurance, Web3 marketing"
          content="blockchain solutions, Web3 development, DeFi services, Smart Contract Development, Safe deployment, Blockscout, Uniswap integration, The Graph, RPC Node Hosting, blockchain audit, oracle integration, CDP development, Web3 marketing"
        />
      </Helmet>

      <div
        className="bg-no-repeat bg-contain"
        style={{ backgroundImage: `url(${serviceBg1})` }}
      >
        <div
          className="bg-no-repeat bg-center bg-auto xl:bg-[length:100%_505px]"
          style={{ backgroundImage: `url(${serviceBg2})` }}
        >
          <div className="max-w-[1120px] mx-auto pt-[80px]">
            <section className="mx-4 md:mx-6 xl:mx-0">
              <div className="md:max-w-[832px] xl:max-w-[754px] pt-[100px] md:pt-[120px]">
                <h1
                  className="text-white100 text-[32px] leading-[40px] md:text-5xl md:leading-[56px] xl:text-[64px] xl:leading-[72px] header1 sm:block md:m-auto font-bold inline"
                  style={{ fontFamily: "Onest-SemiBold" }}
                >
                  Solutions & Services
                </h1>
                <p className="mt-4 mb-0 text-white55 text-base md:text-lg">
                  Apart from building dedicated, results-oriented development
                  teams with guaranteed outcomes, we offer full-cycle services —
                  including ideation, implementation, and support — and have 10+
                  market-validated, proven solutions to grow TVL and MAU for
                  L1/L2 chains and DeFi networks.
                </p>
              </div>
              <div className="mt-4 flex">
                <Button
                  className="py-2 mt-3 mr-3 flex items-center"
                  size="lg"
                  variant="outlined"
                  onClick={handleScrollToSelector}
                >
                  <span className="mr-3 text-sm">
                    Show Solutions for TVL & MAU
                  </span>
                  <ChevronRight className="opacity-60" />
                </Button>
                <Button
                  className="py-2 mt-3 mr-3 flex items-center"
                  size="lg"
                  variant="outlined"
                  onClick={handleScrollToAllServices}
                >
                  <span className="mr-3 text-sm">Explore All Services</span>
                  <ChevronRight className="opacity-60" />
                </Button>
              </div>
            </section>
            <section className="flex flex-col md:flex-row pt-[120px] mx-4 md:mx-6 xl:mx-0">
              <ProjectsMetrics />
            </section>
            <div className="mt-[80px] md:mt-[100px] pb-[80px] md:pb-[100px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />
          </div>
        </div>

        <div className="max-w-[1120px] mx-auto">
          <section className="mx-4 md:mx-6 xl:mx-0" id="protofireOffers">
            <OffersSelector isFullyVisible />
          </section>
        </div>
      </div>

      <div
        className="bg-no-repeat bg-auto bg-right-top xl:bg-[length:1200px_1500px]"
        style={{ backgroundImage: `url(${serviceBg3})` }}
      >
        <div className="max-w-[1120px] mx-auto">
          <BookCallDontFind />
          <div className="flex justify-center mt-6">
            <Button
              className="py-2 mt-3 mr-3 flex items-center"
              size="lg"
              variant="outlined"
              onClick={handleScrollToAllServices}
            >
              <span className="mr-3 text-sm">Explore All Services</span>
              <ChevronRight className="opacity-60" />
            </Button>
          </div>

          <div className="my-[120px] md:my-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />

          <section className="mt-[100px] mx-4 md:mx-6 xl:mx-0" id="allServices">
            <h2
              className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]"
              style={{ fontFamily: "Onest-SemiBold" }}
            >
              All Services
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 md:gap-8">
              {otherServicesData.map((item, index) => (
                <OtherServiceItem
                  key={item.title}
                  indexNumber={`0${index + 1}`}
                  title={item.title}
                  link={item.link}
                  isOuterLink={item.isOuterLink || false}
                  subItems={item.items}
                  className="mt-[100px]"
                />
              ))}
            </div>
          </section>

          <div className="my-[120px] md:my-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />

          <BookCallWith
            imgUrl={alejandro}
            name="Alejandro Losa"
            lnUrl="https://www.linkedin.com/in/alejandrolosa/"
            calendlyCallPostfix="alejandro-protofire"
            text="Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution."
          />
        </div>
      </div>
    </div>
  );
}

export default Services;
