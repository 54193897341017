/* eslint-disable jsx-a11y/anchor-is-valid */
/// <reference types="react-scripts" />
import { useState } from "react";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import dividerLine from "../assets/home/divider-line.svg";
import { partnersLogos } from "./partnersLogos";
import Button from "../common/button/Button";
import StudiesProjectsCards from "./studiesProjectsCards";
import Metrics from "./metrics";
import TeamCarousel from "../common/ourTeam";
import ProtofireCarousel from "../common/protofireCarousel";
import ProjectsMetrics from "../common/projectsMetrics/ProjectsMetrics";
import GrowTvlAdoption from "../common/growTvlAdoption";
import OffersSelector from "./OffersSelector";
import { ReactComponent as ChevronRightDouble } from "../assets/images/chevronRightDouble.svg";
import { ReactComponent as ChevronRight } from "../assets/images/chevronRight.svg";
import "./home.css";
import BookCallDontFind from "../common/bookCallDontFind";
import worldMap from "../assets/home/world-map.png";
import bgHomeSecondary1XL from "../assets/home/bgHomeSecondary1XL.png";
import bgHomeSecondary1Tablet from "../assets/home/bgHomeSecondary1Tablet.png";
import bgHomeMain1MD from "../assets/home/bgHomeMain1MD.png";
import bgHomeMain1Mobile from "../assets/home/bgHomeMain1Mobile.png";
import bgHomeMain2XL from "../assets/home/bgHomeMain2XL.png";
import bgHomeSecondary2 from "../assets/home/bgHomeSecondary2.png";
import bgHomeSecondary3XL from "../assets/home/bgHomeSecondary3XL.png";
import bgHomeSecondary3Tablet from "../assets/home/bgHomeSecondary3Tablet.png";
import bgHomeSecondary4XL from "../assets/home/bgHomeSecondary4XL.png";
import bgHomeSecondary4Tablet from "../assets/home/bgHomeSecondary4Tablet.png";
import bgHomeMain3XL from "../assets/home/bgHomeMain3XL.png";
import bgHomeMain4XL from "../assets/home/bgHomeMain4XL.png";
import dashedLineVertical from "../assets/home/dashedLineVertical.png";
import dashedLineHorizontal from "../assets/home/dashedLineHorizontal.png";
import { firstTriggerItems } from "../common/offersSelectorControl/data";
import ContactUs from "../contactUs";

function Home() {
  const [selectedTriggerItem, setSelectedTriggerItem] = useState("");
  const handleLeverageExperienceClick = (triggerItem: string) => {
    const protofireOffersId = document.getElementById("protofireOffers");
    if (protofireOffersId) {
      setSelectedTriggerItem(triggerItem);
      window.scrollTo({
        top: protofireOffersId.offsetTop - 100,
        behavior: "smooth",
      });
    }
  };

  const isMobile = useMediaQuery({ query: "(max-width: 833px)" });
  const isXLScreen = useMediaQuery({ query: "(min-width: 1440px)" });

  return (
    <div className="pb-[150px]">
      <Helmet>
        <title>Protofire | Blockchain Development for Web3 & DeFi</title>
        <meta
          name="description"
          content="Expert blockchain solutions for L1/L2 chains and DeFi protocols. Strategic planning and deployment to enhance TVL and usage of Web3 projects. Tailored compatibility and marketing for long-term growth."
        />
        <meta
          name="keywords"
          content="blockchain development, Web3 solutions, TVL growth, DeFi protocols, L1/L2 chains, blockchain consulting, strategic planning"
        />
      </Helmet>
      <div
        className="bg-no-repeat bg-auto bg-right-top relative"
        style={{
          backgroundImage: isMobile
            ? "none"
            : `url(${
                isXLScreen ? bgHomeSecondary1XL : bgHomeSecondary1Tablet
              })`,
        }}
      >
        <div className="hidden xl:block h-[160px] w-[256px] absolute right-[260px] top-[600px]">
          <div
            className="h-full bg-no-repeat relative left-[50px]"
            style={{
              backgroundImage: `url(${dashedLineVertical})`,
            }}
          />
          <div
            className="w-full bg-no-repeat h-1 relative bottom-[50px]"
            style={{
              backgroundImage: `url(${dashedLineHorizontal})`,
            }}
          />
        </div>
        <div
          className={`bg-no-repeat ${
            isMobile ? "bg-[length:100%_100%]" : "bg-right-top bg-auto"
          }`}
          style={{
            backgroundImage: `url(${
              isMobile ? bgHomeMain1Mobile : bgHomeMain1MD
            })`,
          }}
        >
          <div
            className="bg-no-repeat bg-[length:100%_100%] xl:bg-auto bg-right-top"
            style={{
              backgroundImage: isMobile ? "none" : `url(${bgHomeMain2XL})`,
            }}
          >
            <div className="max-w-[1120px] mx-auto pt-[80px]">
              <div className="mx-4 md:mx-6 xl:mx-0 relative">
                <section className="z-10 relative sm:pt-0 lg:mt-0">
                  <div className="z-10 w-full m-0 pt-[100px] md:pt-[120px] max-w-[832px]">
                    <div>
                      <h1
                        className="text-white100 text-[40px] leading-[48px] md:text-5xl md:leading-[56px] xl:text-[64px] xl:leading-[72px] header1 sm:block md:m-auto font-bold inline"
                        style={{ fontFamily: "Onest-SemiBold" }}
                      >
                        Developer DAO
                        {/* <RandomTitle className="text-[40px] leading-[48px] md:leading-[56px] xl:text-[64px] xl:leading-[72px] ml-2" /> */}
                      </h1>
                      <ul className="mt-4 text-white100 leading-[26px] text-base md:text-lg">
                        <li className="mt-4 flex items-center ">
                          <ChevronRightDouble className="mr-4 min-w-[20px]" />
                          <span>
                            10+ market-validated solutions to grow TVL and MAU
                            for L1/L2 chains and DeFi networks
                          </span>
                        </li>
                        <li className="mt-4 flex items-center">
                          <ChevronRightDouble className="mr-4 min-w-[20px]" />
                          <span>
                            Result-oriented engagements and guaranteed outcomes
                          </span>
                        </li>
                        <li className="mt-4 flex items-center">
                          <ChevronRightDouble className="mr-4 min-w-[20px]" />
                          <span>
                            In-house and 3rd party components to accelerate
                            delivery and drive down the costs
                          </span>
                        </li>
                        <li className="mt-4 flex items-center">
                          <ChevronRightDouble className="mr-4 min-w-[20px]" />
                          <span>Ideation, implementation, and support</span>
                        </li>
                      </ul>

                      <div className="mt-10">
                        <p className="text-white55 text-base mt-0 mb-2">
                          Leverage our experience:
                        </p>
                        <div className="flex flex-wrap max-w-[520px]">
                          {firstTriggerItems.map((triggerItem) => (
                            <Button
                              key={`leverage-exp-btn-${triggerItem}`}
                              className="py-2 mt-3 mr-3 flex items-center"
                              size="lg"
                              variant="outlined"
                              onClick={() => {
                                handleLeverageExperienceClick(triggerItem);
                              }}
                            >
                              <span className="mr-3 text-sm">
                                {triggerItem}
                              </span>
                              <ChevronRight className="opacity-60" />
                            </Button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hidden xl:block h-[160px] w-[256px] absolute -left-[100px] top-[680px]">
                    <div
                      className="h-full bg-no-repeat relative left-[50px]"
                      style={{
                        backgroundImage: `url(${dashedLineVertical})`,
                      }}
                    />
                    <div
                      className="w-full bg-no-repeat h-1 relative bottom-[50px]"
                      style={{
                        backgroundImage: `url(${dashedLineHorizontal})`,
                      }}
                    />
                  </div>
                </section>
              </div>
              <section className="flex flex-col md:flex-row py-[120px] mx-4 md:mx-6 xl:mx-0">
                <ProjectsMetrics />
              </section>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[1120px] mx-auto">
        <section className="mx-4 md:mx-6 xl:mx-0">
          <h2
            className="text-white100 text-[32px] leading-[40px] md:text-[40px] mb:leading-[48px] xl:text-[48px] xl:leading-[56px]"
            style={{ fontFamily: "Onest-SemiBold" }}
          >
            Our Clients and Partners
          </h2>
          <p className="mt-4 mb-0 text-white55 text-base md:text-lg">
            Trusted by 60+ Networks and 95+ Protocols
          </p>
        </section>
      </div>

      <section className="mt-[40px] mx-4 md:mx-6 xl:mx-0">
        <Marquee autoFill>
          <div>
            <ul>
              {partnersLogos.map((logo, index) =>
                index % 2 === 0 ? (
                  <li key={logo.name} className="inline-block">
                    <img
                      src={logo.src}
                      className="h-8 md:h-12 xl:h-16 m-2 md:m-4"
                      alt="protofire partners logos"
                    />
                  </li>
                ) : (
                  ""
                )
              )}
            </ul>
            <ul className="ml-[24px] md:ml-[40px] xl:ml-[48px]">
              {partnersLogos.map((logo, index) =>
                index % 2 === 1 ? (
                  <li key={logo.name} className="inline-block">
                    <img
                      src={logo.src}
                      className="h-8 md:h-12 xl:h-16 m-2 md:m-4"
                      alt="protofire partners logos"
                    />
                  </li>
                ) : (
                  ""
                )
              )}
            </ul>
          </div>
        </Marquee>
      </section>

      <div className="max-w-[1120px] mx-auto">
        <div
          className="h-[1px] mt-[100px] hidden xl:block"
          style={{
            background: `url(${dividerLine})`,
          }}
        />
      </div>
      <section id="protofireOffers">
        <div className="max-w-[1120px] mx-auto">
          <div className="mt-[86px] xl:mt-[100px] mx-4 md:mx-6 xl:mx-0">
            <div className="max-w-[832px]">
              <h2
                className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]"
                style={{ fontFamily: "Onest-SemiBold" }}
              >
                What Protofire Offers?
              </h2>
              <p className="mt-4 mb-0 text-white55 text-base md:text-lg">
                Discover tailored solutions designed to drive growth in TVL and
                usage for your web3 projects.
              </p>
              <p className="mt-4 mb-0 text-white55 text-base md:text-lg">
                At Protofire, our developer DAO ensures aligned incentives,
                providing an array of services that can be easily filtered to
                match your specific needs. Whether you’re part of a leading
                L1/L2 network, managing a DeFi, RWA, or NFT protocol, or a
                company onboarding into the blockchain space, we ideate,
                implement, and support long-term growth through strategic,
                compatibility, and marketing solutions.
              </p>
            </div>
          </div>
        </div>

        <div
          className="bg-no-repeat bg-left bg-auto"
          style={{
            backgroundImage: `url(${isMobile ? "none" : bgHomeSecondary2})`,
          }}
        >
          <div className="max-w-[1120px] mx-auto">
            <div className="mx-4 md:mx-6 xl:mx-0">
              <OffersSelector
                firstTrigger={selectedTriggerItem}
                isFullyVisible
              />
            </div>
          </div>
        </div>
        <div className="max-w-[1120px] mx-auto">
          <div className="mt-[24px] xl:mt-[32px] mx-4 md:mx-6 xl:mx-0">
            <BookCallDontFind />
            <div className="flex flex-col md:flex-row md:justify-center mt-6 md:mt-[48px]">
              <Link to="/services#allServices">
                <Button
                  variant="outlined"
                  size="md"
                  className="w-full md:w-auto"
                >
                  Explore All Services
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <div
        className="bg-no-repeat bg-top"
        style={{
          backgroundImage: isMobile
            ? "none"
            : `url(${
                isXLScreen ? bgHomeSecondary3XL : bgHomeSecondary3Tablet
              })`,
        }}
      >
        <div
          className="bg-no-repeat bg-left-top"
          style={{
            backgroundImage: `url(${bgHomeMain3XL})`,
          }}
        >
          <div
            className="bg-no-repeat bg-right-top"
            style={{
              backgroundImage: `url(${bgHomeMain4XL})`,
            }}
          >
            <div
              className="bg-no-repeat bg-right"
              style={{
                backgroundImage: isMobile
                  ? "none"
                  : `url(${
                      isXLScreen ? bgHomeSecondary4XL : bgHomeSecondary4Tablet
                    })`,
              }}
            >
              <div className="max-w-[1120px] mx-auto">
                <section className="pt-[70px] mt-[30px] mx-4 md:mx-6 xl:mx-0">
                  <div className="max-w-[832px]">
                    <h2
                      className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]"
                      style={{ fontFamily: "Onest-SemiBold" }}
                    >
                      Case Studies & Projects
                    </h2>
                    <p className="mt-4 mb-0 text-white55 text-base md:text-lg">
                      Trusted by 60+ Networks and 95+ Protocols
                    </p>
                  </div>

                  <StudiesProjectsCards />
                  <div className="flex flex-col md:flex-row md:justify-center mt-6 md:mt-[56px]">
                    <Link to="/projects">
                      <Button
                        variant="outlined"
                        size="md"
                        className="w-full md:w-auto"
                      >
                        View all
                      </Button>
                    </Link>
                  </div>
                </section>

                <div
                  className="h-[1px] mt-[100px] hidden xl:block"
                  style={{
                    background: `url(${dividerLine})`,
                  }}
                />

                <section className="mt-[100px] mx-4 md:mx-6 xl:mx-0">
                  <div className="max-w-[832px]">
                    <h2
                      className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]"
                      style={{ fontFamily: "Onest-SemiBold" }}
                    >
                      About Protofire
                    </h2>
                    <p className="mt-4 mb-0 text-white55 text-base md:text-lg">
                      We help the world transition into Web3 by deploying
                      solutions that unlock the organic growth of Web3 projects,
                      DAOs, and protocols.
                    </p>
                    <Link to="/about">
                      <Button
                        variant="outlined"
                        size="md"
                        className="mt-[56px] w-full md:w-auto"
                      >
                        About Protofire
                      </Button>
                    </Link>
                  </div>
                  <div className="flex flex-col xl:flex-row mt-[56px] gap-4 md:gap-8">
                    <div className="p-8 rounded-lg bg-white5 w-full xl:min-w-[336px] xl:max-w-[336px] flex flex-col justify-between">
                      <p className="text-base text-white55 m-0">
                        Brand promise
                      </p>
                      <p
                        className="text-white100 text-2xl md:text-[32px] md:leading-[40px] mt-8 mb-0"
                        style={{ fontFamily: "Onest-ExtraLight" }}
                      >
                        Our partners will see noticeable growth in value
                        transferred or value staked on their platform
                      </p>
                    </div>
                    <div className="p-8 rounded-lg bg-orange100">
                      <h2
                        className="text-black100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]"
                        style={{ fontFamily: "Onest-SemiBold" }}
                      >
                        History of Protofire since 2016
                      </h2>
                      <p
                        className="mb-0 mt-8 text-base md:text-lg xl:text-2xl"
                        style={{ fontFamily: "Onest-ExtraLight" }}
                      >
                        Protofire was born in 2016 as a Blockchain Spin-off of
                        Altoros, a new Web3 company of developers focused on
                        helping ETH ecosytem projects with Front and Backend
                        work.
                      </p>
                      <Link to="/about#protofireHistory">
                        <Button
                          variant="outlined"
                          size="md"
                          className="mt-8 bg-black100"
                        >
                          Read full story
                        </Button>
                      </Link>
                    </div>
                  </div>

                  <ProtofireCarousel />
                </section>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-[1120px] mx-auto">
          <div
            className="h-[1px] mt-[100px] hidden xl:block"
            style={{
              background: `url(${dividerLine})`,
            }}
          />

          <section className="mt-[100px] mx-4 md:mx-6 xl:mx-0">
            <div className="max-w-[832px]">
              <h2
                className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]"
                style={{ fontFamily: "Onest-SemiBold" }}
              >
                Protofire since 2016
              </h2>
              <p className="mt-4 mb-0 text-white55 text-base md:text-lg">
                Over the years, we have transformed from a small team of
                enthusiasts working on our own project into a large collective
                of engineers collaborating with many of the leading players in
                the DeFi space.
              </p>
            </div>
            <Metrics />
            <img
              src={worldMap}
              alt="protofire-world-map"
              className="mt-[56px] hidden md:block"
            />
            <div className="mt-[56px] flex flex-col gap-2 md:items-end">
              <p className="text-base text-white100">Maybe we met at:</p>
              <p className="md:max-w-[544px] text-sm text-white55 md:text-right">
                Eth Denver, ETH CC, ETH Bcn, ETH Global, Consensus, Graph Day,
                Decoded, SmartCon, AVAX Summit, EthLatam
              </p>
            </div>
          </section>

          <section className="mt-[100px] mx-4 md:mx-6 xl:mx-0">
            <div className="max-w-[832px] xl:max-w-[544px]">
              <h2
                className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]"
                style={{ fontFamily: "Onest-SemiBold" }}
              >
                Meet Our Team
              </h2>
              <p className="mt-4 mb-0 text-white55 text-base md:text-lg">
                We attract, develop, and retain senior teams dedicated to
                building lasting relationships and delivering sustained growth
                for networks, protocols, and DAOs, enabling Web3 ecosystems to
                expand their TVL organically over the long term.
              </p>
            </div>
            <Link to="/careers">
              <Button
                variant="outlined"
                size="md"
                className="mt-[56px] w-full md:w-auto"
              >
                Become a member
              </Button>
            </Link>
            <div>
              <TeamCarousel />
            </div>
          </section>

          <section className="mt-[100px] md:mt-[150px] mx-4 md:mx-6 xl:mx-0">
            {/* <JoinUs /> */}
            <GrowTvlAdoption />
          </section>
          <section className="mt-[100px] md:mt-[150px] mx-4 md:mx-6 xl:mx-0">
            <ContactUs />
          </section>
        </div>
      </div>
    </div>
  );
}

export default Home;
