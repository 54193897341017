import MailchimpSubscribe from "react-mailchimp-subscribe";
import MailchimpNameEmailForm from "./mailChimpNameEmailForm";

function JoinUs() {
  return (
    <MailchimpSubscribe
      url="https://protofire.us14.list-manage.com/subscribe/post?u=d8327a6c9901fa5484b082c67&id=c1003e2516&f_id=0021ede0f0"
      render={({ status, message, subscribe }) => (
        <MailchimpNameEmailForm
          message={message}
          status={status}
          onValidated={(formData) => subscribe(formData)}
        />
      )}
    />
  );
}

export default JoinUs;
