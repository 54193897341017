/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Helmet } from "react-helmet";
import { useMemo, useState } from "react";
import projectsBg from "../assets/projects/bgMain1.png";
import { projects, tags } from "./data";
import StudyProjectCard from "../common/studyProjectCard";
import ToggleTag from "../common/offersSelectorControl/ToggleTag";
import ContactUs from "../contactUs";

const getItemXlGridColSpan = (index: number): string => {
  switch (index % 5) {
    case 0:
    case 1:
      return "xl:col-span-3";
    default:
      return "xl:col-span-2";
  }
};

function Projects() {
  const [selectedTag, setSelectedTag] = useState<string>(tags[0]);

  const filteredProjects = useMemo(() => {
    if (selectedTag === tags[0]) {
      return projects;
    }
    return projects.filter((project) => project.tagList.includes(selectedTag));
  }, [selectedTag]);

  return (
    <div
      className="bg-no-repeat bg-auto"
      style={{ backgroundImage: `url(${projectsBg})` }}
    >
      <div className="max-w-[1120px] mx-auto pt-[80px]">
        <Helmet>
          <title>Web3 Projects & Solutions | Protofire</title>
          <meta
            name="description"
            content="Explore Protofire's diverse blockchain projects. From DeFi and oracles to smart contracts and wallets. See how we bring Web3 visions to reality."
          />
          <meta
            name="keywords"
            content="Web3 development project, DeFi solutions, Smart contract integration, Blockchain infrastructure"
          />
        </Helmet>
        <section className="mx-4 md:mx-6 xl:mx-0">
          <div className="md:max-w-[544px] pt-[100px] md:pt-[120px] text-white100">
            <h1
              className="text-[32px] leading-[40px] md:text-5xl md:leading-[56px] xl:text-[64px] xl:leading-[72px]"
              style={{ fontFamily: "Onest-SemiBold" }}
            >
              Case Studies & Projects
            </h1>
            <p className="mt-4 mb-0 text-base md:text-lg">
              Trusted by 60+ Networks and 95+ Protocols. We bring your vision to
              reality through a combination of technical and management
              expertise.
            </p>
          </div>
        </section>
        <section className="my-[120px] md:my-[150px] mx-4 md:mx-6 xl:mx-0">
          <h3 className="mb-3 md:mb-5 text-base md:text-2xl text-white100">
            Select a #hashtag of what you are looking for:
          </h3>
          {tags.map((tag) => (
            <ToggleTag
              clasName="mr-2 mt-2"
              key={tag}
              onClick={() => {
                if (tag !== selectedTag) {
                  setSelectedTag(tag);
                }
              }}
              checked={tag === selectedTag}
              text={tag}
            />
          ))}
          <div className="flex flex-col md:grid grid-cols-6 gap-8 mt-12">
            {filteredProjects.map((projectItem, index) => (
              <StudyProjectCard
                key={projectItem.id}
                imgSrc={projectItem.img}
                title={projectItem.title}
                tagList={projectItem.tagList}
                text={projectItem.text}
                link={projectItem.link}
                className={`col-span-6 md:col-span-3 ${getItemXlGridColSpan(
                  index
                )}`}
              />
            ))}
          </div>
          <div className="mt-[120px] md:mt-[150px]">
            <ContactUs />
          </div>
        </section>
      </div>
    </div>
  );
}

export default Projects;
