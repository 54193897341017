/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/dot-notation */
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as ChevronDown } from "../assets/images/checvronDown.svg";
import { ReactComponent as ChevronUp } from "../assets/images/checvronUpOrange.svg";
import oppAvatarCenter from "../assets/career/oppAvatarCenter.png";
import oppAvatarCenterLeft from "../assets/career/oppAvatarCenterLeft.png";
import oppAvatarLeftLeft from "../assets/career/oppAvatarLeftLeft.png";
import oppAvatarCenterRight from "../assets/career/oppAvatarCenterRight.png";
import oppAvatarRightRight from "../assets/career/oppAvatarRightRight.png";
import { content } from "./content";
import careerBg1 from "../assets/career/bgMain1Gen.png";
import careerBg2 from "../assets/career/bgSecondary1.png";
import careerBg3 from "../assets/career/bgMain2Gen.png";
import careerBg4 from "../assets/career/bgSecondary2.png";
import careerBg5 from "../assets/career/bgMain3Gen.png";
import careerBg6 from "../assets/career/bgSecondary3.png";
import circlesTriangle from "../assets/career/circlesTriangle.png";

import "./careers.css";
import Button from "../common/button/Button";
import ProjectsMetrics from "../common/projectsMetrics/ProjectsMetrics";
import AppProcess from "./appProcess/AppProcess";
import ProtofireCarousel from "../common/protofireCarousel";

function Careers() {
  const isMobile = useMediaQuery({ query: "(max-width: 833px)" });
  const location = useLocation();
  const [vacancies, setVacancies] = useState<any>([]);
  const searchParams = new URLSearchParams(location.search);
  const vacancyId = searchParams.get("vacancyid");
  const [activeVacancyIndex, setActiveVacancyIndex] = useState<string | null>(
    vacancyId
  );

  useEffect(() => {
    fetch("https://welcome.altoros.com/vacancies-protofire/")
      .then((res) => res.json())
      .then(
        (result) => {
          setVacancies(result);
        },
        () => {}
      );
  }, []);

  const handleBecomeMemberClick = () => {
    const openOpportunitiesId = document.getElementById("openOpportunities");
    if (openOpportunitiesId) {
      window.scrollTo({
        top: openOpportunitiesId.offsetTop - 100,
        behavior: "smooth",
      });
    }
  };

  const goToViolation = (id: string) => {
    const scrollTop = document.getElementById(id)?.offsetTop || 100;
    window.scrollTo({
      top: scrollTop - 100,
      behavior: "smooth",
    });
    setActiveVacancyIndex(id);
  };

  useEffect(() => {
    if (vacancyId && vacancies.length > 0) {
      goToViolation(`vacancy-${vacancyId}`);
      if (vacancies.length > 0) setActiveVacancyIndex(vacancyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancies]);

  const handleVacancyClick = (id: string) => {
    setActiveVacancyIndex(activeVacancyIndex === id ? null : id);
  };

  const displayVacancies = () => {
    return vacancies.map((item: any, index: number) => {
      const desc = item["desc"]["body"];
      // eslint-disable-next-line prefer-destructuring
      const hr = item["hr"];
      // replace skype: from the links
      const replacedSkype = hr.replaceAll("skype:", "");
      const parser = new DOMParser();
      const doc = parser.parseFromString(replacedSkype, "text/html");
      const div = doc.querySelector("div.vacancy-manager");
      const img = doc.querySelector("img.attachment-avatar");

      if (img instanceof HTMLImageElement) {
        img.alt = `Looking for a position in a challenging Web3 project? Join Protofire and contribute to the world's top blockchain protocols and networks.`;
      }

      const divHtml = div! && div.outerHTML;

      return (
        <div
          key={item["title"]}
          className="bg-white5 px-6 xl:px-8 pb-6 xl:pb-8"
        >
          <Link
            to={`/careers?vacancyid=${index}`}
            state={{ name: item["title"] }}
          >
            <div
              id={`vacancy-${index}`}
              className={`${
                activeVacancyIndex === `${index}`
                  ? "text-orange100"
                  : "text-white100"
              } cursor-pointer text-lg xl:text-2xl pt-6 xl:pt-8 flex items-center`}
              onClick={() => handleVacancyClick(`${index}`)}
              style={{
                fontFamily: "Onest-SemiBold",
              }}
            >
              {activeVacancyIndex === `${index}` ? (
                <ChevronUp />
              ) : (
                <ChevronDown />
              )}
              <span className="ml-4 md:ml-6">{item["title"]}</span>
            </div>
          </Link>
          <div
            className={`${
              activeVacancyIndex === `${index}` ? "grid" : "hidden"
            }  lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 text-sm text-white55 lg:gap-20 sm:gap-8 mt-8 xl:mt-14`}
            style={{
              fontFamily: "Onest-Medium",
            }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: desc }}
              className="leftDiv col-span-2"
            />
            <div dangerouslySetInnerHTML={{ __html: divHtml }} className="" />
          </div>
        </div>
      );
    });
  };

  return (
    <div
      className="bg-no-repeat bg-auto pb-[150px]"
      style={{ backgroundImage: `url(${careerBg1})` }}
    >
      <Helmet>
        <title>Home/Careers: Career Opportunities | Protofire</title>
        <meta
          name="description"
          content="Explore exciting blockchain career opportunities at Protofire DAO. Join our community of Web3 enthusiasts and contribute to groundbreaking projects. Grow your skills while helping to shape the future of decentralized technology."
        />
        <meta
          name="keywords"
          content="blockchain jobs, Web3 careers, cryptocurrency jobs, blockchain developer, Protofire DAO, decentralized technology, blockchain enthusiasts, crypto careers, DeFi jobs, smart contract developer"
        />
      </Helmet>
      <div
        className="bg-no-repeat bg-bottom bg-auto xl:bg-[length:100%_667px]"
        style={{ backgroundImage: `url(${careerBg2})` }}
      >
        <div className="max-w-[1120px] mx-auto pt-[80px]">
          <section className="mx-4 md:mx-6 xl:mx-0">
            <div className="md:max-w-[832px] xl:max-w-[754px] pt-[100px] md:pt-[120px]">
              <h1
                className="text-white100 text-[32px] leading-[40px] md:text-5xl md:leading-[56px] xl:text-[64px] xl:leading-[72px] header1 sm:block md:m-auto font-bold inline"
                style={{ fontFamily: "Onest-SemiBold" }}
              >
                Web3 Wizards and Gurus Wanted
              </h1>
              <p className="mt-4 mb-0 text-white55 text-base md:text-lg">
                We are a community of blockchain enthusiasts and engineers who
                love blockchain and crypto.
              </p>
              <p className="mt-3 mb-0 text-white55 text-base md:text-lg">
                We attract, develop, and retain senior teams dedicated to
                building lasting relationships and delivering sustained growth
                for networks, protocols, and DAOs, enabling Web3 ecosystems to
                expand their TVL organically over the long term.
              </p>
              <Button
                size="md"
                className="mt-[56px] w-full md:w-auto"
                onClick={handleBecomeMemberClick}
              >
                Become a member
              </Button>
            </div>
          </section>

          <section className="flex flex-col md:flex-row mt-[100px] mx-4 md:mx-6 xl:mx-0">
            <ProjectsMetrics />
          </section>

          <div className="my-[120px] md:my-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />
        </div>
      </div>
      <div className="max-w-[1120px] mx-auto">
        <section className="mt-[100px] mx-4 md:mx-6 xl:mx-0">
          <h2
            className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]"
            style={{ fontFamily: "Onest-SemiBold" }}
          >
            Why Protofire?
          </h2>
          <div className="mt-[56px] md:mt-[100px] grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 xl:gap-8">
            <p className="my-0 text-lg text-white100">What is Protofire DAO</p>
            <p className="my-0 text-base text-white55">
              We help the world transition into web3 in a collaborative
              environment with aligned incentives. We accelerate crypto mass
              adoption by deploying solutions that unlock organic growth in
              Networks, DAOs, and protocols.
            </p>
          </div>
          <div className="hidden md:block my-10 border-t border-dashed border-white15" />
          <div className="mt-[56px] md:mt-[100px] grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 xl:gap-8">
            <p className="my-0 text-lg text-white100">Our Goal</p>
            <div className="my-0 text-base text-white55">
              Is to help web3 to onboard:
              <ul className="pl-5 mt-3">
                <li className="list-disc">1B users and</li>
                <li className="list-disc">
                  $750B+ of TVL (Total Value Locked) into crypto ecosystems by
                  developing 10%+ of the early code base at 50 major crypto
                  economies within the next 10 years.
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-[100px] border-t border-dashed border-white15" />
        </section>

        <section className="mt-[100px] mx-4 md:mx-6 xl:mx-0">
          <h2
            className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]"
            style={{ fontFamily: "Onest-SemiBold" }}
          >
            Our Values
          </h2>
          {/* <div className="mt-[56px] md:mt-[100px] grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 xl:gap-8">
            <p className="my-0 text-lg text-white100 md:max-w-[256px]">
              We work with customers who believe in technical possibilities
              provided by the blockchain technology.
            </p>
            <div className="my-0 text-base text-white55">
              <ul className="pl-5">
                <li className="list-disc">
                  We learn from successes and failures.
                </li>
                <li className="list-disc">
                  We make the most out of the lessons learned.
                </li>
                <li className="list-disc">
                  We test new ideas to keep improving performance.
                </li>
                <li className="list-disc">
                  We develop ourselves and help each other to grow personally
                  and grow the business.
                </li>
              </ul>
            </div>
          </div>
          <div className="hidden md:block mt-10 border-t border-dashed border-white15" /> */}
          {/* <div className="grid grid-cols-1 mt-10 md:grid-cols-2 gap-2 md:gap-4 xl:gap-8"> */}
          <div className="mt-[56px] md:mt-[100px] grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 xl:gap-8">
            <p className="my-0 text-lg text-white100 md:max-w-[256px]">
              We are a psychologically safe organization and encourage people to
              make their own decisions
            </p>
            <div className="my-0 text-base text-white55">
              <ul className="pl-5">
                <li className="list-disc">
                  We are empowered to test things out, take calculated risks and
                  make wise decisions by using our knowledge, expertise, and
                  common sense.
                </li>
                <li className="list-disc">
                  We show openness to possible failure.
                </li>
                <li className="list-disc">
                  We admit mistakes openly and share lessons learned widely.
                </li>
                <li className="list-disc">
                  We are building the organization with the help of sustainable
                  agile processes which enable us to work in a humane over
                  heroic approach.
                </li>
              </ul>
            </div>
          </div>
          <div className="hidden md:block mt-10 border-t border-dashed border-white15" />
          <div className="grid grid-cols-1 mt-10 md:grid-cols-2 gap-2 md:gap-4 xl:gap-8">
            <p className="my-0 text-lg text-white100 md:max-w-[256px]">
              We address our responsibilities proactively and autonomously
            </p>
            <div className="my-0 text-base text-white55">
              <ul className="pl-5">
                <li className="list-disc">
                  We are a team of self-aware members, we don&apos;t need to be
                  pushed to get things done because we value autonomy and
                  ownership.
                </li>
                <li className="list-disc">
                  We encourage a culture of continuous feedback and suggestions,
                  healthy discussion and debate, as well as taking
                  accountability for results and behavior.
                </li>
                <li className="list-disc">
                  We are open to constructive feedback, acknowledging it as an
                  opportunity for skills development, as well as a chance to
                  improve our processes and organization as a whole.
                </li>
              </ul>
            </div>
          </div>
          <div className="hidden md:block mt-10 border-t border-dashed border-white15" />
          <div className="grid grid-cols-1 mt-10 md:grid-cols-2 gap-2 md:gap-4 xl:gap-8">
            <p className="my-0 text-lg text-white100 md:max-w-[256px]">
              Everybody Is a Shareholder
            </p>
            <div className="my-0 text-base text-white55">
              <ul className="pl-5">
                <li className="list-disc">
                  We drive business as shareholders and we have skin in the
                  game.
                </li>
                <li className="list-disc">
                  We are passionate about what we do, we raise the bar and
                  strive to exceed expectations.
                </li>
                <li className="list-disc">
                  We have a big-picture view, considering a broad range of
                  internal and external factors when solving problems and making
                  decisions.
                </li>
                <li className="list-disc">
                  We care about Protofire&apos;s success, and that is why we are
                  not only focused on our project or node.
                </li>
                <li className="list-disc">
                  Through the tools that we build, we want to help the world to
                  create better products and services.
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>

      <div
        className="bg-no-repeat bg-auto bg-right-top xl:bg-[length:auto_1200px]"
        style={{ backgroundImage: `url(${careerBg3})` }}
      >
        <div className="max-w-[1120px] mx-auto pt-[120px] md:pt-[150px]">
          <div className="mb-[120px] md:mb-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />

          <section
            className="mx-4 md:mx-6 xl:mx-0 bg-no-repeat bg-auto bg-right-top"
            style={{ backgroundImage: isMobile ? "none" : `url(${careerBg4})` }}
          >
            <h2
              className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]"
              style={{ fontFamily: "Onest-SemiBold" }}
            >
              Our Benefits
            </h2>

            <div
              className="mt-[56px] grid xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-2 md:gap-4 xl:gap-8
  lg:text-sm md:text-sm sm:text-xs text-blue100"
              style={{
                fontFamily: "Onest-Light",
              }}
            >
              {content.map((item) => (
                <div
                  key={item.desc}
                  className="flex flex-col items-center p-6 xl:p-8 rounded-lg rounded-lg"
                  style={{
                    backdropFilter: "blur(56px)",
                    background:
                      "linear-gradient(0deg, rgba(241, 248, 255, 0.03) 0%, rgba(145, 149, 153, 0) 100%)",
                  }}
                >
                  <item.icon className="w-[48px] h-[48px] mb-4" />
                  <p className="m-0 text-base text-white100 text-center">
                    {item.desc}
                  </p>
                </div>
              ))}
            </div>

            <div className="mt-[100px] grid grid-cols-1 md:grid-cols-[3fr,5fr]">
              <p className="my-0 text-lg text-white100 md:max-w-[256px]">
                What really makes us different from all
              </p>
              <div className="mt-4 md:mt-0 text-base text-white55">
                <ul className="pl-5">
                  <li className="list-disc">Offer-driven sales structure</li>
                  <li className="list-disc">
                    Profit share through PROTOvNode token
                  </li>
                  <li className="list-disc">
                    Up to a half of the node equity goes to builders on top of
                    base salary and profits share
                  </li>
                  <li className="list-disc">
                    Participation in the early funding rounds of our customers
                    by Protofire members
                  </li>
                  <li className="list-disc">
                    Node structure for less overhead and faster results
                  </li>
                  <li className="list-disc">
                    Established relationships with Accounts and Partners among
                    the most if not all web3 biggest protocols and ecosystems
                  </li>
                  <li className="list-disc">
                    Treasuries management using Web3 tools & Degen strategies
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <div className="my-[120px] md:my-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />

          <section className="mt-[100px] mx-4 md:mx-6 xl:mx-0">
            <div className="flex flex-col md:flex-row gap-4">
              <div className="md:max-w-[544px] flex-1">
                <h2
                  className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]"
                  style={{ fontFamily: "Onest-SemiBold" }}
                >
                  Everybody Is a Shareholder
                </h2>
                <p className="m-0 mt-4 text-base xl:text-lg text-white55">
                  We drive business as shareholders. With our skin in the game,
                  we are passionate about what we do, raising the bar and
                  striving to exceed expectations.
                </p>
                <p className="m-0 text-base xl:text-lg text-white55">
                  Our PROTOvNODE Compensation Model ensures that Protofire team
                  members earn a fair share of the value they create for DAOs
                  and protocols we work with.
                </p>
              </div>
              <div className="flex flex-1 items-center justify-center min-h-[304px] md:min-h-fit">
                <img src={circlesTriangle} alt="circles-triangle" />
              </div>
            </div>
          </section>

          <div className="my-[120px] md:my-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />

          <section className="mt-[100px] mx-4 md:mx-6 xl:mx-0">
            <h2
              className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]"
              style={{ fontFamily: "Onest-SemiBold" }}
            >
              PROTOvNODE Token Compensation
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 mt-4 md:mt-[56px] md:gap-14">
              <div>
                <p className="m-0 text-base xl:text-lg text-white55">
                  Every month, Protofire shares its profit with the team in
                  regular rewards – this profit-sharing approach is still a rare
                  find in the DeFi world, yet we believe this is an important
                  virtue of the Protofire spirit.
                </p>
                <p className="m-0 mt-4 text-base xl:text-lg text-white55">
                  Firstly, a team member’s compensation consists of a regular
                  compensation and additional rewards based on the DAO’s and
                  project’s performance.
                </p>
              </div>
              <p className="m-0 sm:mt-4 md:mt-0 text-base xl:text-lg text-white55">
                Secondly, Protofirers can opt compensations in fiat and/or
                token. Our experiment with fiat+token or token-only contracts
                has proven to be a fantastic win-win for both Protofire and its
                members. To date, those members who worked on projects with
                customer-token compensations have seen significant gains
                compared to their average stable-currency payouts on payday.
                Some team members have received 12,000 Safe tokens –
                approximately $24,000 at the current exchange rate, or ~5 times
                the monthly base salary!
              </p>
            </div>

            <div
              className="flex flex-col gap-6 px-4 md:px-8 py-8 rounded-lg mt-[100px] text-xl text-white100"
              style={{
                background:
                  "linear-gradient(145.03deg, rgba(255, 255, 255, 0.16) 0%, rgba(0, 0, 0, 0) 62.67%)",
              }}
            >
              <span>Team member&apos;s compensation</span>
              <div
                className="px-4 md:px-8 p-8 rounded-lg "
                style={{
                  background:
                    "linear-gradient(151.72deg, rgba(151, 71, 255, 0.16) 0%, rgba(0, 0, 0, 0) 72.57%)",
                }}
              >
                Base compensation, negotiated during the hiring process
              </div>
              <div
                className="px-4 md:px-8 p-8 rounded-lg flex flex-col gap-6"
                style={{
                  background:
                    "linear-gradient(148.93deg, rgba(0, 208, 255, 0.16) 0%, rgba(0, 0, 0, 0) 70.7%)",
                }}
              >
                <span>
                  PROTOVNODE tokens, can comprise up to 30%-50% of a base
                  compensation
                </span>
                <div className="flex flex-col md:flex-row gap-6">
                  <div
                    className="px-4 md:px-8 p-8 rounded-lg "
                    style={{
                      background:
                        "linear-gradient(148.93deg, rgba(0, 208, 255, 0.16) 0%, rgba(0, 0, 0, 0) 70.7%)",
                    }}
                  >
                    Retro bonus, a customer reward for a project success
                  </div>
                  <div
                    className="px-4 md:px-8 p-8 rounded-lg "
                    style={{
                      background:
                        "linear-gradient(148.93deg, rgba(0, 208, 255, 0.16) 0%, rgba(0, 0, 0, 0) 70.7%)",
                    }}
                  >
                    Share of a Project / Node&apos;s operational net profit
                  </div>
                  <div
                    className="px-4 md:px-8 p-8 rounded-lg "
                    style={{
                      background:
                        "linear-gradient(148.93deg, rgba(0, 208, 255, 0.16) 0%, rgba(0, 0, 0, 0) 70.7%)",
                    }}
                  >
                    Client tokens at a discounted price
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div
        className="bg-no-repeat bg-left-top bg-auto xl:bg-[length:auto_1200px]"
        style={{ backgroundImage: `url(${careerBg5})` }}
      >
        <div className="max-w-[1120px] mx-auto pt-[120px] md:pt-[150px]">
          <div className="border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />

          <section
            className="mx-4 md:mx-6 xl:-mx-[100px] xl:px-[100px] pt-[120px] md:pt-[150px] bg-no-repeat bg-auto bg-right-top"
            style={{ backgroundImage: isMobile ? "none" : `url(${careerBg6})` }}
          >
            <h2
              className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]"
              style={{ fontFamily: "Onest-SemiBold" }}
            >
              Application Process
            </h2>
            <p className="m-0 mt-4 text-base xl:text-lg text-white55 xl:max-w-[832px]">
              In most cases, the entire process takes less than 3 weeks. On rare
              occasions, for high-risk and high-demanding positions this period
              may comprise up to 2 months.
            </p>
            <AppProcess />
          </section>

          <div className="my-[120px] md:my-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />

          <section
            className="mt-[100px] mx-4 md:mx-6 xl:mx-0"
            id="openOpportunities"
          >
            <h2
              className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]"
              style={{ fontFamily: "Onest-SemiBold" }}
            >
              Open opportunities
            </h2>
            <div className="mt-8 flex flex-col gap-2">
              {vacancies.length > 0 && displayVacancies()}
            </div>
          </section>
        </div>
      </div>

      <div
        className="bg-no-repeat bg-auto bg-right"
        style={{ backgroundImage: `url(${careerBg3})` }}
      >
        <div className="max-w-[1120px] mx-auto pt-[120px] md:pt-[150px]">
          <section className="mt-[100px] mx-4 md:mx-6 xl:mx-0 ">
            <div
              className="h-full w-full rounded-t-lg py-[56px] mb-[100px] rounded-lg"
              style={{
                background:
                  "linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(153, 153, 153, 0) 100%)",
              }}
            >
              <div className="px-8 text-center text-white100">
                <div className="flex justify-center items-center relative">
                  <img
                    src={oppAvatarLeftLeft}
                    alt=""
                    className="relative z-[1] -mr-2"
                  />
                  <img
                    src={oppAvatarCenterLeft}
                    alt=""
                    className="relative z-[2] -mr-2"
                  />
                  <img
                    src={oppAvatarCenter}
                    alt=""
                    className="relative z-[3]"
                  />
                  <img
                    src={oppAvatarCenterRight}
                    alt=""
                    className="relative z-[2] -ml-2"
                  />
                  <img
                    src={oppAvatarRightRight}
                    alt=""
                    className="relative z-[1] -ml-2"
                  />
                </div>
                <p
                  className="mb-0 text-[24px] leading-[32px] md:text-[32px] md:leading-[40px] xl:text-[40px] xl:leading-[48px]"
                  style={{ fontFamily: "Onest-SemiBold" }}
                >
                  Haven’t found an opportunity for you?
                </p>
                <p className="mb-0 mt-2 md:mt-3 xl:mt-4 text-sm md:text-base">
                  You may be the professional we need, we just don&apos;t know
                  it yet
                </p>
                <a href="mailto:apply@protofire.io">
                  <Button size="md" className="mt-6">
                    Write to us
                  </Button>
                </a>
              </div>
            </div>
          </section>

          <section
            className="mt-[56px] mx-4 md:mx-6 xl:mx-0 bg-no-repeat bg-auto bg-right-top"
            style={{ backgroundImage: isMobile ? "none" : `url(${careerBg4})` }}
          >
            <h2
              className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]"
              style={{ fontFamily: "Onest-SemiBold" }}
            >
              Our Team
            </h2>
            <ProtofireCarousel />
          </section>
        </div>
      </div>
    </div>
  );
}

export default Careers;
