/* eslint-disable react/require-default-props */
/* eslint-disable import/prefer-default-export */
import { ReactComponent as ArrowNext } from "../../assets/images/arrowNext.svg";
import { ReactComponent as CvReview } from "../../assets/career/paidTime.svg";
import { ReactComponent as HrInterview } from "../../assets/career/hrInterview.svg";
import { ReactComponent as TechHM } from "../../assets/career/tech.svg";
import { ReactComponent as TechAssessment } from "../../assets/career/techAssessment.svg";
import { ReactComponent as RefCheck } from "../../assets/career/refCheck.svg";
import { ReactComponent as Offer } from "../../assets/career/gift.svg";

function AppProcessMobileRow({
  leftIcon,
  rightIcon,
  leftText,
  rightText,
}: {
  leftIcon: any;
  rightIcon: any;
  leftText: React.ReactNode;
  rightText: React.ReactNode;
}) {
  return (
    <div className="flex gap-4 items-center">
      <div
        className="py-6 flex items-center justify-center flex-col rounded-lg flex-1 text-center"
        style={{
          background:
            "linear-gradient(270deg, rgba(241, 248, 255, 0.16) 0%, rgba(145, 149, 153, 0) 100%)",
        }}
      >
        {leftIcon}
        <p className="mt-2 mb-0">{leftText}</p>
      </div>
      <div className="bg-white5 rounded-lg p-2">
        <ArrowNext />
      </div>
      <div
        className="py-6 flex items-center justify-center flex-col rounded-lg flex-1"
        style={{
          background:
            "linear-gradient(90deg, rgba(241, 248, 255, 0.16) 0%, rgba(145, 149, 153, 0) 100%)",
        }}
      >
        {rightIcon}
        <p className="mt-2 mb-0 text-center">{rightText}</p>
      </div>
    </div>
  );
}
function AppProcessItem({
  icon,
  text,
  isTopToBottom,
}: {
  icon: any;
  text: any;
  isTopToBottom?: boolean;
}) {
  return (
    <div
      className="py-6 flex items-center justify-center flex-col rounded-lg flex-1"
      style={{
        background: isTopToBottom
          ? "linear-gradient(180deg, rgba(241, 248, 255, 0.16) 0%, rgba(145, 149, 153, 0) 100%)"
          : "linear-gradient(0deg, rgba(241, 248, 255, 0.16) 0%, rgba(145, 149, 153, 0) 100%)",
      }}
    >
      {icon}
      <p className="mt-2 mb-0 text-center">{text}</p>
    </div>
  );
}

function AppProcess() {
  return (
    <>
      {/* Mobile: */}
      <div className="flex mt-[56px] flex-col gap-4 text-white100 md:hidden">
        <AppProcessMobileRow
          leftIcon={<CvReview />}
          rightIcon={<HrInterview />}
          leftText={
            <>
              CV
              <br />
              review
            </>
          }
          rightText={
            <>
              HR
              <br />
              interview
            </>
          }
        />
        <AppProcessMobileRow
          leftIcon={<TechHM />}
          rightIcon={<TechAssessment />}
          leftText={
            <>
              Technical
              <br />+ HM
            </>
          }
          rightText={
            <>
              Technical
              <br />
              Assessment
            </>
          }
        />
        <AppProcessMobileRow
          leftIcon={<RefCheck />}
          rightIcon={<Offer />}
          leftText={
            <>
              Reference
              <br />
              Check
            </>
          }
          rightText={
            <>
              Job <br />
              Offer
            </>
          }
        />
      </div>

      {/* Tablet + desktop: */}
      <div className="hidden mt-[56px] md:flex flex-col xl:flex-row gap-8 xl:gap-4 text-white100 flex-1">
        <div className="flex gap-8 xl:gap-4 items-center flex-1">
          <AppProcessItem
            icon={<CvReview />}
            text={
              <>
                CV
                <br />
                review
              </>
            }
            isTopToBottom
          />
          <div className="bg-white5 rounded-lg p-2">
            <ArrowNext />
          </div>
          <AppProcessItem
            icon={<HrInterview />}
            text={
              <>
                HR
                <br />
                interview
              </>
            }
          />
          <div className="bg-white5 rounded-lg p-2">
            <ArrowNext />
          </div>
          <AppProcessItem
            icon={<TechHM />}
            text={
              <>
                Technical
                <br />+ HM
              </>
            }
            isTopToBottom
          />
        </div>

        <div className="gap-8 xl:gap-4 items-center hidden xl:flex">
          <div className="bg-white5 rounded-lg p-2 ">
            <ArrowNext />
          </div>
        </div>

        <div className="flex gap-8 xl:gap-4 items-center flex-1">
          <AppProcessItem
            icon={<TechAssessment />}
            text={
              <>
                Technical
                <br />
                Assessment
              </>
            }
          />
          <div className="bg-white5 rounded-lg p-2">
            <ArrowNext />
          </div>
          <AppProcessItem
            icon={<RefCheck />}
            text={
              <>
                Reference
                <br />
                Check
              </>
            }
            isTopToBottom
          />
          <div className="bg-white5 rounded-lg p-2">
            <ArrowNext />
          </div>
          <AppProcessItem
            icon={<Offer />}
            text={
              <>
                Job <br />
                Offer
              </>
            }
          />
        </div>
      </div>
    </>
  );
}

export default AppProcess;
