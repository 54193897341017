import { Link } from "react-router-dom";

import { useContext } from "react";
import { ReactComponent as ProtofireWhite } from "../assets/images/protofireWhite.svg";
import { ReactComponent as Github } from "../assets/images/github.svg";
// import { ReactComponent as Slack } from "../assets/images/slack.svg";
import { ReactComponent as Linkedin } from "../assets/images/linkedin.svg";
import { ReactComponent as Medium } from "../assets/images/medium.svg";
import { ReactComponent as Twitter } from "../assets/images/twitter.svg";
import { ReactComponent as Discord } from "../assets/images/discord.svg";
import { ReactComponent as Instagram } from "../assets/images/instagram.svg";
import { ReactComponent as Mirror } from "../assets/images/mirror.svg";
// import { ReactComponent as Youtube } from "../assets/images/youtube.svg";

// import MobileFooter from "./mobileFooter";
import getCurrentYear, { DefaultCallendlyName } from "../../utils/utils";
import Button from "../common/button/Button";
import bookCalendlyCall from "../../utils/bookCalendlyCall";
import { CallUserContext } from "../../store";

function Footer() {
  const context = useContext(CallUserContext);
  const currentYear = getCurrentYear();
  const handleBookCallClick = () => {
    context?.setCallUser(DefaultCallendlyName);
    bookCalendlyCall();
  };
  return (
    <div className="border-t border-white10 py-10">
      <div className="max-w-[1120px] mx-auto px-4 md:px-6 xl:px-0">
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col xl:flex-1">
            <ProtofireWhite className="h-8 mr-8" />
            <div className="flex flex-row md:flex-col xl:flex-row items-center md:items-baseline xl:items-center mt-8">
              <Button
                className="mr-8 md:mr-0 xl:mr-8"
                size="md"
                onClick={handleBookCallClick}
              >
                Book a call
              </Button>
            </div>
          </div>
          <nav className="flex flex-col md:flex-row md:flex-1 text-white100 text-sm leading-[20px]">
            <ul className="md:ml-8 mt-8 md:mt-0 flex flex-1 flex-col">
              <li className="whitespace-nowrap">
                <Link to="/about">About Protofire</Link>
              </li>
              <li className="mt-4 whitespace-nowrap">
                <Link to="/services">Solutions & Services</Link>
              </li>
              <li className="mt-4 whitespace-nowrap">
                <Link to="/projects">Case Studies & Projects</Link>
              </li>
              <li className="mt-4">
                <Link to="/careers">Careers</Link>
              </li>
              <li className="mt-4">
                <Link to="/community">Community</Link>
              </li>
            </ul>
            <ul className="md:ml-8 mt-8 md:mt-0 flex flex-1 flex-col">
              <li>
                <Link to="/solhint">Solhint</Link>
              </li>
              {/* <li className="mt-4">
                <Link to="/blog">Blog</Link>
              </li> */}
              <li className="mt-4 whitespace-nowrap">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              {/* <li className="mt-4">
                <Link to="/">Brandbook</Link>
              </li> */}
            </ul>
          </nav>
        </div>
        <div className="text-sm text-white40 leading-6">
          <div className="my-10 border-t border-white10" />
          <div className="flex flex-col md:flex-row justify-between items-baseline md:items-center">
            <div className="flex z-10 mt-0 flex-wrap gap-4">
              <a
                href="https://github.com/protofire"
                target="_blank"
                rel="noreferrer"
                className="text-blue100 hover:opacity-60  transition-opacity h-8 w-8 flex items-center justify-center"
              >
                <Github />
              </a>
              <a
                href="https://www.linkedin.com/company/protofire-io/"
                target="_blank"
                rel="noreferrer"
                className="text-blue100 hover:opacity-60 transition-opacity h-8 w-8 flex items-center justify-center"
              >
                <Linkedin />
              </a>
              <a
                href="https://medium.com/protofire-blog"
                target="_blank"
                rel="noreferrer"
                className="text-blue100 hover:opacity-60 transition-opacity h-8 w-8 flex items-center justify-center"
              >
                <Medium />
              </a>
              <a
                href="https://discord.gg/y5TE8TcPGn"
                target="_blank"
                rel="noreferrer"
                className="text-blue100 hover:opacity-60 transition-opacity h-8 w-8 flex items-center justify-center"
              >
                <Discord />
              </a>
              <a
                href="https://www.instagram.com/protofire.io"
                target="_blank"
                rel="noreferrer"
                className="text-blue100 hover:opacity-60 transition-opacity h-8 w-8 flex items-center justify-center"
              >
                <Instagram />
              </a>
              <a
                href="https://mirror.xyz/protofiredao.eth"
                target="_blank"
                rel="noreferrer"
                className="text-blue100 hover:opacity-60 transition-opacity h-8 w-8 flex items-center justify-center"
              >
                <Mirror />
              </a>
              <a
                href="https://twitter.com/protofire"
                target="_blank"
                rel="noreferrer"
                className="text-blue100 hover:opacity-60 transition-opacity h-8 w-8 flex items-center justify-center"
              >
                <Twitter />
              </a>
              {/* <a
                href="https://www.linkedin.com/company/protofire-io/"
                target="_blank"
                rel="noreferrer"
                className="text-blue100 hover:opacity-60 transition-opacity h-8 w-8 flex items-center justify-center"
              >
                <Slack />
              </a> */}
              {/* <a
                href="https://www.youtube.com/channel/UCAQJ1nXk7fNNnbbZuJzoH5w"
                target="_blank"
                rel="noreferrer"
                className="text-blue100 hover:opacity-60 transition-opacity h-8 w-8 flex items-center justify-center"
              >
                <Youtube />
              </a> */}
            </div>
            <p className="mt-4 md:mt-0 mb-0">
              Protofire {currentYear}. All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
