/* eslint-disable react/prop-types */
/// <reference types="react-scripts" />
import StudyProjectCard from "../../common/studyProjectCard";
import chainlinkDevTools from "../../assets/projects/chainlink-dev-tooling.png";
import bnbToPolygon from "../../assets/projects/BNB-to-Polygon-Bridging-for-Kryptomon.png";
import diaOracles from "../../assets/projects/DIA-Oracles-on-Kadena.png";
import singleButtonSolution from "../../assets/projects/single-button-solution.png";
import monitoringForGiga from "../../assets/projects/monitoring-for-giga.png";
import gnosis from "../../assets/projects/gnosis.png";

function StudiesProjectsCards() {
  return (
    <div className="mt-14 grid grid-cols-6 gap-8">
      <StudyProjectCard
        imgSrc={diaOracles}
        title="DIA Oracles on Kadena"
        tagList={["Oracle", "Smart Contract"]}
        text="Protofire integrated DIA oracles on Kadena, enabling 14 asset price feeds for blockchain protocols. Learn about our innovative approach to expanding oracle services and enhancing DeFi capabilities on the Kadena network."
        link="/projects/dia-oracles-kadena"
        className="col-span-6 md:col-span-3 xl:col-span-2"
      />
      <StudyProjectCard
        imgSrc={bnbToPolygon}
        title="BNB-to-Polygon Bridging for Kryptomon"
        tagList={["CCIP", "Integration"]}
        text="Protofire has leveraged Chainlink's CCIP to create a secure, one-time, and one-direction bridge infrastructure to transfer the Kryptomon assets (ERC20 and NFTs) from BNB to Polygon chain using an innovative approach to blockchain interoperability and asset transfer for gaming ecosystems."
        link="/projects/dia-oracles-kadena"
        className="col-span-6 md:col-span-3 xl:col-span-2"
      />
      <StudyProjectCard
        imgSrc={chainlinkDevTools}
        title="Chainlink Developer Tools"
        tagList={["Chainlink", "Foundry"]}
        text="The Chainlink Developer Tools, developed by Protofire, simplify interactions with Chainlink services and facilitate local testing of dApps that integrate them."
        link="/projects/chainlink-dev-tools"
        className="col-span-6 md:col-span-3 xl:col-span-2"
      />
      <StudyProjectCard
        imgSrc={singleButtonSolution}
        title="Single-button Solution for Big Data Protocol"
        tagList={["Big Data Protocol", "Solution"]}
        text="Protofire has developed a Single-button solution for Big Data Protocol to help accelerate the deployment and testing of new Ocean features for its data marketplace."
        link="/projects/single-button-solution"
        className="col-span-6 md:col-span-3"
      />
      <StudyProjectCard
        imgSrc={monitoringForGiga}
        title="Monitoring Platform for Giga"
        tagList={["Big Data Protocole", "Solution"]}
        text="Protofire has built a platform for Giga, an organization of which Unicef takes part of, that has as a goal to connect every school to the internet to empower people and help students develop."
        link="/projects/monitoring-for-giga"
        className="col-span-6 md:col-span-3"
      />
      <StudyProjectCard
        imgSrc={gnosis}
        title="Safe Multisig Wallet"
        tagList={["dApp", "Interface"]}
        text="Protofire has worked alongside Safe as a partner on the development of the Safe technology. Safe Multisig is a customizable crypto wallet running on Ethereum that requires a predefined number of signatures to confirm transactions to prevent unauthorized access to the assets stored."
        link="/projects/gnosis-safe-wallet"
        className="col-span-6 md:col-span-3 xl:hidden"
      />
    </div>
  );
}

export default StudiesProjectsCards;
