import Checkbox from "../checkboxs/Checkbox";

import "./offerPane.css";

type OfferPaneProps = {
  onSelect: () => void;
  title: string;
  description: string;
  link: string;
  checked?: boolean;
};

function OfferPane({
  onSelect,
  title,
  description,
  link,
  checked = false,
}: OfferPaneProps) {
  return (
    <div
      className="p-[0.5px] rounded-lg"
      style={{
        background: checked
          ? "linear-gradient(150.36deg, #FF8800 0%, rgba(255, 136, 0, 0) 81.87%)"
          : "linear-gradient(150.36deg, #F1F8FF1A 0%, #F1F8FF1A 81.87%)",
      }}
    >
      <div
        className="p-6 rounded-lg flex flex-col justify-between h-full"
        style={{
          background: checked
            ? "linear-gradient(to bottom right, #533e25, #2b2d2f), linear-gradient(131.31deg, #533e25 0%, #2b2d2f 100%)"
            : "#2a2c2e",
        }}
      >
        <div>
          <Checkbox checked={checked} onClick={onSelect} />
          <h4
            className="text-white100 text-base mt-6 truncate"
            style={{ fontFamily: "Onest-SemiBold" }}
          >
            {title}
          </h4>
        </div>
        <div>
          <p className="mt-2 mb-0 text-white55 text-sm offer-description">
            {description}
          </p>
          <span
            className="text-sm text-white100 mt-6 inline-block cursor-pointer"
            role="button"
            onKeyDown={() => {}}
            tabIndex={0}
            onClick={() => {
              window.open(link, "_blank", "noopener noreferrer");
            }}
          >
            Learn more
          </span>
        </div>
      </div>
    </div>
  );
}

OfferPane.defaultProps = {
  checked: false,
  // className: "",
  // disabled: false,
};

export default OfferPane;
