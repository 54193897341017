import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import CookiesSetup from "../../cookiesSetup";
// import SolhintLayoutHeader from "./solhintLayoutHeader";
import SolhintLayoutFooter from "./solhintLayoutFooter";
import Menu from "../../components/menu/menu";

function SolhintLayout() {
  return (
    <div className="bg-solhintBackground bg-solhint-looper bg-contain bg-center bg-no-repeat bg-top">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="format-detection" content="telephone=no" />
        <link rel="canonical" href="protofire.io" />
        <title>Protofire | We Build the Future of Web3</title>
        <meta
          name="description"
          content="Build a team of blockchain experts to develop your web3 solutions and accelerate growth of your decentralized ecosystem. Get in touch for details."
        />
        <meta
          name="keywords"
          content="blockchain development, web3, defi, dapps development"
        />
      </Helmet>

      {/* <SolhintLayoutHeader /> */}
      <div className="fixed w-full top-6 z-50 mx-auto px-4 xl:px-[144px]">
        <Menu isCallAvailable={false} />
      </div>

      <Outlet />

      <SolhintLayoutFooter />

      <CookiesSetup />
    </div>
  );
}

export default SolhintLayout;
