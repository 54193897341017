const L1_L2_NETWORK = "L1/L2 Network";
const PROTOCOL = "Protocol (Defi, RWA, NFT)";
const ONBOARDING_INTO_WEB3_BLOCKCHAIN = "Onboarding into Web3/Blockchain";
const ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS =
  "Attract Developers and/or Protocols";
const GROW_TVL = "Grow TVL";
const GROW_MONTHLY_ACTIVE_USERS_TRANSACTIONS =
  "Grow Monthly Active Users & Transactions";

// I am
export const firstTriggerItems = [
  L1_L2_NETWORK,
  PROTOCOL,
  ONBOARDING_INTO_WEB3_BLOCKCHAIN,
];

// My Ultimate Business Outcome
export const secondTriggerItems = [
  ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS,
  GROW_TVL,
  GROW_MONTHLY_ACTIVE_USERS_TRANSACTIONS,
];

export const offerItems = [
  {
    title: "CCIP Integration",
    triggerItemsStep1: [L1_L2_NETWORK, PROTOCOL],
    triggerItemsStep2: {
      [L1_L2_NETWORK]: [ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS, GROW_TVL],
      [PROTOCOL]: [ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS, GROW_TVL],
    },
    description:
      "We integrate protocols with Chainlink’s Cross-Chain Interoperability Protocol to help them grow their user base and expand token usage.",
    link: "/services/solution/ccip-integration",
  },
  // {
  //   title: "PPC marketing",
  //   triggerItemsStep1: [L1_L2_NETWORK, PROTOCOL],
  //   description:
  //     "We help DAOs, chains, protocols, and networks attract Leads and acquire Users to their ecosystems through Email marketing activities and Advertising platforms.",
  //   link: "/services/solution/ppc-marketing",
  //   triggerItemsStep2: {
  //     [L1_L2_NETWORK]: [
  //       ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS,
  //       GROW_TVL,
  //       GROW_MONTHLY_ACTIVE_USERS_TRANSACTIONS,
  //     ],
  //     [PROTOCOL]: [
  //       ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS,
  //       GROW_TVL,
  //       GROW_MONTHLY_ACTIVE_USERS_TRANSACTIONS,
  //     ],
  //     [ONBOARDING_INTO_WEB3_BLOCKCHAIN]: [
  //       ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS,
  //       GROW_TVL,
  //       GROW_MONTHLY_ACTIVE_USERS_TRANSACTIONS,
  //     ],
  //   },
  // },
  {
    title: "Web3 Marketing",
    triggerItemsStep1: [
      L1_L2_NETWORK,
      PROTOCOL,
      ONBOARDING_INTO_WEB3_BLOCKCHAIN,
    ],
    triggerItemsStep2: {
      [L1_L2_NETWORK]: [
        ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS,
        GROW_TVL,
        GROW_MONTHLY_ACTIVE_USERS_TRANSACTIONS,
      ],
      [PROTOCOL]: [
        ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS,
        GROW_TVL,
        GROW_MONTHLY_ACTIVE_USERS_TRANSACTIONS,
      ],
      [ONBOARDING_INTO_WEB3_BLOCKCHAIN]: [
        ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS,
        GROW_TVL,
        GROW_MONTHLY_ACTIVE_USERS_TRANSACTIONS,
      ],
    },
    description:
      "We build vibrant communities and drive developer adoption for Web3 and DeFi projects using tailored strategies that enhance brand awareness, engage users, and connect you with key industry influencers to maximize your impact.",
    link: "/services/solution/marketing",
  },
  {
    title: "Safe Deployment",
    triggerItemsStep1: [
      L1_L2_NETWORK,
      PROTOCOL,
      ONBOARDING_INTO_WEB3_BLOCKCHAIN,
    ],
    triggerItemsStep2: {
      [L1_L2_NETWORK]: [ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS],
      [PROTOCOL]: [ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS],
      [ONBOARDING_INTO_WEB3_BLOCKCHAIN]: [ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS],
    },
    description:
      "Protofire is a leading blockchain collective for a fast and secure Safe Wallet deployment on networks. Potofire is an official infrastructural partner of Safe (previously Gnosis Safe) and Safe Guardian, widely recognized within the Safe ecosystem.",
    link: "/services/solution/safe-deployment",
  },
  {
    title: "Validators",
    triggerItemsStep1: [L1_L2_NETWORK, ONBOARDING_INTO_WEB3_BLOCKCHAIN],
    triggerItemsStep2: {
      [L1_L2_NETWORK]: [ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS],
      [ONBOARDING_INTO_WEB3_BLOCKCHAIN]: [ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS],
    },
    description:
      "We set up and maintain the environment and infrastructure for Validators to help them outperform TVL, MAU, liquidity levels, and more.",
    link: "/services/solution/validators",
  },
  {
    title: "RPC Node Hosting",
    triggerItemsStep1: [L1_L2_NETWORK],
    triggerItemsStep2: {
      [L1_L2_NETWORK]: [ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS],
    },
    description:
      "Protofire provides high-performance customized RPC endpoints for networks, platforms, protocols, and event organizers.",
    link: "/services/solution/rpc-node-hosting",
  },
  {
    title: "The Graph",
    triggerItemsStep1: [L1_L2_NETWORK, ONBOARDING_INTO_WEB3_BLOCKCHAIN],
    triggerItemsStep2: {
      [L1_L2_NETWORK]: [ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS],
      [ONBOARDING_INTO_WEB3_BLOCKCHAIN]: [ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS],
    },
    description:
      "We bring The Graph protocol services, the #1 data indexing standard, and its subgraphs to networks enabling dApps to efficiently query blockchain data while reducing the need for in-house data indexing solutions and infrastructure.",
    link: "/services/solution/the-graph",
  },
  {
    title: "Oracle Integration",
    triggerItemsStep1: [L1_L2_NETWORK],
    triggerItemsStep2: {
      [L1_L2_NETWORK]: [ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS],
    },
    description:
      "Protofire deploys custom compatible oracles using the same data providers and node operators, allowing for protocols to go to their network without changing smart contracts.",
    link: "/services/solution/oracle-integration",
  },
  {
    title: "Blockscout Development",
    triggerItemsStep1: [L1_L2_NETWORK],
    triggerItemsStep2: {
      [L1_L2_NETWORK]: [ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS],
    },
    description:
      "We provide custom Blockscout development, upgrades, hosting, and support to deliver an exceptional user experience and enhanced functionality for your ecosystem.",
    link: "/services/solution/blockscout-development",
  },
  // {
  //   title: "Bridges",
  //   // triggerItemsStep1: [
  //   //   L1_L2_NETWORK,
  //   //   GROW_TVL,
  //   //   PROTOCOL,
  //   // ],
  //   description:
  //     "Protofire builds comprehensive Bridges for network ecosystems and develops reliable end-to-end solutions for protocols and retail and institutional users who need cross-chain and economic security features.",
  //   link: "/services/solution/bridges",
  // },
  {
    title: "Uniswap v3 Integration ",
    triggerItemsStep1: [L1_L2_NETWORK],
    triggerItemsStep2: {
      [L1_L2_NETWORK]: [GROW_TVL, GROW_MONTHLY_ACTIVE_USERS_TRANSACTIONS],
    },
    description:
      "We integrate Uniswap v3 with DeFi ecosystems to help them expand the outreach and diversify the ecosystem accessibility.",
    link: "/services/solution/uniswap-v3-integration",
  },
  // {
  //   title: "Staking Modules",
  //   triggerItemsStep1: [L1_L2_NETWORK, PROTOCOL],
  //   triggerItemsStep2: {
  //     [L1_L2_NETWORK]: [GROW_TVL, GROW_MONTHLY_ACTIVE_USERS_TRANSACTIONS],
  //     [PROTOCOL]: [GROW_TVL, GROW_MONTHLY_ACTIVE_USERS_TRANSACTIONS],
  //   },
  //   description:
  //     "We introduce staking mechanisms for platforms and protocols to maximize rapid success in the mid and long term.",
  //   link: "/services/solution/staking-modules",
  // },
  // {
  //   title: "Genesis Ark",
  //   triggerItemsStep1: ["Protocol Incubation"],
  //   description:
  //     "We deliver a simple, user-friendly way for blockchain networks with subchains to automatically onboard more dApps.",
  //   link: "/services/solution/genesis-ark",
  // },
  // {
  //   title: "Cost Optimization",
  //   triggerItemsStep1: [L1_L2_NETWORK],
  //   description:
  //     "Protofire helps blockchain-based projects and ecosystems optimize solution infrastructure and save thousands of dollars on operation and maintenance.",
  //   link: "/services/solution/cost-optimization",
  // },
  // {
  //   title: "Insured Audits",
  //   triggerItemsStep1: [PROTOCOL],
  //   triggerItemsStep2: {
  //     [PROTOCOL]: [GROW_TVL, GROW_MONTHLY_ACTIVE_USERS_TRANSACTIONS],
  //   },
  //   description:
  //     "Protofire helps protocols to attract users by improving and demonstrating its security. We designed Insured Audits to solve the problem of auditors not having skin in the game. Unlike alternatives, Protofire’s Insured Audits combine staked USDC/ERC20 tokens from delivery teams with USDC/ERC20 from 3rd party underwriters. Skin in the game from auditors and the ability to purchase optional deposit protection de-risks institutional deposits.",
  //   link: "/services/solution/audit",
  // },
  // {
  //   title: "Insurance",
  //   triggerItemsStep1: [PROTOCOL],
  //   triggerItemsStep2: {
  //     [PROTOCOL]: [GROW_TVL],
  //   },
  //   description:
  //     "Using At_REPLACE_omica, Protofire builds comprehensive, customized Security Solutions that protect DeFi users against risks that prevent them from adopting your project or increasing their deposits.",
  //   link: "/services/solution/insurance",
  // },
  // {
  //   title: "Insurance for large TVL depositors",
  //   triggerItemsStep1: [
  //     "Remove Token from circulation and reduce selling presure",
  //     "Dev Tooling",
  //   ],
  //   description:
  //     "Protofire develops frameworks of insurance solutions to attract users and increase customers’ TVL.",
  //   link: "/services/solution/insurance-for-large-tvl-depositors",
  // },
  // {
  //   title: "Insured Audit Services & Institutional TVL",
  //   triggerItemsStep1: ["Protocol Incubation"],
  //   description:
  //     "Protofire helps protocols attract users and grow TVL by improving and demonstrating the customer ecosystem’s security.",
  //   link: "/services/solution/insured-audit-services-institutional-tvl",
  // },
  // {
  //   title: "CDP (Liquity)",
  //   triggerItemsStep1: [L1_L2_NETWORK],
  //   triggerItemsStep2: {
  //     [L1_L2_NETWORK]: [
  //       ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS,
  //       GROW_TVL,
  //       GROW_MONTHLY_ACTIVE_USERS_TRANSACTIONS,
  //     ],
  //   },
  //   description:
  //     "We deploy fully functional CDP products on customer networks and make fully functional templates for their users willing to extend it and create profitable businesses in the customer’s ecosystem.",
  //   link: "/services/solution/cdp",
  // },
  // {
  //   title: "Web3 Marketing",
  //   triggerItemsStep1: [
  //     L1_L2_NETWORK,
  //     PROTOCOL,
  //     ONBOARDING_INTO_WEB3_BLOCKCHAIN,
  //   ],
  //   triggerItemsStep2: {
  //     [L1_L2_NETWORK]: [
  //       ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS,
  //       GROW_TVL,
  //       GROW_MONTHLY_ACTIVE_USERS_TRANSACTIONS,
  //     ],
  //     [PROTOCOL]: [
  //       ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS,
  //       GROW_TVL,
  //       GROW_MONTHLY_ACTIVE_USERS_TRANSACTIONS,
  //     ],
  //     [ONBOARDING_INTO_WEB3_BLOCKCHAIN]: [
  //       ATTRACT_DEVELOPERS_AND_OR_PROTOCOLS,
  //       GROW_TVL,
  //       GROW_MONTHLY_ACTIVE_USERS_TRANSACTIONS,
  //     ],
  //   },
  //   description:
  //     "We build vibrant communities and drive developer adoption for Web3 and DeFi projects using tailored strategies that enhance brand awareness, engage users, and connect you with key industry influencers to maximize your impact.",
  //   link: "/services/solution/marketing",
  // },

  // {
  //   title: "Marketing PR",
  //   triggerItemsStep1: [
  //     "Adding Utility on Token",
  //     "On-demand Development &/or Design",
  //   ],
  //   description:
  //     "We connect with mass media focused on Web3 and DeFi to grow our brand awareness and customer loyalty organically.",
  //   link: "/services/solution/marketing-pr",
  // },
  // {
  //   title: "Community building and management",
  //   triggerItemsStep1: [
  //     "Remove Token from circulation and reduce selling presure",
  //     "Dev Tooling",
  //   ],
  //   description:
  //     "We’re all about building strong, engaged communities around your Web3 project. Our team crafts strategies to connect with your audience, keeping them loyal and active.",
  //   link: "/services/solution/community-building-and-management",
  // },
  // {
  //   title: "Offline and side events support",
  //   triggerItemsStep1: ["Testing or Audit"],
  //   description:
  //     "Our Marketing team can take care of your event attendance from start to end, including event selection, booth design and assembling, and side event management, with a strong push through social media.",
  //   link: "/services/solution/offline-and-side-events-support",
  // },
  // {
  //   title: "KOLs marketing",
  //   triggerItemsStep1: ["Insurance"],
  //   description:
  //     "We can connect with Key Opinion Leaders (KOLs) and mass media focused on our industry, generating awareness and word-of-mouth, pushing your brand further into the public consciousness.",
  //   link: "/services/solution/kol-marketing",
  // },
  // {
  //   title: "QA",
  //   triggerItemsStep1: [
  //     ONBOARDING_INTO_WEB3_BLOCKCHAIN,
  //     GROW_MONTHLY_ACTIVE_USERS_TRANSACTIONS,
  //     "Infrastructure",
  //   ],
  //   description:
  //     "We provide Quality Assurance services for Web3 projects, networks, and ecosystems to reduce the cost of quality user experience, automate monitoring and alerts, and improve QA performance..",
  //   link: "/services/solution/qa",
  // },
  // {
  //   title: "Staff Augmentation",
  //   triggerItemsStep1: [
  //     "Adding Utility on Token",
  //     "On-demand Development &/or Design",
  //   ],
  //   description:
  //     "Protofire can set up a dream team of engineers for your project with on-demand allocation, regular reporting, and a clear pricing plan.",
  //   link: "/services/solution/staff-augmentation",
  // },
  // {
  //   title: "At_REPLACE_omica",
  //   triggerItemsStep1: [
  //     "Remove Token from circulation and reduce selling presure",
  //     "Dev Tooling",
  //   ],
  //   description:
  //     "Protofire and At_REPLACE_omica build comprehensive, customized Security Solutions that protect DeFi users against risks that prevent them from adopting your project or increasing their deposits.",
  //   link: "/services/solution/At_REPLACE_omica",
  // },
];
