import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import { ReactComponent as ChevronRightDouble } from "../assets/images/chevronRightDouble.svg";
import ProjectsMetrics from "../common/projectsMetrics/ProjectsMetrics";
import Button from "../common/button/Button";
import TeamCarousel from "../common/ourTeam";
import GrowTvlAdoption from "../common/growTvlAdoption";
import aboutBg1 from "../assets/about/bgSecondary1.png";
import aboutBg2 from "../assets/about/bgSecondary2.png";
import bgAboutMain1MD from "../assets/about/bgAboutMain1MD.png";
import bgAboutMain1XL from "../assets/about/bgAboutMain1XL.png";
import bgAboutMain2 from "../assets/about/bgAboutMain2.png";
import theGraphLogo from "../assets/about/theGraphLogo.png";
import filecoinLogo from "../assets/about/filecoinLogo.png";
import swarmLogo from "../assets/about/swarmLogo.png";
import gnosisLogo from "../assets/about/gnosisLogo.svg";
import chainlinkLogo from "../assets/about/chainlinkLogo.png";
import HistorySlider from "./historySlider";
import ContactUs from "../contactUs";

function About() {
  const location = useLocation();
  const isXLScreen = useMediaQuery({ query: "(min-width: 1440px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 833px)" });

  useEffect(() => {
    if (location?.hash) {
      window.scrollTo({
        top: document.getElementById(
          location.hash.slice(1, location.hash.length)
        )?.offsetTop,
        behavior: "smooth",
      });
    }
  }, [location?.hash]);

  return (
    <div className="pb-[150px]">
      <Helmet>
        <title>
          About Protofire | Pioneering Web3 Development & DAO Solutions
        </title>
        <meta
          name="description"
          content="Discover how Protofire empowers Web3 projects, DAOs, and protocols to achieve organic growth. Learn about our expertise in blockchain solutions, developer adoption, and strategies to enhance Total Value Locked (TVL)."
        />
        <meta
          name="keywords"
          content="about Protofire, Web3 growth, blockchain development, DAO solutions, organic growth strategies, developer adoption, Total Value Locked (TVL), Ethereum ecosystem, blockchain innovation"
        />
      </Helmet>
      <div
        className="bg-no-repeat bg-center bg-auto xl:bg-contain"
        style={{ backgroundImage: `url(${aboutBg1})` }}
      >
        <div
          className="bg-no-repeat bg-[length:auto_100%] bg-right-top relative"
          style={{
            backgroundImage: isMobile
              ? "none"
              : `url(${isXLScreen ? bgAboutMain1XL : bgAboutMain1MD})`,
          }}
        >
          <div className="max-w-[1120px] mx-auto pt-[80px]">
            <section className="mx-4 md:mx-6 xl:mx-0">
              <div className="md:max-w-[832px] xl:max-w-[754px] pt-[100px] md:pt-[120px]">
                <h1
                  className="text-white100 text-[32px] leading-[40px] md:text-5xl md:leading-[56px] xl:text-[64px] xl:leading-[72px]"
                  style={{ fontFamily: "Onest-SemiBold" }}
                >
                  Our Mission
                </h1>
                <p className="mt-4 mb-0 text-white55 text-base md:text-lg">
                  We help the world transition into Web3 by deploying solutions
                  that unlock the organic growth of Web3 projects, DAOs, and
                  protocols.
                </p>
                <p className="mt-3 mb-0 text-white55 text-base md:text-lg">
                  We remove blockers for organic growth of:
                </p>
                <ul className="mt-4 text-white100">
                  <li className="mt-4 flex items-center ">
                    <ChevronRightDouble className="mr-4 min-w-[20px]" />
                    <span>Users</span>
                  </li>
                  <li className="mt-4 flex items-center">
                    <ChevronRightDouble className="mr-4 min-w-[20px]" />
                    <span>Developers’ adoption</span>
                  </li>
                  <li className="mt-4 flex items-center">
                    <ChevronRightDouble className="mr-4 min-w-[20px]" />
                    <span>TVL (Total Value Locked)</span>
                  </li>
                </ul>
              </div>
              <p className="mb-0 mt-6 text-white55 text-sm">
                We are different because our DAO ensures that our members earn a
                fair share of the value they create for DAOs and protocols.
              </p>
            </section>

            <section className="flex flex-col md:flex-row mt-[100px] mx-4 md:mx-6 xl:mx-0">
              <ProjectsMetrics />
            </section>

            <div className="my-[120px] md:my-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />
          </div>
        </div>
      </div>

      <div className="max-w-[1120px] mx-auto">
        <section
          className="mt-[100px] mx-4 md:mx-6 xl:mx-0 relative"
          id="protofireHistory"
        >
          <h2
            className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px] absolute z-10"
            style={{ fontFamily: "Onest-SemiBold" }}
          >
            Protofire History
          </h2>
          <HistorySlider />
        </section>

        <div className="my-[120px] md:my-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />

        <section className="mt-[100px] mx-4 md:mx-6 xl:mx-0">
          <h2
            className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]"
            style={{ fontFamily: "Onest-SemiBold" }}
          >
            Protofire DAO Structure
          </h2>
          <p className="mt-4 mb-0 text-white55 text-base md:text-lg md:max-w-[832px]">
            Protofire consists of several nodes where each node is driving their
            own projects while staying in-sync with each other and the
            Protofire&apos;s goals.
          </p>

          <div
            className="mt-[56px] p-8 rounded-lg flex flex-col xl:flex-row gap-6"
            style={{
              background: isXLScreen
                ? "linear-gradient(105.28deg, rgba(255, 255, 255, 0.16) 0%, rgba(0, 0, 0, 0) 60.73%)"
                : "linear-gradient(132.47deg, rgba(255, 255, 255, 0.16) 0%, rgba(0, 0, 0, 0) 46.43%)",
            }}
          >
            <div>
              <h4 className="my-0 text-white100 text-lg">Back-office Node:</h4>
              <div className="mt-6">
                <div className="mt-2 mr-2 inline-block text-white55 rounded-full bg-white5 px-3 py-1 text-sm">
                  Cub3
                </div>
                <div className="mt-2 mr-2 inline-block text-white55 rounded-full bg-white5 px-3 py-1 text-sm">
                  Maple
                </div>
                <div className="mt-2 mr-2 inline-block text-white55 rounded-full bg-white5 px-3 py-1 text-sm">
                  Simplestate
                </div>
                <div className="mt-2 mr-2 inline-block text-white55 rounded-full bg-white5 px-3 py-1 text-sm">
                  Terrace
                </div>
              </div>
            </div>

            <div
              className="p-8 rounded-lg flex flex-col xl:flex-row gap-6"
              style={{
                background: isXLScreen
                  ? "linear-gradient(108.25deg, rgba(151, 71, 255, 0.16) 0%, rgba(0, 0, 0, 0) 62.4%)"
                  : "linear-gradient(137.77deg, rgba(151, 71, 255, 0.16) 0%, rgba(0, 0, 0, 0) 48.36%)",
              }}
            >
              <div>
                <h4 className="my-0 text-white100 text-lg">Incubation Node:</h4>
                <div className="mt-4">
                  <div className="mt-2 mr-2 inline-block text-white55 rounded-full bg-white5 px-3 py-1 text-sm">
                    Lending & Borrowing
                  </div>
                  <div className="mt-2 mr-2 inline-block text-white55 rounded-full bg-white5 px-3 py-1 text-sm">
                    Stablecoins
                  </div>
                  {/* <div className="mt-2 mr-2 inline-block text-white55 rounded-full bg-white5 px-3 py-1 text-sm">
                    Staking Modules
                  </div> */}
                  <div className="mt-2 mr-2 inline-block text-white55 rounded-full bg-white5 px-3 py-1 text-sm">
                    DeFi Strategies
                  </div>
                </div>
              </div>

              <div
                className="p-8 rounded-lg flex flex-col xl:flex-row gap-6"
                style={{
                  background: isXLScreen
                    ? "linear-gradient(116.41deg, rgba(0, 208, 255, 0.16) 0%, rgba(0, 0, 0, 0) 66.59%)"
                    : "linear-gradient(134.69deg, rgba(0, 208, 255, 0.16) 0%, rgba(0, 0, 0, 0) 48.67%)",
                }}
              >
                <div className="flex-1">
                  <h4 className="my-0 text-white100 text-lg">
                    Seed Node Pool:
                  </h4>
                  <div className="mt-4">
                    <div className="mt-2 mr-2 inline-block text-white55 rounded-full bg-white5 px-3 py-1 text-sm">
                      DevTooling
                    </div>
                    <div className="mt-2 mr-2 inline-block text-white55 rounded-full bg-white5 px-3 py-1 text-sm">
                      Cross-chain & Bridges
                    </div>
                    <div className="mt-2 mr-2 inline-block text-white55 rounded-full bg-white5 px-3 py-1 text-sm">
                      Solhint
                    </div>
                    <div className="mt-2 mr-2 inline-block text-white55 rounded-full bg-white5 px-3 py-1 text-sm">
                      Subgraphs
                    </div>
                    <div className="mt-2 mr-2 inline-block text-white55 rounded-full bg-white5 px-3 py-1 text-sm">
                      Audits
                    </div>
                    <div className="mt-2 mr-2 inline-block text-white55 rounded-full bg-white5 px-3 py-1 text-sm">
                      Oracles
                    </div>
                    <div className="mt-2 mr-2 inline-block text-white55 rounded-full bg-white5 px-3 py-1 text-sm">
                      Safety Modules
                    </div>
                  </div>
                </div>

                <div
                  className="p-8 rounded-lg flex flex-col xl:flex-row flex-1"
                  style={{
                    background: isXLScreen
                      ? "linear-gradient(127.77deg, rgba(255, 136, 0, 0.16) 0%, rgba(0, 0, 0, 0) 71.83%)"
                      : "linear-gradient(140.52deg, rgba(255, 136, 0, 0.16) 0%, rgba(0, 0, 0, 0) 58.92%)",
                  }}
                >
                  <div>
                    <h4 className="my-0 text-white100 text-lg">Full Nodes:</h4>
                    <div className="mt-4">
                      <div className="mt-2 mr-2 inline-block text-white55 rounded-full bg-white5 px-3 py-1 text-sm">
                        DevOps Node
                      </div>
                      <div className="mt-2 mr-2 inline-block text-white55 rounded-full bg-white5 px-3 py-1 text-sm">
                        Web3 User Solutions
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="my-[120px] md:my-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />
      </div>

      <div
        className="bg-no-repeat bg-auto bg-left"
        style={{
          backgroundImage: `url(${bgAboutMain2})`,
        }}
      >
        <div className="max-w-[1120px] mx-auto">
          <section className="mt-[120px] md:mt-[150px] mx-4 md:mx-6 xl:mx-0">
            <div className="max-w-[832px] xl:max-w-[544px]">
              <h2
                className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]"
                style={{ fontFamily: "Onest-SemiBold" }}
              >
                Our Team
              </h2>
              <p className="mt-4 mb-0 text-white55 text-base md:text-lg">
                We are a team of senior engineers and experts who know how to
                make Web3 ecosystems grow their TVL organically.
              </p>
            </div>
            <Link to="/careers">
              <Button
                variant="outlined"
                size="md"
                className="mt-[56px] w-full md:w-auto"
              >
                Become a member
              </Button>
            </Link>
            <div>
              <TeamCarousel />
            </div>
          </section>

          <section
            className="pt-[120px] md:pt-[150px] mx-4 md:mx-6 xl:mx-0 bg-no-repeat bg-right-top bg-auto"
            style={{ backgroundImage: `url(${aboutBg2})` }}
          >
            <h2
              className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]"
              style={{ fontFamily: "Onest-SemiBold" }}
            >
              Our Long-term Partners
            </h2>
            <p className="mt-4 mb-0 text-white55 text-base md:text-lg md:max-w-[832px]">
              What our partners love and value about Protofire.
            </p>
            <div className="mt-[56px] flex flex-col md:flex-row gap-6 xl:gap-8 items-stretch">
              <div
                className="p-[0.5px] pb-0 rounded-lg flex flex-1"
                style={{
                  background:
                    "linear-gradient(110.31deg, #6846F2 0%, rgba(0, 0, 0, 0) 63.51%)",
                }}
              >
                <div
                  className="p-8 rounded-lg w-full h-full"
                  style={{
                    background:
                      "radial-gradient(100% 505.32% at 0% 0%, #211c38 0%, #141516 100%)",
                  }}
                >
                  <div className="flex w-full justify-between gap-2">
                    <div>
                      <p className="m-0 text-2xl text-white100">The Graph</p>
                      <p className="mb-0 mt-8 text-lg text-white100">
                        Since 2021, with 3+ Protofire DAO members.
                      </p>
                    </div>
                    <div
                      className="min-w-[90px] h-[90px] bg-no-repeat bg-center bg-cover"
                      style={{
                        backgroundImage: `url(${theGraphLogo})`,
                      }}
                    />
                  </div>
                  <p className="mb-0 mt-8 text-base text-white55">
                    Built more than 30 subgraphs and counting. Running one of
                    the biggest indexer nodes in the ecosystem.
                  </p>
                </div>
              </div>
              <div
                className="p-[0.5px] pb-0 rounded-lg flex flex-1"
                style={{
                  background:
                    "linear-gradient(110.31deg, #3294F9 0%, rgba(0, 0, 0, 0) 63.51%)",
                }}
              >
                <div
                  className="p-8 rounded-lg w-full h-full"
                  style={{
                    background:
                      "radial-gradient(100% 505.32% at 0% 0%, #192839 0%, #151617 100%)",
                  }}
                >
                  <div className="flex w-full justify-between gap-2">
                    <div>
                      <p className="m-0 text-2xl text-white100">Filecoin</p>
                      <p className="mb-0 mt-8 text-lg text-white100">
                        Working together since 2020, had 2+ Protofire DAO
                        members.
                      </p>
                    </div>
                    <div
                      className="min-w-[90px] h-[90px] bg-no-repeat bg-center bg-cover"
                      style={{
                        backgroundImage: `url(${filecoinLogo})`,
                      }}
                    />
                  </div>
                  <p className="mb-0 mt-8 text-base text-white55">
                    TTP requests processed by us (daily): 12 million in the
                    mainnet. Uptime for the public API (average, year): 99.581%.
                    Total unique IPs: 229,258.
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-6 xl:mt-8 flex flex-col md:flex-row gap-6 xl:gap-8 items-stretch">
              <div
                className="p-[0.5px] pb-0 rounded-lg flex flex-1"
                style={{
                  background:
                    "linear-gradient(110.31deg, #23795C 0%, rgba(0, 0, 0, 0) 63.51%)",
                }}
              >
                <div
                  className="p-8 rounded-lg w-full h-full"
                  style={{
                    background:
                      "radial-gradient(100% 505.32% at 0% 0%, #152321 0%, #141516 100%)",
                  }}
                >
                  <div className="flex w-full justify-between gap-2">
                    <div>
                      <p className="m-0 text-2xl text-white100">
                        Gnosis ecosystem
                      </p>
                      <p className="mb-0 mt-8 text-lg text-white100">
                        Since 2018, had a 6+ Protofire DAO contributors.
                      </p>
                    </div>
                    <div
                      className="min-w-[90px] h-[90px] bg-no-repeat bg-center bg-cover"
                      style={{
                        backgroundImage: `url(${gnosisLogo})`,
                      }}
                    />
                  </div>
                  <p className="mb-0 mt-8 text-base text-white55">
                    Contributed to development of Safe, a multisignature wallet
                    that allows users to manage digital assets collectively,
                    Gnosis Auction app - platform for conducting fair,
                    transparent, and decentralized token price discovery,
                    Cowswap DEX and the Explorer apps, Conditional Token
                    framework, Omen - a decentralized Prediction market.
                  </p>
                </div>
              </div>
              <div
                className="p-[0.5px] pb-0 rounded-lg flex flex-1"
                style={{
                  background:
                    "linear-gradient(110.31deg, #345FD0 0%, rgba(0, 0, 0, 0) 63.51%)",
                }}
              >
                <div
                  className="p-8 rounded-lg w-full h-full"
                  style={{
                    background:
                      "radial-gradient(100% 505.32% at 0% 0%, #182033 0%, #141516 100%)",
                  }}
                >
                  <div className="flex w-full justify-between gap-2">
                    <div>
                      <p className="m-0 text-2xl text-white100">Chainlink</p>
                      <p className="mb-0 mt-8 text-lg text-white100">
                        Since December 2021, had 4+ Protofire DAO members.
                      </p>
                    </div>
                    <div
                      className="min-w-[90px] h-[90px] bg-no-repeat bg-center bg-cover"
                      style={{
                        backgroundImage: `url(${chainlinkLogo})`,
                      }}
                    />
                  </div>
                  <p className="mb-0 mt-8 text-base text-white55">
                    Made Chainlink integrations acceptance testing for 7+
                    networks. Delivered 20+ Chainlink External Adapters.
                    Developers are using these EAs to get data or information
                    for their smart contracts. GitHub Repo. Created plugin to
                    spin up local Chainlink node with Foundry. GitHub Link
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-6 xl:mt-8 flex flex-col md:flex-row gap-6 xl:gap-8 items-stretch">
              <div
                className="p-[0.5px] pb-0 rounded-lg flex flex-1 w-full"
                style={{
                  background:
                    "linear-gradient(110.31deg, #2F79F4 0%, rgba(0, 0, 0, 0) 63.51%)",
                }}
              >
                <div
                  className="p-8 rounded-lg w-full h-full"
                  style={{
                    background:
                      "radial-gradient(100% 505.32% at 0% 0%, #19233a 0%, #141516 100%)",
                  }}
                >
                  <div className="flex w-full justify-between gap-2">
                    <div>
                      <p className="m-0 text-2xl text-white100">
                        Swarm Markets
                      </p>
                      <p className="mb-0 mt-8 text-lg text-white100">
                        Working together since December 2020.
                      </p>
                    </div>
                    <div
                      className="min-w-[90px] h-[90px] bg-no-repeat bg-center bg-cover"
                      style={{
                        backgroundImage: `url(${swarmLogo})`,
                      }}
                    />
                  </div>
                  <p className="mb-0 mt-8 text-base text-white55">
                    As a partner of Swarm since 2020, Protofire has played a key
                    role in developing their compliant multi-asset
                    infrastructure within the regulated environment. Our
                    contributions include KYC services, a multi-tier
                    permissioning system, and automated market-making (AMM) for
                    their decentralized exchange, which offers Swap, Pool, and
                    OTC trading functionalities while ensuring core DeFi
                    benefits like self-custody and on-chain transparency.
                  </p>
                </div>
              </div>
              <div
                className="p-[0.5px] pb-0 rounded-lg flex flex-1"
                style={{
                  background:
                    "linear-gradient(110.31deg, rgba(255, 255, 255, 0.32) 0%, rgba(0, 0, 0, 0) 63.51%)",
                }}
              >
                <div
                  className="p-8 rounded-lg w-full h-full"
                  style={{
                    background:
                      "radial-gradient(100% 505.32% at 0% 0%, #262729 0%, #141516 100%)",
                  }}
                >
                  <p className="m-0 text-2xl text-white100">
                    Large Auditing Company
                  </p>
                  <p className="mb-0 mt-8 text-lg text-white100">
                    Working together since 2019, had a dedicated team of 10+ PF
                    contributors.
                  </p>
                  <p className="mb-0 mt-8 text-base text-white55">
                    Delivered 15+ proof of reserves and real-time attestations
                    for asset-backed tokens. Spinned up and maintain 25 nodes at
                    11 networks.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="max-w-[1120px] mx-auto">
        <section className="mt-[120px] md:mt-[150px] mx-4 md:mx-6 xl:mx-0">
          <GrowTvlAdoption />
        </section>
        <section className="mt-[100px] md:mt-[150px] mx-4 md:mx-6 xl:mx-0">
          <ContactUs />
        </section>
      </div>
    </div>
  );
}

export default About;
