/* eslint-disable react/button-has-type */
import "./sliderCarousel.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import { ReactComponent as ChevronRight } from "../../assets/images/chevronRight.svg";

type SliderCarouselProps = {
  images: { alt: string; src: string }[];
};

function SliderCarousel({ images }: SliderCarouselProps) {
  return (
    <>
      <Swiper
        slidesPerView={2}
        spaceBetween={30}
        centeredSlides
        navigation={{
          prevEl: ".slider-button-prev",
          nextEl: ".slider-button-next",
        }}
        modules={[Navigation]}
        className="mySwiper"
      >
        {images.map((image) => (
          <SwiperSlide className="text-white100" key={image.src}>
            <img src={image.src} alt={image.alt} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="flex justify-center gap-2 mt-12">
        <button className="transition-opacity text-sm font-medium rounded-lg slider-button slider-button-prev h-[40px] w-[40px] flex justify-center items-center text-white100 outlined-btn">
          <ChevronRight className="rotate-180" />
        </button>
        <button className="transition-opacity text-sm font-medium rounded-lg slider-button slider-button-next h-[40px] w-[40px] flex justify-center items-center text-white100 outlined-btn">
          <ChevronRight />
        </button>
      </div>
    </>
  );
}

export default SliderCarousel;
