import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import Menu from "../../components/menu/menu";
import Footer from "../../components/footer/footer";
import CookiesSetup from "../../cookiesSetup";

function MainLayout() {
  return (
    <div
      className="bg-black100"
      style={{
        minHeight: "100vh",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="format-detection" content="telephone=no" />
        <link rel="canonical" href="protofire.io" />
        <title>Protofire | We Build the Future of Web3</title>
        <meta
          name="description"
          content="Build a team of blockchain experts to develop your web3 solutions and accelerate growth of your decentralized ecosystem. Get in touch for details."
        />
        <meta
          name="keywords"
          content="defi crypto, defi trading, crypto defi, defi market, crypto contract, smart contract crypto, cryptocurrency contracts, defi define, crypto developer, smart contract audit, blockchain development, blockchain app development"
        />
      </Helmet>
      <div className="fixed w-full top-6 z-50 mx-auto px-4 xl:px-[144px]">
        <Menu />
      </div>

      <Outlet />

      <Footer />
      <CookiesSetup />
    </div>
  );
}
export default MainLayout;
