/* eslint-disable react/require-default-props */
import { useContext } from "react";
import bookCalendlyCall from "../../../utils/bookCalendlyCall";
import Button from "../button/Button";
import { CallUserContext } from "../../../store";
import { DefaultCallendlyName } from "../../../utils/utils";

function BookCallDontFind({
  name = DefaultCallendlyName,
  callUrlPostfix,
}: {
  name?: string;
  callUrlPostfix?: string;
}) {
  const context = useContext(CallUserContext);
  const handleBookCallClick = () => {
    context?.setCallUser(name);
    bookCalendlyCall(callUrlPostfix);
  };
  return (
    <div
      className="p-8 rounded-xl flex flex-col md:flex-row justify-between items-center mt-[48px] gap-6 md:gap-[96px] xl:gap-8 "
      style={{
        background:
          "linear-gradient(270deg, rgba(255, 255, 255, 0.08) 0%, rgba(153, 153, 153, 0) 100%)",
      }}
    >
      <div className="text-center md:text-left">
        <h3
          className="text-2xl md:text-[32px] md:leading-[40px] text-white100"
          style={{
            fontFamily: "Onest-SemiBold",
          }}
        >
          Haven&apos;t found what you need? That&apos;s not all we offer!
        </h3>
        <p className="mt-2 text-white55">
          Let&apos;s talk on any custom development or solution design.
        </p>
      </div>
      <Button
        className="whitespace-nowrap w-full md:w-auto"
        size="lg"
        onClick={handleBookCallClick}
      >
        Book a call
      </Button>
    </div>
  );
}

export default BookCallDontFind;
