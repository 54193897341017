import { Link } from "react-router-dom";
import { ReactComponent as PeopleImg } from "../../assets/images/people.svg";
import { ReactComponent as PeopleImgMD } from "../../assets/images/peopleMD.svg";
import { ReactComponent as PeopleImgXS } from "../../assets/images/peopleXS.svg";
import Button from "../button/Button";

function GrowTvlAdoption() {
  return (
    <div
      className="rounded-lg p-8 relative flex flex-col items-center md:items-baseline"
      style={{
        background:
          "linear-gradient(270deg, rgba(255, 255, 255, 0.08) 0%, rgba(153, 153, 153, 0) 100%)",
      }}
    >
      <PeopleImgXS className="block md:hidden xl:hidden mb-8" />
      <h3
        className="text-white100 text-2xl md:text-[32px] md:leading-[40px] mb-8 md:max-w-[540px] text-center md:text-left"
        style={{ fontFamily: "Onest-SemiBold" }}
      >
        Grow your TVL and user adoption with Protofire
      </h3>
      <Link to="/services" target="_blank">
        <Button variant="outlined" size="lg">
          Leverage our experience
        </Button>
      </Link>
      <PeopleImg className="absolute right-0 top-1/2 -translate-y-2/4 hidden xl:block" />
      <PeopleImgMD className="absolute right-0 top-1/2 -translate-y-2/4 hidden md:block xl:hidden" />
    </div>
  );
}

export default GrowTvlAdoption;
