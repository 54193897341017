import gnosis from "../assets/projects/gnosis.png";
import cowswap from "../assets/projects/cowswap.png";
import swarmMarket from "../assets/projects/swarmMarket.png";
import makerDAO from "../assets/projects/makerDAO.png";
import aragonDAO from "../assets/projects/aragonDAO.png";
import armanino from "../assets/projects/armanino.png";
import graph from "../assets/projects/graph.png";
import chainlink from "../assets/projects/chainlink.png";
import gnosisConditional from "../assets/projects/gnosisConditional.png";
import kyberDAO from "../assets/projects/kyberDAO.png";
// import opyn from "../assets/projects/opyn.png";
import filecoin from "../assets/projects/filecoin.png";
import oxLauch from "../assets/projects/oxLauch.png";
// import solhint from "../assets/projects/solhint.png";
import chainlinkDevTools from "../assets/projects/chainlink-dev-tooling.png";
import singleButtonSolution from "../assets/projects/single-button-solution.png";
import monitoringForGiga from "../assets/projects/monitoring-for-giga.png";
import bnbToPolygon from "../assets/projects/BNB-to-Polygon-Bridging-for-Kryptomon.png";
import diaOracles from "../assets/projects/DIA-Oracles-on-Kadena.png";

import safeMultisigWallet0 from "../assets/projectDetailsImages/safeMultisigWallet0.png";
import safeMultisigWallet1 from "../assets/projectDetailsImages/safeMultisigWallet1.png";
import safeMultisigWallet2 from "../assets/projectDetailsImages/safeMultisigWallet2.png";
import safeMultisigWallet3 from "../assets/projectDetailsImages/safeMultisigWallet3.png";
import cowswapDex0 from "../assets/projectDetailsImages/cowswapDex0.png";
import cowswapDex1 from "../assets/projectDetailsImages/cowswapDex1.png";
import cowswapDex2 from "../assets/projectDetailsImages/cowswapDex2.png";
import graphSubgraphs0 from "../assets/projectDetailsImages/graphSubgraphs0.png";
import graphSubgraphs1 from "../assets/projectDetailsImages/graphSubgraphs1.png";
import graphSubgraphs2 from "../assets/projectDetailsImages/graphSubgraphs2.png";
import chainlinkIntegration0 from "../assets/projectDetailsImages/chainlinkIntegration0.png";
import chainlinkIntegration1 from "../assets/projectDetailsImages/chainlinkIntegration1.png";
import chainlinkIntegration2 from "../assets/projectDetailsImages/chainlinkIntegration2.png";
import chainlinkIntegration3 from "../assets/projectDetailsImages/chainlinkIntegration3.png";
import swarmDex0 from "../assets/projectDetailsImages/swarmDex0.png";
import swarmDex1 from "../assets/projectDetailsImages/swarmDex1.png";
import filecoin0 from "../assets/projectDetailsImages/filecoin0.png";
import filecoin1 from "../assets/projectDetailsImages/filecoin1.png";
import makerdaoDashboard0 from "../assets/projectDetailsImages/makerdaoDashboard0.png";
import makerdaoDashboard1 from "../assets/projectDetailsImages/makerdaoDashboard1.png";
import armanino0 from "../assets/projectDetailsImages/armanino0.png";
import armanino1 from "../assets/projectDetailsImages/armanino1.png";
import armanino2 from "../assets/projectDetailsImages/armanino2.png";
// import opyn0 from "../assets/projectDetailsImages/opyn0.png";
// import opyn1 from "../assets/projectDetailsImages/opyn1.png";
import aragon0 from "../assets/projectDetailsImages/aragon0.png";
import gnosisExplorer0 from "../assets/projectDetailsImages/gnosisExplorer0.png";
import kyberswap0 from "../assets/projectDetailsImages/kyberswap0.png";
import kyberswap1 from "../assets/projectDetailsImages/kyberswap1.png";
import kyberswap2 from "../assets/projectDetailsImages/kyberswap2.png";
import oxLunch0 from "../assets/projectDetailsImages/oxLunch0.png";
import oraclesDia1 from "../assets/projectDetailsImages/oracles_dia1.png";
import oraclesDia2 from "../assets/projectDetailsImages/oracles_dia2.png";
import kryptomon0 from "../assets/projectDetailsImages/kryptomon0.png";
import bigDataProtocol0 from "../assets/projectDetailsImages/bigDataProtocol0.png";
import bigDataProtocol1 from "../assets/projectDetailsImages/bigDataProtocol1.png";
import monitoringPlatformGiga0 from "../assets/projectDetailsImages/monitoringPlatformGiga0.png";

import alejandro from "../assets/team/alejandro.png";

export const tags = [
  "ALLTAGS",
  "Chainlink",
  "Foundry",
  "Big Data Protocol",
  "Solution",
  "Smart Contract",
  "Wallet",
  "dApp",
  "Node Management",
  "Interface",
  "DeFi",
  "DEX",
  "KYC",
  "OTC",
  "Subgraph",
  "DevOps",
  "Oracle",
  "API",
  "Governance",
  "Dashboard",
  "Monitor",
  "Payments",
  "Platform",
  "Audit",
  // "Insurance",
  "Integration",
  "Explorer",
  "Launch Kit",
  "CCIP",
  "NFT",
];

export const projects = [
  {
    id: "dia-oracles-kadena",
    title: "DIA Oracles on Kadena",
    text: "Protofire integrated DIA oracles on Kadena, enabling 14 asset price feeds for blockchain protocols. Learn about our innovative approach to expanding oracle services and enhancing DeFi capabilities on the Kadena network.",
    tagList: ["Oracle", "Smart Contract", "Integration"],
    link: "/projects/dia-oracles-kadena",
    img: diaOracles,
  },
  {
    id: "kryptomon",
    title: "BNB-to-Polygon Bridging for Kryptomon",
    text: "Protofire has leveraged Chainlink's CCIP to create a secure, one-time, and one-direction bridge infrastructure to transfer the Kryptomon assets (ERC20 and NFTs) from BNB to Polygon chain using an innovative approach to blockchain interoperability and asset transfer for gaming ecosystems.",
    tagList: ["CCIP", "Integration", "Smart Contract", "NFT"],
    link: "/projects/kryptomon",
    img: bnbToPolygon,
  },
  {
    id: "chainlink-dev-tools",
    title: "Chainlink Developer Tools",
    text: "The Chainlink Developer Tools, developed by Protofire, simplify interactions with Chainlink services and facilitate local testing of dApps that integrate them.",
    tagList: ["Chainlink", "Foundry"],
    link: "/projects/chainlink-dev-tools",
    img: chainlinkDevTools,
  },
  {
    id: "single-button-solution",
    title: "Single-button Solution for Big Data Protocol",
    text: "Protofire has developed a Single-button solution for Big Data Protocol to help accelerate the deployment and testing of new Ocean features for its data marketplace.",
    tagList: ["Big Data Protocol", "Solution"],
    link: "/projects/single-button-solution",
    img: singleButtonSolution,
  },
  {
    id: "monitoring-for-giga",
    title: "Monitoring Platform for Giga",
    text: "Protofire has built a platform for Giga, an organization of which Unicef takes part of, that has as a goal to connect every school to the internet to empower people and help students develop.",
    tagList: ["Big Data Protocol", "Solution"],
    link: "/projects/monitoring-for-giga",
    img: monitoringForGiga,
  },
  // {
  //   id: "solhint",
  //   title: "Solhint Solidity Linter",
  //   text: "Solhint helps to write high-quality, secure, and bug-free smart contract code.",
  //   tagList: ["Explorer", "Node Management"],
  //   link: "/projects/solhint",
  //   img: solhint,
  // },
  {
    id: "gnosis-safe-wallet",
    title: "Safe Multisig Wallet",
    text: "Protofire has worked alongside Safe as a partner on the development of the Safe technology. Safe Multisig is a customizable crypto wallet running on Ethereum that requires a predefined number of signatures to confirm transactions to prevent unauthorized access to the assets stored. DeFi apps can be accessed and user funds can be put to work from inside the safe interface. There are currently dozens of billions in funds stored in safes.",
    tagList: ["dApp", "Interface", "Smart Contract", "Wallet"],
    link: "/projects/gnosis-safe-wallet",
    img: gnosis,
  },
  {
    id: "cowswap-dex",
    title: "CowSwap DEX",
    text: `Protofire has been working in sync with the CoW Protocol team in the 
      rebranding, development, and testing of a web trading interface built 
      on top of the protocol, which allows users to buy and sell tokens using 
      gas-less orders, as well as the improvement of an affiliate program to 
      promote the referrals in the community.`,
    tagList: ["dApp", "DeFi", "DEX", "Interface", "SDK"],
    link: "/projects/cowswap-dex",
    img: cowswap,
  },
  {
    id: "the-graph-subgraphs",
    title: "Subgraph & Node Management on The Graph",
    text: `Protofire provides ongoing assistance to onboard Dapps to The Graph. 
    For instance, our team helps to validate smart contracts and on-chain data, define 
    the roadmap for subgraph development and adoption, as well as deploy new projects 
    on multiple EVM networks.`,
    tagList: ["Subgraph", "DevOps", "Node Management"],
    link: "/projects/the-graph-subgraphs",
    img: graph,
  },
  {
    id: "chainlink-integration",
    title: "Chainlink Integration",
    text: `Supported by Chainlink Community Grants, we have built a team 
      that works with Chainlink infrastructure in order to bring oracle infrastructure
      to wherever it may be needed in the Web3 ecosystem.`,
    tagList: ["Oracle", "Integration", "API", "DevOps"],
    link: "/projects/chainlink-integration",
    img: chainlink,
  },
  {
    id: "swarm-dex",
    title: "Swarm Markets DEX",
    text: `Protofire has received several grants from Protocol Labs and has been 
      working together with the Protocol Labs team (as contributors to Glif tools) 
      since before the launch of the Filecoin Mainnet.`,
    tagList: ["dApp", "Dashboard", "DeFi", "DEX", "KYC", "OTC"],
    link: "/projects/swarm-dex",
    img: swarmMarket,
  },
  {
    id: "filecoin-node-infrastructure",
    title: "Filecoin Node Infrastructure",
    text: `Protofire team has worked on the architecture, smart contracts, 
      and front end for Swarm, which provides compliant multi-asset DeFi infrastructure. 
      KYC services are inbuilt within the platform.`,
    tagList: ["API", "Explorer", "DevOps", "Node Management"],
    link: "/projects/filecoin-node-infrastructure",
    img: filecoin,
  },
  {
    id: "makerdao-dashboard",
    title: "Maker Governance Analytics Dashboard",
    text: "Protofire built Maker Governance Analytics Dashboard. It provides users with a single location to track the latest updates and view historical data.",
    tagList: ["Dashboard", "Monitor", "Interface", "Governance", "DeFi"],
    link: "/projects/makerdao-dashboard",
    img: makerDAO,
  },
  {
    id: "armanino-platform",
    title: "Armanino TrustExplorer Platform",
    text: `Protofire has been working with Armanino for more than three years 
      and has been assisting in the development of four solutions under the TrustExplorer 
      project: a blockchain-based assurance platform (Real-Time Audit)`,
    tagList: ["Platform"],
    link: "/projects/armanino-platform",
    img: armanino,
  },
  // {
  //   id: "opyn-platform",
  //   title: "Opyn Decentralized Insurance Platform",
  //   text: `We have supported Opyn on the development of the user interface of
  //     their Decentralized Insurance Platform, as well as integrated it with a backend
  //     and Web3 calls.`,
  //   // tagList: ["Platform", "Audit", "Insurance", "Interface", "Integration"],
  //   tagList: ["Platform", "Audit", "Interface", "Integration"],
  //   link: "/projects/opyn-platform",
  //   img: opyn,
  // },
  {
    id: "aragon-dapp",
    title: "AragonDAO Payroll dApp",
    text: `The Aragon application allows users to incorporate new employees 
      (integrated with the identity and finance apps), request and pre-authorize payments 
      in different tokens, as well as view all the payroll information, including 
      historic payments, balance, and statistics.`,
    tagList: ["dApp", "Dashboard", "Payments"],
    link: "/projects/aragon-dapp",
    img: aragonDAO,
  },
  {
    id: "gnosis-explorer",
    title: "Gnosis Conditional Tokens Explorer",
    text: "Protofire has contributed to the development of CTE’s interface. It was built with a focus on providing a good user experience. We also created hierarchical and custom components with specialized functions.",
    tagList: ["dApp", "Explorer", "Interface"],
    link: "/projects/gnosis-explorer",
    img: gnosisConditional,
  },
  {
    id: "kyber-smart-contract",
    title: "KyberDAO Governance Smart Contract",
    text: "In partnership with Kyber Network, Protofire created a trustless smart contract proxy - a non-custodial delegation service - for the KyberDAO governance.",
    tagList: ["Smart Contract", "Governance"],
    link: "/projects/kyber-smart-contract",
    img: kyberDAO,
  },
  {
    id: "ox-launch-kit",
    title: "0x Launch Kit",
    text: "Protofire has developed a repository that contains an open-source, free-to-use 0x relayer template that one can use as a starting point for one’s own project.",
    tagList: ["Launch Kit"],
    link: "/projects/ox-launch-kit",
    img: oxLauch,
  },
];

export const projectDetailsMap = {
  "dia-oracles-kadena": {
    metaTitle: "DIA Oracles on Kadena: Expanding DeFi Capabilities | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Protofire integrates DIA oracles on Kadena, enabling 14 asset price feeds. Enhance your DeFi projects with reliable, real-time data on the Kadena network",
      },
      {
        name: "keywords",
        content:
          "Kadena oracle integration, DIA price feeds, Blockchain oracle solutions, DeFi infrastructure, Pact smart contracts",
      },
    ],
    title: "DIA Oracles on Kadena",
    tagList: ["Oracle", "Smart Contract", "Integration"],
    description: [
      "Protofire integrated DIA oracles on Kadena, enabling 14 asset price feeds for blockchain protocols to expand oracle services and enhance DeFi capabilities on the Kadena network.",
    ],
    about: [
      "We partnered with DIA to port and deploy their oracle smart contracts to the Kadena blockchain, expanding the availability of reliable price feeds for Kadena-based projects.",
    ],
    mainImage: oraclesDia1,
    images: [
      { alt: "oraclesDia1", src: oraclesDia1 },
      { alt: "oraclesDia2", src: oraclesDia2 },
    ],
    additionalContent: [
      {
        title: "The problem",
        description: [
          "Projects on Kadena were limited in the assets they could offer due to a lack of available price feeds on the network. This constraint hindered the growth and diversity of DeFi applications on Kadena.",
        ],
      },
      {
        title: "The solution",
        description: [
          "Protofire successfully integrated DIA as an oracle provider for 14 asset prices (tokens and fiat) using DIA GraphQL as the data source. Along with detailed documentation creation, we expanded the capabilities of DeFi applications on Kadena and established a foundation for future oracle implementations on the network.",
          "Our solution included:",
        ],
        items: [
          "Porting existing DIA smart contracts to Pact, Kadena's programming language",
          "Implementing data update feeders for asset prices and randomness",
          "Creating deployment scripts and Docker configurations",
          "Developing a TypeScript library for internal monitoring",
          "Providing comprehensive documentation for deployment and usage",
        ],
      },
      {
        title: "The client/partner",
        description: [
          "Kadena is an innovative blockchain platform that addresses scalability issues through its unique multi-chain architecture called Chainweb. It features Pact, a user-friendly smart contract language, and offers high throughput and low transaction costs. Founded by former JPMorgan blockchain experts, Kadena aims to serve both enterprise and public blockchain use cases, positioning itself as a scalable and secure solution for various applications including DeFi, NFTs, and gaming.",
        ],
      },
    ],
    technologyStack: {
      // description: ["TECHNOLOGIES"],
      items: [
        {
          title: "Programming Languages",
          items: ["Pact (Kadena's programming language)", "TypeScript"],
        },
        {
          title: "SDKs and Librariess",
          items: ["Kadena's JS SDK"],
        },
        {
          title: "Development Tools",
          items: ["Docker"],
        },
        {
          title: "APIs",
          items: ["DIA GraphQL API"],
        },
      ],
    },
    codeData: {
      items: [
        {
          title: "GitHub DIA Kadena Oracles Repository",
          link: "https://github.com/diadata-org/dia-kadena-oracles",
        },
        {
          title: "Kadena Price Oracles Documentation",
          link: "https://docs.diadata.org/introduction/dia-technical-structure/data-delivery-methods/integrated-l1-l2-networks/kadena-price-oracles",
        },
        {
          title: "DIA Price Feed for Kadena",
          link: "https://www.diadata.org/app/price/asset/Kadena/0x0000000000000000000000000000000000000000/",
        },
      ],
    },
    mediaData: {
      items: [
        {
          title: "The Importance of Robust Data Sourcing for Oracles",
          link: "https://www.kadena.io/blog/dia-grantee-spotlight",
        },
        {
          title: "Dia Oracles Available on Dia Mainnet ",
          link: "https://www.diadata.org/blog/post/dia-price-oracles-kadena-mainnet/",
        },
      ],
    },
    bottomSlogan: "Are you in need of an oracle integration for your project?",
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  // ----------------------------------------------------------------------------

  kryptomon: {
    metaTitle: "Kryptomon: Migration from BNB to Polygon | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Protofire leverages Chainlink CCIP for secure, one-time ERC20 and NFT asset transfer from BNB to Polygon. Streamline your blockchain migration.",
      },
      {
        name: "keywords",
        content:
          "Cross-chain asset migration, Chainlink CCIP integration, NFT blockchain transfer, Gaming asset migration, BNB to Polygon bridge",
      },
    ],
    title: "BNB-to-Polygon Bridging for Kryptomon",
    tagList: ["CCIP", "Integration", "Smart Contract", "NFT"],
    description: [
      "Protofire designed and implemented a secure, one-time, one-direction bridge infrastructure for Kryptomon, leveraging Chainlink's CCIP solution to efficiently transfer ERC20 and NFT assets from Binance Smart Chain to Polygon. This innovative approach streamlined the migration process, preparing Kryptomon for the launch of their cross-platform MMORPG, KMON: World of Kogaea, on the Polygon network.",
    ],
    about: [
      "Kryptomon sought to migrate all its digital assets to Polygon in preparation for the launch of KMON: World of Kogaea, a cross-platform MMORPG.",
    ],
    mainImage: kryptomon0,
    // images: [
    //   { alt: "oraclesDia0", src: oraclesDia0 },
    //   { alt: "oraclesDia1", src: oraclesDia1 },
    // ],
    additionalContent: [
      {
        title: "The problem",
        description: [
          "Initially, the project aimed to create an infrastructure for migrating NFT assets from Binance Smart Chain to Polygon using a multisig bridge implementation. This involved setting up three keys: one on Digital Ocean, another on AWS, and a third as a cold wallet.",
        ],
      },
      {
        title: "The solution",
        description: [
          "After investigation, we pivoted to using Chainlink's CCIP (Cross-Chain Interoperability Protocol) solution. As long-term Chainlink partners, we leveraged this new technology to move token data between chains securely and efficiently.",
        ],
        items: [
          "Successful migration of NFT game assets to a new chain",
          "Secure transfer of NFTs across chains",
          "Streamlined infrastructure for future cross-chain operations",
        ],
        descriptionExtra: {
          text: ["Our solution supports:"],
          items: [
            "Transferring data from the source network",
            "Retrieving metadata on the target network for ERC1155 and ERC721 tokens",
            "Minting expected ERC20 tokens on the destination chain",
          ],
        },
      },
      {
        title: "The client/partner",
        description: [
          "Kryptomon is a blockchain-based gaming platform that combines elements of collectible monsters, battling, and breeding. Players can collect, train, and evolve digital creatures called Kryptomons, each with unique DNA and elemental abilities. The game offers various features including dungeon battles, trading card games, and a marketplace for buying and selling in-game assets, all while emphasizing player ownership and an open economy.",
        ],
      },
    ],
    technologyStack: {
      items: [
        {
          title: "Frameworks",
          items: ["Chainlink CCIP"],
        },
        {
          title: "Programming Languages",
          items: ["Smart Contracts (Solidity)"],
        },
      ],
    },
    mediaData: {
      items: [
        {
          title: "CCIP for Kryptomon",
          link: "https://x.com/chainlink/status/1775887809615732944",
        },
      ],
    },
    bottomSlogan:
      "Are you in need of a cross-chain integration for your project?",
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  // ----------------------------------------------------------------------------

  "chainlink-dev-tools": {
    metaTitle:
      "Chainlink Developer Tools: Simplify dApp Integration | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Streamline Chainlink integration with our Hardhat Plugin and Foundry Toolkit. Easily test and deploy smart contracts using local nodes and CLI.",
      },
      {
        name: "keywords",
        content:
          "Chainlink integration tools, Smart contract development, Blockchain dApp testing, Web3 framework plugins, Decentralized oracle solutions",
      },
    ],
    mainImage: chainlinkIntegration0,
    title: "Chainlink Developer Tools",
    tagList: ["Chainlink", "Foundry"],
    description: [
      "The Chainlink Developer Tools, developed by Protofire, simplify interactions with Chainlink services and facilitate local testing of dApps that integrate them.",
      "Protofire has developed the Chainlink Developer Tools, which include toolkits and plugins for modern Web3 frameworks, such as Hardhat and Foundry. These tools simplify interactions with Chainlink services, including Data Feeds, VRF, Functions, and Automations. They also facilitate local testing of dApps that integrate Chainlink, helping with the setup and management of local Chainlink nodes and simulations.",
    ],
    about: [
      "Chainlink Developer Tools include the Hardhat Plugin and the Foundry Toolkit. These standalone applications utilize TypeScript in Hardhat, along with Forge, Make, and Solidity Scripting in Foundry. They offer functionality for interacting with Chainlink services, spinning up local Chainlink nodes, creating Chainlink Jobs, and deploying and interacting with related Smart Contracts. All functionalities are accessible both directly in the code of a project utilizing a specific framework and via CLI commands.",
    ],
    aboutItems: [
      "Multi Solidity compiler support",
      "Local testing",
      "Convenient interaction with Chainlink services",
    ],
    additionalContent: [
      {
        title: "The problem",
        description: [
          "Developers who relied on Chainlink tools within their projects faced a lack of convenient, streamlined methods for smooth development and operation.",
          "The integration of Chainlink tools with the Foundry and Hardhat frameworks has solved this challenge offering ready-made functionality for Chainlink node developers.",
        ],
      },
      {
        title: "The solution",
        description: [
          "Chainlink Developer Tools introduce unified methods and a Command-Line Interface using TypeScript, Forge, and Solidity Scripting that encompass functionalities of Chainlink, Hardhat and Foundry. This consolidation smooths the development process, allowing developers to interact with Chainlink services without the need to switch between separate frameworks and tools.",
          "These Developer Tools are designed so that they can be launched through CLI and integrated into the project code using the corresponding framework.",
          "Chainlink Developer Tools documentation covers step-by-step instructions and use cases, enabling developers to leverage Chainlink features effortlessly.",
        ],
      },
      {
        title: "Hardhat Chainlink Plugin",
        description: [
          "The Hardhat Chainlink Plugin allows the integration of the Chainlink functionality into your web3 development workflow through seamless interaction between Chainlink services and Hardhat-based projects. The plugin supports Data Feeds, VRF, Automation and Functions. Developers can use the plugin’s sandbox to spin up a local Chainlink node, run local tests and simulations against it.",
        ],
      },
      {
        title: "Foundry Chainlink Toolkit",
        description: [
          "The Foundtry Chainlink toolkit provides atomic methods to interact with smart contracts of the Chainlink services: Data Feeds, VRF, Automation and Functions. This plugin allows to easily integrate Chainlink functionality into the web3 development workflow of existing Foundry projects. Developers can use this plugin to spin up a local cluster of Chainlink nodes, run local tests and simulations against it.",
        ],
      },
      {
        title: "The client/partner",
        description: [
          "Chainlink is a decentralized oracle network that enables smart contracts on blockchain platforms to securely interact with real-world data, external APIs, and traditional payment systems.",
        ],
      },
    ],
    technologyStack: {
      description: [
        "We leverage the most suitable technologies for each solution to help our customers achieve their business goals more swiftly.",
      ],
      items: [
        {
          title: "Programming Languages",
          items: [
            "Typescript",
            "Solidity Scripting",
            "Solidity",
            "Bash + make",
          ],
        },
        {
          title: "Database",
          items: ["Postgres as a part of Chainlink nodes"],
        },
        {
          title: "Network & Protocol",
          items: ["EVM-compatible networks"],
        },
        {
          title: "Infrastructure",
          items: ["Docker", "Chainlink oracles"],
        },
      ],
    },
    codeData: {
      // description: [
      //   "We leverage the most suitable technologies for each solution to help our customers achieve their business goals more swiftly.",
      // ],
      items: [
        {
          title:
            "Developer Tooling for Oracle Contracts Explained by Dmitrii Kotelkin at SmartCon 2023",
          link: "https://www.youtube.com/watch?v=r8S0pomjuvM&t=1s",
        },
        {
          title: "Hardhat Chainlink Plugin",
          link: "https://docs.chain.link/quickstarts/hardhat-plugin",
        },
        {
          title: "Foundry Chainlink Toolkit",
          link: "https://docs.chain.link/quickstarts/foundry-chainlink-toolkit",
        },
      ],
    },
    bottomSlogan: "Is your project in need of dev tools?",
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  //-----------------------------------------------------------------------------

  "single-button-solution": {
    metaTitle: "Single-Button Solution for Big Data Protocol | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Accelerate your Protocol updates with our Single-button Deploy solution.",
      },
      {
        name: "keywords",
        content:
          "Data marketplace automation, Ocean Protocol deployment, Blockchain development efficiency, DeFi infrastructure solutions, Big Data Protocol integration",
      },
    ],
    title: "Single-button Solution for Big Data Protocol",
    tagList: ["Big Data Protocol", "Solution"],
    description: [
      "Protofire has developed a Single-button solution for Big Data Protocol to help accelerate the deployment and testing of new Ocean features for its data marketplace.",
    ],
    mainImage: bigDataProtocol0,
    images: [{ alt: "bigDataProtocol1", src: bigDataProtocol1 }],
    about: [
      "The Big Data Protocol’s data marketplace aims to provide fast operation in a user-friendly environment for end users and developers.",
      "Single-button saves time spent on testing, allowing developers to allocate even more effort to new feature implementation.",
    ],
    additionalContent: [
      {
        title: "The problem",
        description: [
          "The Ocean Protocol regularly updates its upstream repository, making update tracking somewhat time-consuming for forks running on Ocean. Developers spend time on rebasing the updates. It usually takes more than a day and up to a week to redeploy the updated components of Ocean infrastructure and test the new features.",
        ],
      },
      {
        title: "The solution",
        description: [
          "Protofire has designed and implemented the Single-button Deploy solution that allows development teams to automatically deploy a fully functional version of Ocean components such as provider, subgraph, and marketplace.",
          "The solution is based on Github commits. It drastically simplifies the development process by reducing the implementation and testing time. As such, Single-button can reduce the deployment phase of a release testing from 8-40 hours to 2 hours, saving time and budget and making new Ocean features available for BDP users faster.",
        ],
      },
      {
        title: "The client/partner",
        description: [
          "<a target='_blank' href='https://bigdataprotocol.com/' class='underline underline-offset-1 text-white100'>Big Data Protocol</a> is a data marketplace (a fork of Ocean Protocol marketplace) that sources commercially valuable data from professional data providers, tokenizes it, and makes it liquid.",
        ],
      },
    ],
    technologyStack: {
      description: [
        "We leverage the most suitable technologies for each solution to help our customers achieve their business goals more swiftly.",
      ],
      items: [
        {
          title: "Programming Languages",
          items: ["Python", "Node.JS", "Terraform"],
        },
        // {
        //   title: "Frameworks and Libraries",
        //   items: ["Node.js", "React", "Styled Components"],
        // },
        {
          title: "Infrastructure",
          items: ["Postgres as a part of Chainlink nodes"],
        },
      ],
    },
    codeData: {
      items: [
        {
          title: "GitHub",
          link: "https://github.com/bigdataprotocol/",
        },
      ],
    },
    mediaData: {
      items: [
        {
          title: "Live Product",
          link: "https://market.bigdataprotocol.com/",
        },
      ],
    },
    bottomSlogan:
      "Have you been thinking of developing a blockchain solution for your protocol?",
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  //-----------------------------------------------------------------------------

  "monitoring-for-giga": {
    metaTitle: "Giga Monitoring Platform | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Protofire's blockchain-based platform for Giga connects governments, ISPs, and schools to monitor internet service quality for 110,000 schools in Brazil and Botswana.",
      },
      {
        name: "keywords",
        content:
          "School internet monitoring, Blockchain education solutions, Giga connectivity platform, Digital divide reduction, Educational technology infrastructure",
      },
    ],
    title: "Monitoring Platform for Giga",
    tagList: ["Big Data Protocol", "Solution"],
    description: [
      "Protofire has built a platform for Giga, an organization of which Unicef takes part of, that has as a goal to connect every school to the internet to empower people and help students develop.",
      "For that, Protofire has created a platform to connect governments, internet service providers and schools, so we could keep track of the service students are obtaining and governments can pay providers that satisfy the terms on their end of the contract.",
    ],
    mainImage: monitoringPlatformGiga0,
    images: [{ alt: "monitoringPlatformGiga0", src: monitoringPlatformGiga0 }],
    about: [
      "The Giga team has approached to Protofire to build a system that ensures transparency around the delivery of Internet services between Brazilian/Botswana governments, schools, and providers.",
    ],
    additionalContent: [
      {
        title: "The problem",
        description: [
          "Under the project, the team at Protofire had to address the following issues:",
        ],
        items: [
          "As the platform was to gather real-time data around the Internet connection (speed, latency, etc.) from 110,000 schools, performance was crucial.",
          "To protect sensitive information (contracts, payments, etc.), ensuring security was key.",
          "The API utilized in the previous projects for other countries to gather metrics (e.g., bandwidth and latency) returned inconsistent data. For instance, download speed could return as a numerical value or a word. On top, it was impossible to capture uptime.",
        ],
      },
      {
        title: "The solution",
        description: [
          "Partnering with Protofire, the organization developed a platform that facilitates the contract creation and payments between Internet service providers and Brazilian/Botswana governments. The system also enables 110,000 school in these countries to monitor the quality of service provided.",
          "Thanks to the architectural approach and CI/CD pipelines, the customer can easily expand the prototype and roll out new features in a matter of minutes.",
          "In addition to cryptocurrency payments, the integration of Metamask ensured that the transactions are permanently recorded in a blockchain, allowing governments to quickly audit budget allocation.",
        ],
      },
      {
        title: "The client/partner",
        description: [
          "Launched in 2019 as an international initiative, the Giga organization aims to connect every school in the world to the Internet by 2030. Thus far, the initiative has provided internet access to over 2 million students in more than 130 countries.",
        ],
      },
    ],
    technologyStack: {
      description: [
        "We leverage the most suitable technologies for each solution to help our customers achieve their business goals more swiftly.",
      ],
      items: [
        {
          title: "Programming Languages",
          items: ["Typescript"],
        },
        {
          title: "Frameworks and Libraries",
          items: [
            "Azure Static Web Apps",
            "GitHub Actions",
            "Ether.js",
            "Blocknative",
            "Gnosis Safe",
            "Metamask",
          ],
        },
        {
          title: "Infrastructure",
          items: ["Microsoft Azure", "PostgreSQL", "Azure Blob Storage"],
        },
      ],
    },
    bottomSlogan:
      "Have you been thinking of building a blockchain solution for your protocol?",
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  //-----------------------------------------------------------------------------

  "gnosis-safe-wallet": {
    metaTitle: "Safe Multisig Wallet: Secure Asset Management | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Customize your crypto security with Safe Multisig. Multi-signature protection for DeFi investments, developed by Protofire. Manage billions securely.",
      },
      {
        name: "keywords",
        content:
          "Ethereum multisig wallet, DeFi asset security, Crypto fund management, Blockchain transaction safety, Multi-signature technology",
      },
    ],
    title: "Safe Multisig Wallet",
    tagList: ["dApp", "Interface", "Smart Contract", "Wallet"],
    description: [
      "Protofire has worked alongside Safe as a partner on the development of the Safe technology. Safe Multisig is a customizable crypto wallet running on Ethereum that requires a predefined number of signatures to confirm transactions to prevent unauthorized access to the assets stored. DeFi apps can be accessed and user funds can be put to work from inside the safe interface. There are currently dozens of billions in funds stored in safes.",
    ],
    mainImage: safeMultisigWallet0,
    images: [
      { alt: "safeMultisigWallet1", src: safeMultisigWallet1 },
      { alt: "safeMultisigWallet2", src: safeMultisigWallet2 },
      { alt: "safeMultisigWallet3", src: safeMultisigWallet3 },
      { alt: "safeMultisigWallet0", src: safeMultisigWallet0 },
    ],
    about: [
      "Protofire helped Safe to develop the Safe technology. Safe Multisig is a customizable crypto wallet running on Ethereum.",
    ],
    metrics: [
      { value: "$100B", text: "of Total Value Locked in Q1/2022" },
      { value: "48K+", text: "of total safes created in Q1/2022" },
      { value: "458K+", text: "of total transactions made in Q1/2022" },
    ],
    aboutItems: [
      `Safe technology built by experts`,
      `Usage of non-custodial wallets`,
      `Financial management tool`,
      `Multisignature for teams and individuals`,
      `Versions: Web, Desktop, and Mobile apps`,
      `Gnosis Safe supports ETH, ERC20 (Tokens) and ERC721 (NFTs)`,
      `Users can see the value of their assets in several currencies`,
      `A balance between usability, security, and self-custody`,
    ],
    additionalContent: [
      {
        title: "The problem",
        description: [
          "According to Elliptic, overall losses caused by DeFi exploits have totaled $12 billion in 2021. Security measures in the ecosystem are still limited. The reasons for such hacks to succeed are the usage of:",
        ],
        items: [
          "Poor security systems",
          "Unsafe wallets",
          "Software developed by amateurs",
          "Lack of enterprise management tools",
          "Users don’t control their own assets",
        ],
      },
      {
        title: "The solution",
        description: [
          "To promote greater security for users of the blockchain ecosystems, Gnosis Safe provides a multisignature transaction platform. The solution is tailored for teams and individuals seeking greater security and money management options. A few features are:",
        ],
        items: [
          "Users can invest in DeFi directly from the interface.",
          "DeFi investments include earn, borrow, invoice, payroll, etc.",
          "Open source: It means that it can be upgraded to a network's needs. Also, it means that code can be verified and audited by anyone.",
          "Batched transactions: The ability to batch multiple transactions into one to reduce costs.",
          "Safe Module: Safe allows modular extensions to its multi-signature execution flow.",
          "Safe Guard: Security logic extension that enforces additional validation checks on accounts.",
          "Gasless signatures.",
          "Synthetix Mintr Safe App: The team at Protofire delivered the Synthetix Mintr Safe App - a dApp to <a href='https://blog.synthetix.io/staking-snx-gnosis-safe-wallets/' class='underline underline-offset-1 text-white100' target='_blank'>mint</a> synthetic assets by stacking SNX tokens directly from the Gnosis Safe Multisig wallet.",
        ],
      },
      {
        title: "The client/partner",
        description: [
          "Protofire team has worked alongside Gnosis Safe as a partner on the development of the first version of the Gnosis Safe multisig. Gnosis Safe turned out to become one of the most trusted platforms to manage digital assets in the Ethereum ecosystem.",
        ],
      },
    ],
    technologyStack: {
      description: [
        "We leverage the most suitable technologies for each solution to help our customers achieve their business goals more swiftly.",
      ],
      items: [
        {
          title: "Programming Languages",
          items: ["React", "Node.js", "Python", "AWS", "CloudFormation"],
        },
        {
          title: "Tools and Libraries",
          items: ["Storybook", "Styled Components", "Jest Rust"],
        },
        {
          title: "Infrastructure",
          items: [
            "Redis / ElastiCache",
            "RDS / PostgreSQL",
            "L1 blockchains (full nodes)",
          ],
        },
      ],
    },
    mediaData: {
      items: [
        {
          title:
            "Protofire Secures Assets and Transactions in Moonbeam with a Fork of Gnosis Safe",
          link: "https://medium.com/protofire-blog/protofire-secures-assets-and-transactions-in-moonbeam-with-a-fork-of-gnosis-safe-26b185a30cd1",
        },
        {
          title:
            "Protofire Deploys a Fork of Gnosis Safe Multisig into Moonriver to Enforce Security",
          link: "https://medium.com/protofire-blog/protofire-deploys-a-fork-of-gnosis-safe-multisig-into-moonriver-to-enforce-security-13e8630d9569",
        },
        {
          title: "BDP Market",
          link: "https://market.bigdataprotocol.com/",
        },
      ],
    },
    bottomSlogan: "Need to develop or deploy a wallet on your network?",
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  //-----------------------------------------------------------------------------

  "cowswap-dex": {
    metaTitle: "CowSwap DEX: Gasless Trading & MEV Protection | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Explore CowSwap's gasless trading and MEV protection. Protofire-developed interface for CoW Protocol with affiliate program and enhanced UX.",
      },
      {
        name: "keywords",
        content:
          "Gasless token trading, MEV protection DEX, CoW Protocol development, Decentralized exchange interface, Blockchain trading solutions",
      },
    ],
    title: "Cowswap DEX",
    tagList: ["dApp", "DeFi", "DEX", "Interface", "SDK"],
    description: [
      `Protofire has been working in sync with the CoW Protocol team in the 
    rebranding, development, and testing of a web trading interface built 
    on top of the protocol, which allows users to buy and sell tokens using 
    gas-less orders, as well as the improvement of an affiliate program to 
    promote the referrals in the community. Additionally, Protofire has assisted 
    in the development of the SDK, the Explorer, the Batch Viewer 
    integration (CowSwap and CoW Protocol Explorer), UI maintenance and 
    UX improvements, and The Graph integration.`,
    ],
    mainImage: cowswapDex0,
    images: [
      { alt: "cowswapDex0", src: cowswapDex0 },
      { alt: "cowswapDex1", src: cowswapDex1 },
      { alt: "cowswapDex2", src: cowswapDex2 },
    ],
    aboutItems: [
      `Protofire has provided a dedicated team to work on several tasks 
      alongside the CoW Protocol team for an unlimited period of time`,
      `Support for the rebranding of Gnosis Protocol to CoW Protocol`,
      `Front end development of the explorer`,
      `Maintenance and improvements`,
      `Providing QA services`,
      `Promoting network growth through an affiliate program`,
    ],
    metrics: [
      { value: "$8.00B", text: "Sell Volume GPv2 in Q1/2022" },
      { value: "169.4K", text: "batches solved in Q1/2022" },
      { value: "236.2K", text: "total trades in Q1/2022" },
    ],
    additionalContent: [
      {
        title: "The problem",
        description: [
          "Gnosis Protocol has decided to both update the technology and rebrand to CoW Protocol. CoW has the reference “Coincidence of Wants” in mind, a core aspect of the protocol’s algorithm.",
        ],
      },
      {
        title: "The solution",
        description: [
          "We have directly helped with the development and testing of the:",
        ],
        items: [
          "Affiliate program: The objective of the program is to <a href='https://cowswap.exchange/#/profile' class='underline underline-offset-1 text-white100'>bring</a> new users to the platform and generate more financial volume.",
          "Explorer: We have been working on extending <a href='https://explorer.cow.fi/' class='underline underline-offset-1 text-white100'>functionalities</a> like user details, token values, transaction view (list and batch views), markets traded and other CoW protocol information in supported networks. (Ethereum Mainnet, Gnosis Chain and Rinkeby)",
          "View batched transactions and transaction map.",
          "SDK: The development of a devkit to <a href='https://github.com/gnosis/cow-sdk' class='underline underline-offset-1 text-white100'>create</a> a set of tools to improve the synchronization of the code in the Gnosis DApps as well as adding the ability to interact with the CoW Protocol in an easy way.",
          "Subgraph: Indexing Mainnet, Rinkeby, and Gnosis Chain. <a href='https://github.com/cowprotocol/subgraph' class='underline underline-offset-1 text-white100'>Fully working version.</a>",
          "Batch Viewer Integration: Both for the CowSwap and CoW Protocol Explorer.",
          "UI maintenance and UX improvements to the interface: Such as adding additional details to the profile page, visualization of the orders execution process, increasing the number of supported wallets and L2 chains, etc.",
        ],
      },
      {
        title: "The client/partner",
        description: [
          `CowSwap is the first trading interface built on top of CoW 
    Protocol. CowSwap is a fully permissionless decentralized trading protocol that 
    leverages Batch Auctions as its price-finding mechanism.`,
          `It is the first trading venue to operate with the CoW Protocol 
    (Coincidence Of Wants), in which investors buy and sell tokens using 
    gasless orders that are settled peer-to-peer among its users, or into 
    any on-chain liquidity source while providing MEV protection.`,
        ],
      },
    ],
    technologyStack: {
      description: [
        "We leverage the most suitable technologies for each solution to help our customers achieve their business goals more swiftly.",
      ],
      items: [
        {
          title: "Programming Languages",
          items: ["React", "TypeScript"],
        },
        {
          title: "Frameworks and Libraries",
          items: ["Storybook", "Styled Components", "Jest Rust"],
        },
        {
          title: "Infrastructure",
          items: ["Ehers (in Explorer)", "Web3"],
        },
      ],
    },
    mediaData: {
      items: [
        {
          title: "FairDex: Bidder UI for DutchX",
          link: "https://medium.com/protofire-blog/protofire-delivers-the-first-bidder-ui-for-the-dutchx-decentralized-exchange-protocol-dccd260a1436",
        },
        {
          title: "MakerDAO Keeper Pool",
          link: "https://makerdao.com/",
        },
      ],
    },
    bottomSlogan: "Need help with developing a decentralized exchange?",
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  //-----------------------------------------------------------------------------

  "the-graph-subgraphs": {
    metaTitle: "The Graph Subgraphs & Indexer Node Services | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Protofire builds subgraphs, validates smart contracts, and operates indexer nodes for The Graph. Streamline your dApp's data indexing and querying.",
      },
      {
        name: "keywords",
        content:
          "The Graph subgraph development, Blockchain data indexing, Smart contract validation, dApp onboarding services, Decentralized query processing",
      },
    ],
    title: "The Graph Subgraphs and Indexer Node",
    tagList: ["DevOps", "Node Management", "Subgraph"],
    description: [
      `Protofire provides ongoing assistance to onboard Dapps to The Graph. 
      For instance, our team helps to validate smart contracts and on-chain data, define 
      the roadmap for subgraph development and adoption, as well as deploy new projects 
      on multiple EVM networks. Protofire has already built over 50 subgraphs for 
      different prominent projects in the ecosystem.`,
    ],
    mainImage: graphSubgraphs0,
    images: [
      { alt: "graphSubgraphs0", src: graphSubgraphs0 },
      { alt: "graphSubgraphs1", src: graphSubgraphs1 },
      { alt: "graphSubgraphs2", src: graphSubgraphs2 },
    ],
    aboutItems: [
      `Definition of the roadmap for subgraph development and adoption`,
      `Ongoing implementation of subgraphs for different protocols`,
      `Validation of smart contracts and on-chain data`,
      `Deployment of new projects on the test and main Ethereum/IPFS network`,
      `Open-source subgraphs to be queried by any developer`,
      `Subgraph toolkits that facilitate bootstrapping of new subgraphs`,
    ],
    additionalContent: [
      {
        title: "The problem",
        description: [
          `Before The Graph, teams had to develop and operate proprietary 
    indexing servers. This required significant engineering and hardware resources 
    and broke the important security properties required for decentralization.`,
        ],
      },
      {
        title: "The solution",
        description: [
          "With the Graph protocol, end users can access historical and real-time data in the GraphQL explorer, explore the network status, and build new types of apps. All data is stored and processed on open networks with verifiable integrity. Open-source subgraphs can be queried by any developer fast, reliably, and securely. Subgraph toolkits facilitate bootstrapping of new subgraphs.",
          "Main Roles in the Ecosystem:",
        ],
        items: [
          "Indexer Node: Indexers are node operators in The Graph Network that stake Graph Tokens (GRT) in order to provide indexing and query processing services. Protofire has been a node operator for The Graph since its inception.",
          "Delegators: Delegators stake GRT with an Indexer(s) in order to earn a portion of Indexer rewards and fees, without having to personally run a Graph Node.",
          "Curators: Curators use GRT to signal to Indexers which subgraphs are valuable to index. Curators will typically be developers but could also be end users supporting a valuable service they rely upon.",
        ],
      },
      {
        title: "The client/partner",
        description: [
          `The Graph is a decentralized protocol to index and query 
    data on Ethereum and IPFS using the GraphQL language. Anyone can build and 
    publish open APIs, called subgraphs, making data easily accessible.`,
        ],
      },
      {
        title: "Deliveries",
        description: [
          "Aragon, Everest Ropsten, Maker DAO Governance, Uniswap, Bull Run Babes, Listing NFT Order Information, GRT Supply, Crypto Pepes, Instadapp DSA, Makersplace, Augur v2, Avastars, Compound Governance, EulerBeats, Set Protocol, AutoGlyphs, Bloom, Meme ltd, Kyber, Axie Inifinty, Multi Collateral Dai, Dai ERC20, Ray, Curve Gauges, dYdX, Curve, Nexus, Uma, LoopRing 3.6, Superrare, Eth Block Art, Audius",
        ],
      },
      {
        title: "Testimonial",
        description: [
          '"We have been working with Protofire for over a year, and they are a huge asset to the community. They understand DeFi and Web3 protocols in detail and continue to find creative ways to build new features and products to push the space forward. We are excited that Protofire has chosen to make The Graph a core part of their stack and recommend them highly to any teams looking to accelerate product development."',
          "Yaniv Tal, Co-Founder of The Graph and Edge & Node, CEO of Geo",
        ],
      },
    ],
    technologyStack: {
      description: [
        "We leverage the most suitable technologies for each solution to help our customers achieve their business goals more swiftly.",
      ],
      items: [
        {
          title: "Programming Languages",
          items: ["Solidity", "Assemblyscript", "Python", "GraphQL"],
        },
        {
          title: "Tools",
          items: ["Github", "GIT", "Terraform", "Kubernetes"],
        },
        {
          title: "Infrastructure",
          items: ["RAWS/GCP/Azure", "SQL", "Docker "],
        },
      ],
    },
    codeData: {
      items: [
        {
          title: "Subgraph Repositories",
          link: "https://github.com/orgs/protofire/repositories?q=subgraph&type=all",
        },
        {
          title: "Subgraph Toolkit",
          link: "https://github.com/protofire/subgraph-toolkit",
        },
        {
          title: "Subgraph Starter-kit",
          link: "https://github.com/protofire/react-subgraph-starter-kit",
        },
      ],
    },
    mediaData: {
      items: [
        {
          title:
            "Protofire Provides Implementation Services in The Graph Partnership",
          link: "https://medium.com/protofire-blog/protofire-provides-implementation-services-in-the-graph-partnership-7e07591031d7",
        },
        {
          title: "Introduction to The Graph Protocol",
          link: "https://thegraph.com/blog/the-graph-network-in-depth-part-1",
        },
        {
          title:
            "Subgraph Development, Part 1: Understanding and Aggregating Data",
          link: "https://medium.com/protofire-blog/subgraph-development-part-1-understanding-and-aggregating-data-ef0c9a61063d",
        },
        {
          title:
            "Subgraph Development, Part 2: Handling Arrays and Identifying Entities",
          link: "https://medium.com/protofire-blog/subgraph-development-part-2-handling-arrays-and-identifying-entities-30d63d4b1dc6",
        },
        {
          title:
            "Protofire Contributes to Mission Control — The Graph’s Incentivized Testnet",
          link: "https://medium.com/protofire-blog/protofire-contributes-to-mission-control-the-graphs-incentivized-testnet-4c2062f1d9dc",
        },
        {
          title: "The Graph: Building a subgraph for HumanityDAO (Part 1)",
          link: "https://medium.com/protofire-blog/the-graph-building-a-subgraph-for-humanitydao-part-1-9b3f1c3feb8a",
        },
        {
          title: "The Graph: Building a subgraph for HumanityDAO (Part 2)",
          link: "https://medium.com/protofire-blog/the-graph-building-a-subgraph-for-humanitydao-part-2-fc870c1a98fa",
        },
      ],
    },
    bottomSlogan: "Is your project in need of a subgraph? ",
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  //-----------------------------------------------------------------------------

  "chainlink-integration": {
    metaTitle:
      "Chainlink Oracle Integration & Custom Adapters | Protofire Solutions",
    meta: [
      {
        name: "description",
        content:
          "Protofire develops Chainlink integrations across networks, builds external adapters, and tests on EVM/non-EVM chains. Enhance your dApp with reliable oracles.",
      },
      {
        name: "keywords",
        content:
          "Chainlink oracle integration, External adapter development, Cross-chain oracle solutions, Blockchain data connectivity, Smart contract API integration",
      },
    ],
    title: "Chainlink Integration and External Adapters",
    tagList: ["API", "DevOps", "Integration", "Oracle"],
    description: [
      `Supported by Chainlink Community Grants, we have built a team 
      that works with Chainlink infrastructure in order to bring oracle infrastructure
      to wherever it may be needed in the Web3 ecosystem. We have promoted Chainlink 
      node deployment, as well as tested integrations on EVM and non-EVM blockchains, 
      and we have developed Chainlink External Adapters that connect smart contracts 
      to premium web APIs. We have established different mechanisms and workflows to 
      validate the new chains, identified the right approaches to achieve the 
      integrations, and produced accompanying documentation for the ecosystem community.`,
    ],
    mainImage: chainlinkIntegration0,
    images: [
      { alt: "chainlinkIntegration0", src: chainlinkIntegration0 },
      { alt: "chainlinkIntegration1", src: chainlinkIntegration1 },
      { alt: "chainlinkIntegration2", src: chainlinkIntegration2 },
      { alt: "chainlinkIntegration3", src: chainlinkIntegration3 },
    ],
    about: ["Chainlink promotes:"],
    aboutItems: [
      `The industry standard oracle network`,
      `A reliable, sybil-resistant, and tamper-proof network`,
      `Seamless connection to APIs`,
      `Proven, ready-made services`,
      `Secure off-chain computation`,
    ],
    aboutExtra: {
      about: [
        "What we have worked on thanks to support from Chainlink Community Grants:",
      ],
      aboutItems: [
        `Integrating Chainlink across several networks.`,
        `Developing repositories with files required to run a Chainlink node in a network.`,
        `Testing integrations on EVM-compatible blockchains.`,
        `Testing integrations on non-EVM-compatible blockchains.`,
        `Building a cross-testnet LINK faucet.`,
        `Developing Chainlink External Adapters to enable access to high-quality data and enable extreme flexibility to connect smart contracts to premium web APIs.`,
      ],
    },
    metrics: [
      { value: "$56B+", text: "of Total Value Secured in Q1/2022" },
      { value: "910+", text: "of total oracle networks in Q1/2022" },
      { value: "2B+", text: "of on-chain data points in Q1/2022" },
      { value: "1230+", text: "of total integrations in Q1/2022" },
    ],
    additionalContent: [
      {
        title: "The problem",
        description: [
          `Chainlink solves “the oracle problem,” which revolves around 
    an innate limitation of blockchains: they cannot pull in data from or push 
    data out to any external system as built-in functionality. As such, blockchains
    are isolated networks, akin to a computer with no Internet connection. However,
    for smart contracts to realize upwards of 90% of their potential use cases, 
    they must connect to the outside world.`,
          `The solution to the blockchain oracle problem is the Chainlink Decentralized 
    Oracle Network. It’s a secure piece of middleware that facilitates communication
    between blockchains and any off-chain system, including data providers, web APIs,
    enterprise backends, cloud providers, IoT devices, e-signatures, payment systems,
    other blockchains, and more.`,
        ],
      },
      {
        title: "The solution",
        items: [
          {
            title: "Chainlink Nodes",
            items: [
              "We worked with the bridge teams to ensure LINK is correctly bridged (respecting ERC677). As part of the collaboration, we tested integrations on EVM-compatible blockchains. The Protofire team has worked on Harmony, Celo, Plasm, Avalanche, xDAI.",
            ],
          },
          {
            title: "Chainlink Blockchain Integration Acceptance Testing",
            items: [
              "We developed repositories with all the files required to run a Chainlink Node in a given network. Protofire team has worked on Klaytn, Celo, Plasm,  Avalanche, xDAI.",
            ],
          },
          {
            title: "Chainlink Scalable EVM Integration Acceptance Testing",
            items: [
              "We tested integrations on EVM-compatible blockchains. Protofire team has worked on Klaytn, Celo, Moonbeam,  Avalanche, xDAI, Harmony, IoTex, OKC (OKX), Kovan Optimism.",
            ],
          },
          {
            title: "Chainlink Scalable Non-EVM Integration Analysis",
            items: [
              "As the crypto ecosystem continues to grow, Chainlink Price Feeds are needed in more diverse environments. This necessitates that work is conducted to integrate Chainlink trust-minimized services across different networks such as Polkadot and Solana.",
            ],
          },
          {
            title: "LINK Faucet for Testnets",
            items: [
              "We built a cross-testnet LINK faucet, enabling developers to test their Chainlink-ed contracts in different environments. We currently support Avalanche Fuji, BSC Testnet, POA Sokol, Fantom Testnet, ETH Kovan, Rinkeby Arbitrum.",
            ],
          },
          {
            title: "Chainlink External Adapters (EA)",
            items: [
              {
                title:
                  "They allow access to high-quality data and enable extreme flexibility to connect smart contracts to premium web APIs. The Protofire team has worked on Modified EA input parameters and EA Response types for dozens of external adapters. We added http and websocket integration tests for dozens of external adapters. Defidozen was implemented for new EA to get an index value that is calculated with the value of different tokens. Proof of Reserves address-set and indexer EAs updated input parameters and response to have unified shape. Further integrations are listed below:",
                items: [
                  "BEA: Consumer price index feed EA. Ported from golang",
                  "ETH Balance: EA that returns ethereum or erc20 balances from addresses",
                  "Set-token Index: Defi-pulse EA",
                  "Wrapped: EA to get a list of blockchain addresses from wrapped.com",
                  "Coinmarketcap EA: New historical api",
                  "View-function: EA that takes ABI and returns the function result of contract",
                  "Improved error logging and error response for EAs that use external API",
                  "Updated Linear Finance index values",
                  "Upvest: EA enabled for gasprice",
                  "MyCryptoAPI: EA enabled for gasprice",
                  "Batching discovery & upgrade: cryptocompare, cmc, tradermade",
                  "Wootrade: feeds with their data only to be used for their private pools on polygon",
                  "Cache.gold: Proof of Reserves EA",
                  "Etherscan: EA enabled for gasprice",
                  "EthGasWatch: EA enabled for gasprice",
                  "US CPI Data for FRAX",
                  "Feeds for BTC and ETH, adding a circuit breaker",
                  "Index Coop Data Economy Index (DATA)",
                  "Finage Batch Discovery, Support batch request in several adapters",
                  "MyCryptoapi.gasprice: EA enabled for gasprice",
                  "EA Masterlist Airtable integration",
                  "Changed server dependency from express to fastify",
                  "Readme generator enhancement",
                ],
              },
            ],
          },
        ],
      },
      {
        title: "The client/partner",
        description: [
          `Chainlink decentralized oracle networks provide tamper-proof 
    inputs, outputs, and computations to support advanced smart contracts on any 
    blockchain. Chainlink is a technology platform that enables both Web3 and 
    non-blockchain enterprises to securely connect with blockchain platforms. 
    Chainlink middleware connects blockchain-based smart contracts with external 
    data, such as fiat currency prices.`,
        ],
      },
    ],
    technologyStack: {
      description: [
        "We leverage the most suitable technologies for each solution to help our customers achieve their business goals more swiftly.",
      ],
      items: [
        {
          title: "Programming Languages",
          items: ["Go (golang)", "Typescript", "Node.js"],
        },
        {
          title: "Tools",
          items: ["Kubernetes", "Geth", "Ginkgo", "Docker"],
        },
        {
          title: "Environment",
          items: ["GCP", "GKE", "Linux", "Kubernetes"],
        },
        {
          title: "Infrastructure",
          items: [
            "Bitcoin node",
            "Eth 1.0",
            "Eth 2.0 Node",
            "Oracles, Kubernetes",
          ],
        },
      ],
    },
    codeData: {
      items: [
        {
          title: "External Adapters Example",
          link: "https://github.com/protofire/chainlink-external-adapter-example",
        },
        {
          title: "External Adapters",
          link: "https://github.com/smartcontractkit/external-adapters-js",
        },
        {
          title: "Integrations",
          link: "https://github.com/smartcontractkit",
        },
      ],
    },
    bottomSlogan: "Are you in need of an oracle integration for your project? ",
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  //-----------------------------------------------------------------------------

  "filecoin-node-infrastructure": {
    metaTitle: "Filecoin Node Infrastructure & CID Checker | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Explore Protofire's Filecoin node management, public API, and CID Checker. Enhance your Filecoin network experience with our open-source solutions.",
      },
      {
        name: "keywords",
        content:
          "Filecoin node infrastructure, CID Checker tool, Blockchain storage solutions, Decentralized cloud storage, Filecoin network management",
      },
    ],
    title: "Filecoin Node Infrastructure",
    tagList: ["API", "DevOps", "Explorer", "Node Management"],
    description: [
      `Protofire has received several grants from Protocol Labs and has been 
  working together with the Protocol Labs team (as contributors to Glif tools) 
  since before the launch of the Filecoin Mainnet. As part of the Glif team we 
  are continuously developing open-source solutions with the intention of improving
   the Filecoin community and promoting network growth. As such, we have helped to 
   provide node infrastructure for Filecoin solutions. We have also developed the 
   CID Checker, which provides a list of all CIDs along with their status.`,
    ],
    mainImage: filecoin0,
    images: [
      { alt: "filecoin0", src: filecoin0 },
      { alt: "filecoin1", src: filecoin1 },
    ],
    about: [
      "Using our DevOps expertise, we have performed node infrastructure management for Filecoin to deliver the following result:",
    ],
    aboutItems: [
      "<a target='_blank' href='https://api.node.glif.io/' class='underline underline-offset-1 text-white100'>Public and dedicated nodes</a>",
      "<a target='_blank' href='https://lotus.filecoin.io/developers/glif-nodes/' class='underline underline-offset-1 text-white100'>A public API</a> for the most popular client for the Filecoin network",
      "<a target='_blank' href='https://filecoin.tools/' class='underline underline-offset-1 text-white100'>A website and API service</a> that can list all CIDs along with their status",
    ],
    metrics: [
      { value: "3.9K", text: "active storage providers in Q1/2022" },
      {
        value: "304K FIL",
        text: "24h FIL Production in block rewards (~U$7 million dollars per day) in Q1/2022",
      },
    ],
    additionalContent: [
      {
        title: "The problem",
        description: [
          "Growing a blockchain network effectively implies several difficulties:",
        ],
        items: [
          "Attracting users.",
          "Allowing users to connect to the network",
          "Letting users utilize the application easily.",
        ],
        descriptionExtra: {
          text: [
            `A way of dealing with that is by providing the required 
          infrastructure for that to happen. We have provided that by building a public 
          and dedicated node infrastructure and management of it, as well as a CID 
          (Content Identifier of Data) explorer of the data that a user wants to store on 
          the Filecoin network.`,
          ],
        },
      },
      {
        title: "The solution",
        description: [
          "We have established Filecoin Glifs, the interoperable tools for the Filecoin network and launched Glif Nodes for public and dedicated Filecoin <a target='_blank' href='https://api.node.glif.io/' class='underline underline-offset-1 text-white100'>node infrastructure</a>. Our team has developed <a target='_blank' href='https://filecoin.tools/' class='underline underline-offset-1 text-white100'>Filecoin CID Checker</a>, a website and API service that can list all CIDs along with their current status in the latest state tree.",
        ],
      },
      {
        title: "The client/partner",
        description: [
          `Filecoin is an open-source, public cryptocurrency and digital 
    payment system intended to be a blockchain-based cooperative digital storage 
    and data retrieval method. Filecoin builds on top of InterPlanetary File System 
    (IPFS), an open-source protocol allowing users to rent unused hard drive space. 
    Users pay hyper-competitive prices to storage providers for file storage and 
    retrieval. The Filecoin network works as a dynamic distributed cloud storage 
    network for data.`,
        ],
      },
    ],
    technologyStack: {
      description: [
        "We leverage the most suitable technologies for each solution to help our customers achieve their business goals more swiftly.",
      ],
      items: [
        {
          title: "Programming Languages",
          items: ["Typescript", "Node.JS", "React"],
        },
        {
          title: "Infrastructure",
          items: ["MongoDB"],
        },
      ],
    },
    codeData: {
      items: [
        {
          title: "Chart for hosting Lotus Node clients",
          link: "https://github.com/glifio/filecoin-chart",
        },
        {
          title: "Filecoin CID Checker",
          link: "https://github.com/protofire/filecoin-CID-checker",
        },
      ],
    },
    bottomSlogan: "Do you need infrastructure management for your project?",
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  //-----------------------------------------------------------------------------

  "swarm-dex": {
    metaTitle:
      "Swarm Markets: Compliant Multi-Asset DeFi Infrastructure | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Explore Swarm's regulated DEX with KYC, multi-tier permissioning, and OTC trading. Access crypto and tokenized securities in one DeFi platform.",
      },
      {
        name: "keywords",
        content:
          "Regulated DeFi exchange, Compliant crypto trading, Tokenized securities platform, Multi-asset DEX infrastructure, KYC-integrated blockchain trading",
      },
    ],
    title: "Swarm Markets DEX",
    tagList: ["dApp", "Dashboard", "DeFi", "DEX", "KYO", "OTC"],
    description: [
      `Protofire team has worked on the architecture, smart contracts, 
  and front end for Swarm, which provides compliant multi-asset DeFi infrastructure. 
  KYC services are inbuilt within the platform. The team has implemented several 
  DEX functionalities - such as swapping and pooling, as well as a Multi-Tier 
  permissioning system and an integrated distributed OTC trading module`,
    ],
    mainImage: swarmDex0,
    images: [
      { alt: "swarmDex0", src: swarmDex0 },
      { alt: "swarmDex1", src: swarmDex1 },
    ],
    aboutItems: [
      `Compliant multi-asset infrastructure`,
      `Operates in a regulated environment in Germany`,
      `Provides KYC services`,
      `Multi-Tier permissioning system`,
      `Automated market-making (AMM)`,
      `Decentralized exchange with Swap, Pool and decentralized over-the-counter 
      block trading functionalities`,
      `Development of: Architecture, Smart contracts, Front end`,
      `A compliant environment with the core DeFi benefits: self-custody, on-chain 
      transparency, and networked liquidity`,
    ],
    additionalContent: [
      {
        title: "The problem",
        description: [
          `Lack of regulation has meant participants and capital remain 
    sidelined from digital markets. The industry is bifurcating into regulated and 
    non-regulated entities, as more members of the crypto industry believe its 
    long-term success lies in regulation.`,
          `Swarm aims to be the nucleus of a transparent financial culture 
    that creates value for many. It steps up to build a world where everyone can 
    benefit from financial opportunities. This aspiration is reflected across its 
    ever growing portfolio of unique investment products, which empower people to 
    take control of their personal wealth in a secure environment and overcome 
    compliance barriers for institutional investors.`,
        ],
      },
      {
        title: "The solution",
        description: [
          "We have customized AMM to enable the creation of pools with a customizable number of assets and weights. Swap functionality has been implemented for swapping assets in real-time. <a target='_blank' href='https://app.swarm.com/pools' class='underline underline-offset-1 text-white100'>Pool functionality</a> was developed for adding/removing liquidity from pools. Using the platform-native token enabled the users to get discounts on the protocol fees.",
        ],
      },
      {
        title: "The client/partner",
        description: [
          `Swarm provides compliant multi-asset DeFi infrastructure  for 
    both institutions and retail investors. For the first time ever, people will 
    be able to invest in crypto assets and tokenized securities in a single DeFi
     venue. Swarm combines the innovation of DeFi with investor protection. Its 
     trading and investing tools enable investors to retain full control of assets 
     in their wallet, giving them direct access to capital and crypto markets 24/7, 
     without paying for costly intermediaries`,
        ],
      },
      {
        title: "Testimonial",
        items: [
          "\"Access to DeFi needs to be at a price point that isn't cost-prohibitive to Main Street investors, which is why expanding our licensed AMM to layer 2 solutions is crucial. Web3 is coming for financial markets and we're building trustless infrastructure that gives people autonomy to build wealth while retaining control of their assets at all times.\"",
          "Philipp Pieper, Co-Founder of Swarm Markets",
        ],
      },
    ],
    technologyStack: {
      description: [
        "We leverage the most suitable technologies for each solution to help our customers achieve their business goals more swiftly.",
      ],
      items: [
        {
          title: "Programming Languages",
          items: ["Typescript", "Node.js", "React"],
        },
        {
          title: "Infrastructure",
          items: ["MongoDB"],
        },
      ],
    },
    codeData: {
      items: [
        {
          title: "Chart for hosting Lotus Node clients",
          link: "https://github.com/glifio/filecoin-chart",
        },
        {
          title: "Filecoin CID Checker",
          link: "https://github.com/protofire/filecoin-CID-checker",
        },
      ],
    },
    mediaData: {
      items: [
        {
          title: "MakerDAO Keeper Pool",
          link: "https://makerdao.com/",
        },
        {
          title: "FairDex: Bidder UI for DutchX",
          link: "https://medium.com/protofire-blog/protofire-delivers-the-first-bidder-ui-for-the-dutchx-decentralized-exchange-protocol-dccd260a1436",
        },
      ],
    },
    bottomSlogan: "Do you need DevOps services for your project? ",
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  //-----------------------------------------------------------------------------

  "makerdao-dashboard": {
    metaTitle: "MakerDAO Governance Analytics Dashboard | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Track MakerDAO governance metrics, voting data, and historical trends. Make informed decisions with our comprehensive analytics dashboard.",
      },
      {
        name: "keywords",
        content:
          "MakerDAO governance analytics, Blockchain voting dashboard, DeFi governance metrics, Cryptocurrency decision tools, On-chain governance tracking",
      },
    ],
    title: "MakerDAO Governance Analytics Dashboard",
    tagList: ["Dashboard", "DeFi", "Governance", "Interface", "Monitor"],
    description: [
      `Protofire built Maker Governance Analytics Dashboard. 
      It provides users with a single location to track the latest updates and view 
      historical data. Through the dashboard, it is possible to access information 
      crucial for voting and polling, such as a stability fee, debt ceiling, 
      multi-collateral DAI assets, etc. In addition, the dashboard allows for 
      monitoring overall engagement metrics in the MakerDAO system`,
    ],
    mainImage: makerdaoDashboard0,
    images: [
      { alt: "makerdaoDashboard0", src: makerdaoDashboard0 },
      { alt: "makerdaoDashboard1", src: makerdaoDashboard1 },
    ],
    about: [
      "We have built an analytics dashboard for the <a target='_blank' href='https://mkrgov.science/' class='underline underline-offset-1 text-white100'>MakerDAO Governance</a> enabling users to track the latest updates and view historical data in a single place. Through the intuitive and easy-to-navigate dashboard, it is possible to access the information that is crucial for voting and polling, such as a stability fee, debt ceiling, multi-collateral DAI assets, and more. The solution allows to monitor the overall engagement metrics in the MakerDAO system.",
    ],
    metrics: [
      { value: "$16B TVL", text: "at MakerDAO in Q1/2022" },
      {
        value: "U$9.5B DAI",
        text: "outstanding in Q1/2022",
      },
    ],
    additionalContent: [
      {
        title: "The problem",
        description: [
          "MakerDAO team proposed that someone build their <a target='_blank' href='https://forum.makerdao.com/t/taken-a-governance-dashboard/87' class='underline underline-offset-1 text-white100'>governance dashboard</a> through a grant.",
          "“We’re looking for someone to build out a governance dashboard that will provide the community a single location to view historical data related to voting and polling and to track overall engagement metrics in the system”. The dashboard should <a target='_blank' href='https://github.com/protofire/maker-governance-dashboard/' class='underline underline-offset-1 text-white100'>contain</a> several specific metrics, as well as the code, must be open-sourced and permissively licensed.",
          `On-chain governance is a system for managing and implementing changes 
      to cryptocurrency blockchains. It includes rules for instituting changes.
       Developers propose changes through code updates and each node or 
       participant votes.`,
        ],
      },
      {
        title: "The solution",
        description: ["The MakerDAO dashboard was delivered as requested:"],
        items: [
          `Users can have a clear and precise view of the MakerDAO governance`,
          `Users can make well-informed decisions on their investments`,
          `Well-documented open-source codebase enables new developers to interact with the protocol and process it quickly`,
          `The data that is exhibited is related to voting and polling for system 
            parameters, such as Stability Fee, debt ceiling, Multi-Collateral
            DAI assets, etc`,
        ],
        descriptionExtra: {
          text: ["The solution’s functionality allows users to track:"],
          items: [
            `Polls and votes`,
            `The total stake weight per option/per vote`,
            `Changes in the total stake over time for active polls and votes`,
            `The number of voters per poll/vote`,
            `Changes in the voters over time`,
            `The Gini coefficient of Maker over time`,
            `The time spent on an Executive Vote to pass`,
            `The number of votes not being ratified`,
          ],
        },
      },
      {
        title: "The client/partner",
        description: [
          `Launched in 2014, Maker is a decentralized autonomous 
    organization (DAO) building a protocol on top of the Ethereum blockchain. 
    The protocol allows for lending and borrowing digital assets with no middlemen 
    involved. In MakerDAO, token holders perform governance through polling and
     voting for protocol changes, while managing DeFi risks. One of the unique 
     goals behind MakerDAO was to create its own stablecoin - DAI - that is backed 
     by crypto collaterals and stored publicly on the Ethereum blockchain.`,
        ],
      },
    ],
    technologyStack: {
      description: [
        "We leverage the most suitable technologies for each solution to help our customers achieve their business goals more swiftly.",
      ],
      items: [
        {
          title: "Programming Languages",
          items: ["React", "Node.js", "Python", "AWS", "CloudFormation"],
        },
        {
          title: "Infrastructure",
          items: [
            "Redis / ElastiCache",
            "RDS / PostgreSQL",
            "L1 blockchains (full nodes)",
          ],
        },
        {
          title: "Tools",
          items: ["Storybook", "Styled Components", "Jest Rust"],
        },
      ],
    },
    codeData: {
      items: [
        {
          title: "MakerDAO Governance Dashboard repository",
          link: "https://github.com/protofire/maker-governance-dashboard/",
        },
      ],
    },
    mediaData: {
      items: [
        {
          title:
            "Protofire Introduces Maker Governance Analytics Dashboard v.1",
          link: "https://medium.com/protofire-blog/protofire-introduces-maker-governance-analytics-dashboard-v-1-d96c19a1effc",
        },
        {
          title: "Livepeer Tools Dashboard",
          link: "https://medium.com/protofire-blog/protofire-introduces-livepeer-tools-2-0-f5b748a56eb2",
        },
        {
          title: "Polkadot Mempool Explorer",
          link: "https://medium.com/protofire-blog/monitoring-pending-transactions-in-polkadot-with-mempool-explorer-36e88e8a41ed",
        },
      ],
    },
    bottomSlogan: "Do you need a convenient dashboard for your application?",
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  //-----------------------------------------------------------------------------

  "armanino-platform": {
    metaTitle:
      "Armanino TrustExplorer: Blockchain-Based Auditing Solution | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Explore Armanino's TrustExplorer suite: Real-Time Audit, Proof of Reserves, Trusted Node, and Treasury. Blockchain-powered transparency for enterprises.",
      },
      {
        name: "keywords",
        content:
          "Blockchain auditing solutions, Real-time financial reporting, Digital asset transparency, Enterprise blockchain tools, Crypto treasury management",
      },
    ],
    title: "Armanino’s TrustExplorer",
    tagList: ["Platform"],
    description: [
      `Protofire has been working with Armanino for more than three years 
      and has been assisting in the development of four solutions under the TrustExplorer 
      project: a blockchain-based assurance platform (Real-Time Audit), a tool to manage
      digital asset reserves (Proof of Reserves), a data query service (Trusted Node), 
      and a dashboard to show token ownership and distribution (Treasury). Those are 
      accounting, auditing, and reporting solutions that are tailored for the enterprise 
      ecosystem and auditing mechanisms.`,
    ],
    mainImage: armanino0,
    images: [
      { alt: "armanino0", src: armanino0 },
      { alt: "armanino1", src: armanino1 },
      { alt: "armanino2", src: armanino2 },
    ],
    about: [
      "Armanino is among Top 20 providers of audit, tax, business management, and consulting services in the USA. Operating in 100+ countries globally, the company serves such industries as information technology, manufacturing, logistics, education, real estate, healthcare, etc.",
      // "Armanino is among Top 20 providers of audit, tax, business management, and consulting services in the USA. Operating in 100+ countries globally, the company serves such industries as information technology, manufacturing, logistics, education, real estate, insurance, healthcare, etc.",
    ],
    extraProjects: [
      {
        title: "TrustExplorer Real-Time Audit",
        items: [
          {
            title: "About",
            description: [
              "A blockchain-based platform for financial accounting, auditing, and reporting in real-time. The <a target='_blank' href='https://www.armaninollp.com/software/trustexplorer/real-time-attest/' class='underline underline-offset-1 text-white100'>solution</a> allows users to dynamically pull data on a circulating supply of tokens, as well as aggregate a total balance of assets supporting the tokens.",
            ],
          },
          {
            title: "Problem",
            items: [
              "Lack of trust and transparency in the enterprise ecosystem and auditing mechanisms",
              "Slow auditing process",
            ],
          },
          {
            title: "Solution",
            items: [
              "A blockchain-based platform",
              "Independent accountant reports in real-time",
              "Users: Increased transparency",
              "Regulators: Fulfill international and domestic regulatory requirements",
              "Management: Enable real-time visibility of assets and liabilities, including historical positions",
            ],
          },
        ],
      },
      {
        title: "TrustExplorer Proof of Reserves",
        items: [
          {
            title: "About",
            description: [
              "A tool (platform) that <a target='_blank' href='https://www.armaninollp.com/software/trustexplorer/proof-of-reserves/' class='underline underline-offset-1 text-white100'>provides</a> users of centralized exchanges with transparency and assurance over digital asset reserves held custodial on their behalf.",
            ],
          },
          {
            title: "Problem",
            items: [
              "Lack of standards to ensure digital asset service providers hold enough assets to cover customer deposits",
              "Gaps in trust result in lower rates of adoption and higher counterparty risks",
            ],
          },
          {
            title: "Solution",
            items: [
              "Users can verify that their digital assets are fully reserved on their exchanges",
              "Platform users: Enhance their reputation and confidence with their user base",
              "Regulators: Fulfill international and domestic regulatory requirements",
              "Management: Increase confidence in their own internal systems by having a team of industry CPA’s inspect their processes",
            ],
          },
        ],
      },
      {
        title: "TrustExplorer Trusted Node",
        items: [
          {
            title: "About",
            description: [
              "A service to query data. Using this solution, the participants of the Hedera Hashgraph network can <a target='_blank' href='https://www.armaninollp.com/software/trustexplorer/trusted-node/' class='underline underline-offset-1 text-white100'>access</a> transactional data. Meanwhile, financial statement auditors can acquire information related to balance sheet accounting of material assets stored in a blockchain.",
            ],
          },
          {
            title: "Problem",
            items: [
              "Burden on ecosystem participants of hosting their own full, archival or mirror nodes",
              "Lack of access to transactional and wallet balance data",
              "Lack of blockchain solutions for the enterprise ecosystem participants and auditors",
            ],
          },
          {
            title: "Solution",
            items: [
              "Solution for the enterprise ecosystem participants and auditors",
              "Built and operated under a robust compliance program",
              "The API allows an auditor to query data from a node maintained by a reputable party and the ability to obtain industry-standard assurance reporting",
            ],
          },
        ],
      },
      {
        title: "TrustExplorer Treasury",
        items: [
          {
            title: "About",
            description: [
              "Treasury is a <a target='_blank' href='https://www.armaninollp.com/software/trustexplorer/treasury/' class='underline underline-offset-1 text-white100'>token management solution</a> (dashboard) that provides all participants with accurate information on token ownership and distribution in an easily digestible format. That promotes growth of token usage, expands the user base and builds trust in the marketplace.",
            ],
          },
          {
            title: "Problem",
            items: [
              "Lack of trust in governance platforms due to a lack of transparency regarding token ownership and distribution",
              "Information may sometimes be inaccurate",
              "Information may sometimes be hard to understand",
            ],
          },
          {
            title: "Solution",
            items: [
              "Ecosystem Development: Increased trust in treasury management through real-time, accurate data",
              "Token Issuers: Enhance their reputation with current and prospective holders",
              "Token Holders: Verify token disbursement aligns with established agreements",
              "Foundations & DAOs: Utilize trusted third-party verification and attest reporting of their treasury",
            ],
          },
        ],
      },
      {
        title: "",
        items: [
          {
            title: "Testimonial",
            description: [
              '"The applications we design and deliver to clients provide assurance over 10’s of billions of dollars to marketplaces, retail and institutional customers, as well as regulators across the globe. The stakes are high and the information produced from our applications must also meet multiple strict professional CPA standards. Protofire has been a long-term partner that has risen to this challenge; their team have provided high continuity, collaborative spirit, excellent communication and outstanding customer service."',
              "Noah Buxton, Partner, Practice Leader at Armanino",
            ],
          },
        ],
      },
    ],
    bottomSlogan: "Looking for a blockchain solution for your DeFi ecosystem?",
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  //-----------------------------------------------------------------------------

  "aragon-dapp": {
    metaTitle: "AragonDAO Payroll dApp for Automated DAO Payments | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Streamline DAO payroll with Aragon's dApp. Manage employees, automate payments, and track statistics. Built for decentralized organizations.",
      },
      {
        name: "keywords",
        content:
          "DAO payroll management, Decentralized employee payments, Blockchain payroll automation, Aragon DAO tools, Crypto salary management",
      },
    ],
    title: "AragonDAO Payroll dApp",
    tagList: ["dApp", "Dashboard", "Payments"],
    description: [
      `The Aragon application allows users to incorporate new employees 
  (integrated with the identity and finance apps), request and pre-authorize payments 
  in different tokens, as well as view all the payroll information, including 
  historic payments, balance, and statistics. The DAO members/employees can manage 
  and expect their payments to arrive on time, error-free, containing the expected 
  tokens`,
    ],
    mainImage: aragon0,
    images: [{ alt: "aragon0", src: aragon0 }],
    about: [
      "The official Aragon DAO Payroll decentralized app promotes automated management of payments. Through the dapp, DAO members can manage and expect payments to arrive at an agreed time. User feedback was integrated to create and improve tools for building new types of organizations with the Aragon stack, as well as to improve user and developer experience.",
    ],
    metrics: [
      {
        value: "1.7K",
        text: "DAOs using Aragon in Q1/2022",
      },
      {
        value: "41K",
        text: "users (Q1/2022)",
      },
      {
        value: "$900M",
        text: "managed by such DAOs in Q1/2022",
      },
    ],
    additionalContent: [
      {
        title: "The problem",
        description: [
          `The hierarchical power structure and the democratic 
    decision-making capabilities in both governments and corporations have been 
    thoroughly questioned these days. In order to promote a different way of being,
     a decentralized autonomous organization (DAO) is an organization that runs on 
     a blockchain protocol fully and autonomously in accordance with rules encoded 
     via smart contracts and its underlying consensus mechanism. DAOs’ main purpose 
     is to automate and decentralize the governance of organizations, such as 
     corporations. Some tenets of DAOs are decentralization, transparency, 
     automation, and being community-based.`,
        ],
      },
      {
        title: "The solution",
        description: ["It allows users to:"],
        items: [
          "Manage payments",
          "Expect payments to arrive on time",
          "Expect payments to be error-free",
          "Tokens to be received at expected currency",
          "Incorporate new employees (integrated with the identity and finance apps)",
          "Filter and sort employees",
          "Request and pre-authorize payments in different tokens",
          "View all the appraisal information, including: Historic payments, Balance, Statistics",
        ],
      },
      {
        title: "The client/partner",
        description: [
          `Aragon DAO is an administrative platform for decentralized 
    organizations built on Ethereum. The Aragon project was born to disintermediate 
    the creation and maintenance of decentralized organizations. Aragon helps to 
    create global, bureaucracy-free organizations, companies, and communities, as 
    well as collaborate without borders or intermediaries. Aragon is creating a 
    whole stack of decentralized technologies that power governance mechanisms 
    underlying decentralized autonomous corporations or (DACs).`,
        ],
      },
    ],
    technologyStack: {
      description: [
        "We leverage the most suitable technologies for each solution to help our customers achieve their business goals more swiftly.",
      ],
      items: [
        {
          title: "Programming Languages",
          items: [
            "React",
            "Javascript",
            "Web3",
            "GraphQL",
            "Assembly Script for The Graph",
            "Solidity",
          ],
        },
        {
          title: "Tools",
          items: ["GitHub", "ZenHub", "Netlify"],
        },
      ],
    },
    codeData: {
      items: [
        {
          title: "Payroll",
          link: "https://github.com/protofire/aragon-apps/tree/master/future-apps/payroll",
        },
      ],
    },
    bottomSlogan: "Interested in dapp development?",
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  //-----------------------------------------------------------------------------

  // "opyn-platform": {
  //   metaTitle:
  //     "Opyn: Decentralized Options Trading & Risk Management | Protofire",
  //   meta: [
  //     {
  //       name: "description",
  //       content:
  //         "Explore Opyn's DeFi options platform. Hedge risks, leverage positions, and trade derivatives securely. Audited technology with user-friendly interface.",
  //     },
  //     {
  //       name: "keywords",
  //       content:
  //         "Decentralized options trading, DeFi risk management, Blockchain derivatives platform, Crypto hedging solutions, Decentralized insurance",
  //     },
  //   ],
  //   title: "Opyn Decentralized Insurance Platform",
  //   // tagList: ["Audit", "Insurance", "Integration", "Interface", "Platform"],
  //   tagList: ["Audit", "Integration", "Interface", "Platform"],
  //   description: [
  //     "A finance management tool for derivatives trading enabling investors to manage their risks better. Users can either hedge or leverage their positions. The solution leverages a thoroughly audited technology and can be accessed through a user interface.",
  //   ],
  //   mainImage: opyn0,
  //   images: [
  //     { alt: "opyn0", src: opyn0 },
  //     { alt: "opyn1", src: opyn1 },
  //   ],
  //   aboutItems: [
  //     `Financial management tool for derivatives trading`,
  //     `It is a tool for investors to manage their risks better`,
  //     `Users can either hedge or leverage their positions`,
  //     `The solution can be accessed through a user interface`,
  //     `A thoroughly audited technology`,
  //   ],
  //   // aboutExtra: { about: [] },
  //   metrics: [
  //     {
  //       value: "$98M+",
  //       text: "of Total Value Locked in Q3/2022",
  //     },
  //   ],
  //   additionalContent: [
  //     {
  //       title: "The problem",
  //       description: [
  //         `The nascent blockchain ecosystem leverages the risk of
  //         investors, given the great uncertainties still faced by it, such as government
  //         regulations and adoption concerns. In order to limit such hurdles, financial
  //         tools such as derivatives promote a way for investors to protect their invested
  //         capital against long-term uncertainty. Options provide a way for investors
  //         to either hedge or leverage their positions against the market.`,
  //       ],
  //     },
  //     {
  //       title: "The solution",
  //       description: ["The deliveries have been:"],
  //       items: [
  //         "Frontend Insurance: We have built the user interface and integrated the UI with a backend and Web3 infrastructure.",
  //         "Landing Page: We have built a landing page and integrated it with the Frontend Insurance.",
  //         "Smart Contracts QA Assessment: We have developed integration tests for Opyn Smart Contracts before audits by Open Zeppelin.",
  //         "OpenZeppelin Audit and Fixations: We have reviewed the OpenZeppelin Audit and applied fixes and improvements to the platform.",
  //       ],
  //     },
  //     {
  //       title: "The client/partner",
  //       description: [
  //         `Opyn is a permissionless, decentralized insurance platform
  //   that enables users to protect their DeFi deposits from financial and technical
  //    risks and hedge against Ether downside, volatility, and flash crashes.`,
  //       ],
  //     },
  //     {
  //       title: "Testimonial",
  //       description: [
  //         '"We enjoyed working with Protofire a lot! DeFi thrives because of this team! They have some of the best engineers, really efficient, professional, and, more than all, fun to work with! They deliver high-quality work really quickly!"',
  //         "Aparna Krishnan, Co-Founder at Opyn",
  //       ],
  //     },
  //   ],
  //   technologyStack: {
  //     description: [
  //       "We leverage the most suitable technologies for each solution to help our customers achieve their business goals more swiftly.",
  //     ],
  //     items: [
  //       {
  //         title: "Programming Languages",
  //         items: [
  //           "React",
  //           "Javascript",
  //           "Web3",
  //           "GraphQL",
  //           "Assembly Script for The Graph",
  //           "Solidity",
  //         ],
  //       },
  //       {
  //         title: "Tools",
  //         items: ["GitHub", "ZenHub", "Netlify"],
  //       },
  //     ],
  //   },
  //   mediaData: {
  //     items: [
  //       {
  //         title: "Synthetix GrantsDAO Interface",
  //         link: "https://medium.com/protofire-blog/synthetix-launched-a-brand-new-grantsdao-interface-72d9831ad0ae",
  //       },
  //     ],
  //   },
  //   bottomSlogan: "Looking for insurance solutions for your ecosystem?",
  //   contact: {
  //     image: alejandro,
  //     name: "Alejandro Losa",
  //     lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
  //     calendlyCallPostfix: "alejandro-protofire",
  //     text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
  //   },
  // },

  //-----------------------------------------------------------------------------

  "gnosis-explorer": {
    metaTitle:
      "Gnosis Conditional Tokens Explorer: Interact with CTF | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Explore and interact with Gnosis Conditional Tokens Framework. Split, merge, and redeem positions. User-friendly interface for blockchain developers.",
      },
      {
        name: "keywords",
        content:
          "Conditional Tokens Explorer, Gnosis CTF interface, Blockchain prediction markets, Tokenized logic platform, DeFi forecasting tools",
      },
    ],
    title: "Gnosis Conditional Tokens Explorer",
    tagList: ["dApp", "Explorer", "Interface"],
    description: [
      `Protofire has contributed to the development of CTE’s interface. 
      It was built with a focus on providing a good user experience. We also created 
      hierarchical and custom components with specialized functions. The Conditional 
      Token Explorer (CTE) was created alongside the Gnosis team to help blockchain 
      developers understand the Conditional Tokens Framework (CTF). The CTF is a 
      protocol for creating tokenized logic; tokens can be redeemed for underlying 
      collateral when a specific condition is true.`,
    ],
    mainImage: gnosisExplorer0,
    images: [{ alt: "gnosisExplorer0", src: gnosisExplorer0 }],
    about: [
      `The Conditional Token Explorer provides an interface for the 
    CTF, allowing users to interact with the protocol and its features. Specifically, 
    the CTE enables users to perform the following actions:`,
    ],
    aboutItems: [
      `Connect to an application using MetaMask or WallectConnect`,
      `Prepare a condition, including an Omen one`,
      `Split positions from collateral, from an existing position, and 
      into different collections`,
      `Merge positions into collateral, into another position, and into a different 
      collection at the same level`,
      `Report payouts`,
      `Redeem a position`,
      `List the user’s positions`,
      `Wrap and unwrap a position into an ERC20`,
    ],
    additionalContent: [
      {
        title: "The problem",
        description: [
          "Learn more about the challenges in our blog \"<a target='_blank' href='https://medium.com/protofire-blog/protofire-contributes-to-the-development-of-the-conditional-token-explorer-5fb4f305c1f4' class='underline underline-offset-1 text-white100'>Protofire Contributes to the Development of the Conditional Token Explorer</a>\".",
        ],
      },
      {
        title: "The solution",
        description: [
          "Protofire has contributed to the development of the <a target='_blank' href='https://cte.gnosis.io/#/conditions' class='underline underline-offset-1 text-white100'>CTE’s interface</a> to improve  its user experience and allow developers to create hierarchical and custom components with specialized functions. CTE’s frontend connects to the blockchain Ethereum network through ether.js and Web3Modal libraries. To speed up queries from the blockchain, we have implemented a subgraph that consumes smart contract data from a GraphQL service. The interface was built using React, Typescript, and style components.",
        ],
      },
      {
        title: "The client/partner",
        description: [
          `The Conditional Tokens Framework (CTF) is a protocol for 
    creating tokenized logic; tokens redeemable for underlying collateral when a 
    specific condition is true. Created by Gnosis, the protocol enables use cases 
    such as predictions, information gathering, and forecasting. While the CTF makes 
    it possible for developers to create various applications, it is also 
    sophisticated in operation, integration, and extension using traditional 
    blockchain techniques.`,
        ],
      },
    ],
    codeData: {
      items: [
        {
          title: "Conditional Tokens Explorer",
          link: "https://github.com/gnosis/conditional-tokens-explorer",
        },
      ],
    },
    mediaData: {
      items: [
        {
          title:
            "Protofire Contributes to the Development of the Conditional Token Explorer",
          link: "https://medium.com/protofire-blog/protofire-contributes-to-the-development-of-the-conditional-token-explorer-5fb4f305c1f4",
        },
      ],
    },
    bottomSlogan:
      "Do you need to develop a token explorer or an interface for your solution? ",
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  //-----------------------------------------------------------------------------

  "kyber-smart-contract": {
    metaTitle:
      "KyberDAO Smart Contract Proxy: Trustless Delegation | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Explore Protofire's non-custodial delegation service for KyberDAO. Stake, vote, and earn rewards securely. Empower decentralized governance.",
      },
      {
        name: "keywords",
        content:
          "KyberDAO governance, Trustless delegation service, Decentralized staking rewards, Blockchain voting proxy, DAO participation solutions",
      },
    ],
    title: "Kyber DAO Smart Contract Proxy",
    tagList: ["Governance", "Smart Contract"],
    description: [
      `In partnership with Kyber Network, Protofire created a trustless 
  smart contract proxy - a non-custodial delegation service - for the KyberDAO 
  governance. This service enables anyone to become a pool operator and perform 
  token staking/voting without the need to create new smart contracts. Now, token 
  holders can delegate governance to operators, while gaining interest from rewards.`,
    ],
    mainImage: kyberswap0,
    images: [
      { alt: "kyberswap0", src: kyberswap0 },
      { alt: "kyberswap1", src: kyberswap1 },
      { alt: "kyberswap2", src: kyberswap2 },
      // { alt: "kyberswap3", src: kyberswap3 },
      // { alt: "kyberswap4", src: kyberswap4 },
    ],
    about: [
      "We have built a trustless smart contract proxy and a non-custodial delegation service for the KyberDAO governance which included the following key features:",
    ],
    aboutItems: [
      "Token holders can become pool operators and stake/vote.",
      "Token holders can delegate governance to operators, and gain interest from rewards.",
    ],
    additionalContent: [
      {
        title: "The problem",
        description: [
          `The governance process in a decentralized protocol is prone 
    to transparency, engagement, and participation issues. As voting and staking 
    are time- and effort-intensive, multiple projects with assets worth millions 
    of dollars rely on a limited number of voters, which is quite risky.`,
          `Protofire and Kyber Network aimed to create a mechanism that 
    would help to eliminate these challenges. The service needed to enable any KNC 
    tokens to participate in KyberDAO - a community governance platform - 
    by delegating voting power to pool operators, while maintaining their stake in 
    a decentralized manner. Pool operators become highly involved in the voting 
    process, sharing rewards among delegators.`,
        ],
      },
      {
        title: "The solution",
        items: [
          `Rewards are stored trustlessly in the smart contract`,
          `Allow users to claim rewards from the smart contracts`,
          `Track rewards that have been claimed by users`,
        ],
      },
      {
        title: "The client/partner",
        description: [
          `Kyber Network is an on-chain liquidity protocol that allows 
    for integrating token swaps into any decentralized application (DApp). Using 
    Kyber Network, developers can build innovative apps, including instant token 
    swap services, decentralized payment flows, and financial DApps`,
        ],
      },
      {
        title: "Testimonial",
        description: [
          '"Thanks to Protofire’s support, we now have a trustless smart contract proxy that any pool operator can utilize for non-custodial KNC staking and voting delegation. The team has been very professional and we’re glad to work with them as part of our KyberDAO launch preparations."',
          "Shane Hong, Marketing Manager at Kyber Network",
        ],
      },
    ],
    mediaData: {
      items: [
        {
          title:
            "Protofire Developed a Trustless Delegation Solution for KyberDAO",
          link: "https://medium.com/protofire-blog/protofire-developed-a-trustless-delegation-solution-for-kyberdao-68dd3a1a0d89",
        },
      ],
    },
    bottomSlogan: "Need help with some smart contract development?",
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  //-----------------------------------------------------------------------------

  "ox-launch-kit": {
    metaTitle: "0x Launch Kit: Build DEX in Minutes | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Create your own decentralized exchange with 0x Launch Kit. Open-source relayer template for ERC-20 and ERC-721 tokens. Start trading in minutes.",
      },
      {
        name: "keywords",
        content:
          "0x relayer template, Decentralized exchange development, ERC-20 trading platform, Blockchain marketplace creation, 0x protocol integration",
      },
    ],
    title: "0x Launch Kit (Reference Implementation)",
    tagList: ["Launch Kit"],
    description: [
      `Protofire has developed a repository that contains an open-source, 
  free-to-use 0x relayer template that one can use as a starting point for one’s 
  own project. The 0x Launch Kit serves as a way to “launch a 0x relayer in under 
  a minute”.`,
    ],
    mainImage: oxLunch0,
    images: [{ alt: "oxLunch0", src: oxLunch0 }],
    about: [
      "The solution is a fully functional working reference for any developer or organization to launch a relayer in under a minute. It includes a production-ready code, a neat and tidy UI, ensuring unsurpassed user experience, and a ERC-20 and ERC-721 relayer interface.",
    ],
    metrics: [
      {
        value: "$130.4B",
        text: "volume in 0x Protocol in Q1/2022",
      },
      {
        value: "1.2M",
        text: "cumulative unique users in 0x Protocol in Q1/2022",
      },
    ],
    additionalContent: [
      {
        title: "The problem",
        description: [
          `Developing a solution from scratch using 0x can be challenging 
    and time-consuming. Protofire and the 0x team worked together on a reference 
    implementation called the 0x Launch Kit. The Kit includes a simple, extensible 
    0x relayer codebase that can be forked or used as reference material to create 
    your own marketplace. The Launch Kit addresses many issues and simplifies 
    integrating with 0x, reducing the effort needed to add decentralized token trading
     to your project.`,
        ],
      },
      {
        title: "The solution",
        items: [
          `This repo contains a Docker image that will run both codebases 
    simultaneously for easy deployment, but you can also clone or fork each repo 
    independently`,
          `Quickly launch a market for your community token`,
          `Seamlessly create an in-game marketplace for digital items and collectibles`,
          `Enable trading of any ERC-20 or ERC-721 asset The Launch Kit is split 
        into two separate repos:`,
          "0x Launch Kit Frontend: ERC-20 and ERC-721 <a target='_blank' href='https://github.com/0xProject/0x-launch-kit-frontend' class='underline underline-offset-1 text-white100'>relayer UIs</a>",
          "0x API: An API that <a target='_blank' href='https://github.com/0xProject/0x-api' class='underline underline-offset-1 text-white100'>supports</a> the Standard Relayer API specification",
        ],
      },
      {
        title: "The client/partner",
        description: [
          `0x is an open protocol that provides an infrastructure to 
    enable the decentralized exchange of assets on the Ethereum blockchain. The 
    0x protocol employs off-chain transaction relay with on-chain settlement via 
    its Ethereum-based smart contract pipeline. The ZRX token functions as a 
    governance token that enables ecosystem stakeholders to coordinate upgrades 
    to the protocol.`,
        ],
      },
    ],
    codeData: {
      items: [
        {
          title: "0x Launch Kit",
          link: "https://github.com/0xProject/0x-launch-kit",
        },
        {
          title: "0x Launch Kit Frontend",
          link: "https://github.com/0xProject/0x-launch-kit-frontend",
        },
      ],
    },
    mediaData: {
      items: [
        {
          title:
            "Introducing the 0x Launch Kit Launch a relayer in under a minute",
          link: "https://blog.0xproject.com/introducing-the-0x-launch-kit-4acdc3453585?gi=177b3c74478b",
        },
      ],
    },
    bottomSlogan: "Looking for a tool kit for your development? ",
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },
};
