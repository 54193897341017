import { ReactComponent as Shape } from "../../assets/images/shape.svg";

type CheckboxProps = {
  checked?: boolean;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
};

function Checkbox({
  checked = false,
  onClick = () => {},
  className = "",
  disabled,
}: CheckboxProps) {
  return (
    <div
      aria-checked={checked}
      role="checkbox"
      onKeyDown={() => {}}
      tabIndex={0}
      className={`w-8 h-8 rounded-lg cursor-pointer hover:opacity-60 flex justify-center items-center transition-all ${
        checked ? "bg-orange100" : "bg-black55"
      } ${className}`}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      {checked && <Shape />}
    </div>
  );
}

Checkbox.defaultProps = {
  checked: false,
  onClick: () => {},
  className: "",
  disabled: false,
};

export default Checkbox;
