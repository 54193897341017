import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import "./community.css";
import communityBg from "../assets/community/community-bg.png";
import teamItem01 from "../assets/community/teamItem01.png";
import teamItem02 from "../assets/community/teamItem02.png";
import teamItem03 from "../assets/community/teamItem03.png";
import teamItem04 from "../assets/community/teamItem04.png";
import teamItem05 from "../assets/community/teamItem05.png";
import teamItem06 from "../assets/community/teamItem06.png";
import teamItem07 from "../assets/community/teamItem07.png";
import teamItem08 from "../assets/community/teamItem08.png";
import teamItem09 from "../assets/community/teamItem09.png";
import teamItem10 from "../assets/community/teamItem10.png";
import teamItem11 from "../assets/community/teamItem11.png";
import teamItem12 from "../assets/community/teamItem12.png";
import JoinUs from "./joinUs";
import Join from "./join";
import Button from "../common/button/Button";

function Community() {
  const handleScrollToJoinClick = () => {
    const protofireOffersId = document.getElementById("joinUs");
    if (protofireOffersId) {
      window.scrollTo({
        top: protofireOffersId.offsetTop - 100,
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      className="bg-no-repeat bg-top-center bg-contain"
      style={{ backgroundImage: `url(${communityBg})` }}
    >
      <div className="max-w-[1120px] mx-auto pt-[80px] pb-[150px]">
        <Helmet>
          <title>
            Join Protofire&apso;s Web3 Community: Collaborate on Blockchain
            Projects | Protofire
          </title>
          <meta
            name="description"
            content="Connect with Web3 experts, work on innovative blockchain projects, and access learning opportunities. Join Protofire's community for developers and enthusiasts."
          />
          <meta
            name="keywords"
            content="Web3 community collaboration, Blockchain project opportunities, Crypto developer network, Web3 learning resources, Blockchain career development"
          />
        </Helmet>
        <section className="mx-4 md:mx-6 xl:mx-0">
          <div className="pt-[100px] md:pt-[120px]">
            <h1
              className="text-white100 text-[32px] leading-[40px] md:text-5xl md:leading-[56px] xl:text-[64px] xl:leading-[72px]"
              style={{ fontFamily: "Onest-SemiBold" }}
            >
              Protofire Web3 Community
            </h1>
            <p className="mt-4 mb-0 text-white55 text-base md:text-lg">
              Become part of the blockchain engineering collective and work with
              the founders of Gnosis, Graph, IoTeX, Ocean, and more.
            </p>
            <div className="mt-[56px]">
              <Join />
            </div>
          </div>
        </section>

        <div className="my-[120px] md:my-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />

        <section className="mx-4 md:mx-6 xl:mx-0">
          <div className="flex flex-col gap-6 md:flex-row md:gap-[120px]">
            <h3 className="text-lg text-white100 flex-[1]">Who We Are</h3>
            <div className="text-white55 text-base flex-[2]">
              <p>
                We work on Web3 projects and dapps together with The Graph,
                Chainlink, Gnosis, Aave, MakerDAO, Ocean Protocol, IoTeX and
                others.
              </p>
              <p className="mt-8">
                Our community is comprised of project teams and individuals.
                Join a team, fund a team, propose to launch a project, or stay
                part of the community as an individual member sharpening your
                Web3 knowledge, haunting bounties, or just communicating with
                professionals!
              </p>
            </div>
          </div>

          <div className="my-[40px] border-t border-dashed border-white15" />

          <div className="flex flex-col gap-6 md:flex-row md:gap-[120px]">
            <h3 className="text-lg text-white100 flex-[1]">Who can join?</h3>
            <div className="text-white55 text-base flex-[2]">
              <p>
                ANYONE interested in Web3 programming & development can join our
                Web3 community!
              </p>
              <p className="mt-8">
                We love meeting people and knowledge sharing, and we definitely
                will be happy to see you on board!
              </p>

              <Button
                size="sm"
                className="mt-8 w-full md:w-auto"
                onClick={handleScrollToJoinClick}
              >
                Join the Communuty
              </Button>
            </div>
          </div>
        </section>

        <div className="my-[120px] md:my-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />

        <section className="mt-[100px] mx-4 md:mx-6 xl:mx-0">
          <h2
            className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]"
            style={{ fontFamily: "Onest-SemiBold" }}
          >
            Opportunities
          </h2>
          <p className="mt-4 mb-0 text-white55 text-base md:text-lg md:max-w-[832px]">
            We create opportunities for you. Make use of them to the utmost.
          </p>

          <div className="mt-[56px] flex flex-col md:flex-row gap-6 xl:gap-8 items-baseline">
            <div
              className="p-[0.5px] pb-0 rounded-lg flex flex-1"
              style={{
                background:
                  "linear-gradient(110.31deg, #6745ee 0%, rgba(0, 0, 0, 0) 63.51%)",
              }}
            >
              <div
                className="p-8 rounded-lg w-full h-full"
                style={{
                  background:
                    "radial-gradient(100% 505.32% at 0% 0%, #463a7c 0%, #222326 100%)",
                }}
              >
                <p className="m-0 text-2xl text-white100">
                  LEARN NEWS & INSIGHTS
                </p>
                <p className="mb-0 mt-6 text-base text-white55">
                  Sign up for our community & crypto world news, newsletters,
                  useful GitHub repos, videos, AMAs, and memes. Attend
                  hackathons & webinars from Protofire and Gnosis, Maker &
                  Ocean.
                </p>
              </div>
            </div>
            <div
              className="p-[0.5px] pb-0 rounded-lg flex flex-1"
              style={{
                background:
                  "linear-gradient(110.31deg, #1c757a 0%, rgba(0, 0, 0, 0) 63.51%)",
              }}
            >
              <div
                className="p-8 rounded-lg w-full h-full"
                style={{
                  background:
                    "radial-gradient(100% 505.32% at 0% 0%, #1b5a5e 0%, #151517 100%)",
                }}
              >
                <p className="m-0 text-2xl text-white100">
                  JOIN PROTOFIRE FULL-TIME!
                </p>
                <p className="mb-0 mt-6 text-base text-white55">
                  Go through a technical interview to get a full-time job offer!
                  Accept it any time, though we surely wish to connect asap. You
                  get a USDC/ USD/ token salary and a share of tokens, with
                  monthly staking and cash-out options.
                </p>
              </div>
            </div>
          </div>

          <div className="mt-6 xl:mt-8 flex flex-col md:flex-row gap-6 xl:gap-8 items-baseline">
            <div
              className="p-[0.5px] pb-0 rounded-lg flex flex-1"
              style={{
                background:
                  "linear-gradient(110.31deg, #389839 0%, rgba(0, 0, 0, 0) 63.51%)",
              }}
            >
              <div
                className="p-8 rounded-lg w-full h-full"
                style={{
                  background:
                    "radial-gradient(100% 505.32% at 0% 0%, #2f5633 0%, #191a1c 100%)",
                }}
              >
                <p className="m-0 text-2xl text-white100">
                  GET PAID FOR REFERRALS
                </p>
                <p className="mb-0 mt-6 text-base text-white55">
                  Invite peers to the Protofire for a reward! If they
                  successfully pass our technical interview and accept the job
                  offer, we will pay you a solid payout via BMC, Avalanche, or
                  Polygon.
                </p>
              </div>
            </div>
            <div
              className="p-[0.5px] pb-0 rounded-lg flex flex-1"
              style={{
                background:
                  "linear-gradient(110.31deg, #fa7f11 0%, rgba(0, 0, 0, 0) 63.51%)",
              }}
            >
              <div
                className="p-8 rounded-lg w-full h-full"
                style={{
                  background:
                    "radial-gradient(100% 505.32% at 0% 0%, #77461b 0%, #151617 100%)",
                }}
              >
                <p className="m-0 text-2xl text-white100">
                  STUDY AT PROTOGARDENS
                </p>
                <p className="mb-0 mt-6 text-base text-white55">
                  Get toolboxes, access must-have libraries, learn best
                  practices, read articles, go to discounted blockchain courses,
                  and access free events from Protofire!
                </p>
              </div>
            </div>
          </div>

          <div className="mt-6 xl:mt-8 flex flex-col md:flex-row gap-6 xl:gap-8 items-baseline">
            <div
              className="p-[0.5px] pb-0 rounded-lg flex flex-1"
              style={{
                background:
                  "linear-gradient(110.31deg, #0f40fa 0%, rgba(0, 0, 0, 0) 63.51%)",
              }}
            >
              <div
                className="p-8 rounded-lg w-full h-full"
                style={{
                  background:
                    "radial-gradient(100% 505.32% at 0% 0%, #162b75 0%, #161618 100%)",
                }}
              >
                <p className="m-0 text-2xl text-white100">CONTRIBUTE TO WEB3</p>
                <p className="mb-0 mt-6 text-base text-white55">
                  Jump in challenges for engineers from our teams. Work on the
                  best projects in the industry. Let’s do meaningful things
                  together!
                </p>
              </div>
            </div>
            <div
              className="p-[0.5px] pb-0 rounded-lg flex flex-1"
              style={{
                background:
                  "linear-gradient(110.31deg, #d60ad5 0%, rgba(0, 0, 0, 0) 63.51%)",
              }}
            >
              <div
                className="p-8 rounded-lg w-full h-full"
                style={{
                  background:
                    "radial-gradient(100% 505.32% at 0% 0%, #631165 0%, #141516 100%)",
                }}
              >
                <p className="m-0 text-2xl text-white100">ENRICH RECRUITERS</p>
                <p className="mb-0 mt-6 text-base text-white55">
                  Do you know great recruiters or do you recruit yourself? We
                  have got special offers for them! Invite recruiters to join
                  the community, and let them make easy profits, too.
                </p>
              </div>
            </div>
          </div>

          <div className="mt-6 xl:mt-8 flex flex-col md:flex-row gap-6 xl:gap-8 items-baseline">
            <div
              className="p-[0.5px] pb-0 rounded-lg flex flex-1"
              style={{
                background:
                  "linear-gradient(110.31deg, #4a098a 0%, rgba(0, 0, 0, 0) 63.51%)",
              }}
            >
              <div
                className="p-8 rounded-lg w-full h-full"
                style={{
                  background:
                    "radial-gradient(100% 505.32% at 0% 0%, #390d66 0%, #171617 100%)",
                }}
              >
                <p className="m-0 text-2xl text-white100">
                  ACCEPT A MOST WANTED JOB
                </p>
                <p className="mb-0 mt-6 text-base text-white55">
                  The Most Wanted jobs are our hot-rated vacancies. Be
                  attentive, check your mail for updates regularly. These jobs
                  come with an inspiring welcome bonus. ;)
                </p>
              </div>
            </div>
            <div
              className="p-[0.5px] pb-0 rounded-lg flex flex-1"
              style={{
                background:
                  "linear-gradient(110.31deg, #8c2a10 0%, rgba(0, 0, 0, 0) 63.51%)",
              }}
            >
              <div
                className="p-8 rounded-lg w-full h-full"
                style={{
                  background:
                    "radial-gradient(100% 505.32% at 0% 0%, #662414 0%, #181716 100%)",
                }}
              >
                <p className="m-0 text-2xl text-white100">
                  PROTOTOKEN AND PROFIT-SHARING
                </p>
                <p className="mb-0 mt-6 text-base text-white55">
                  ProtoToken is the Protofire’s original token. Any full member
                  of the community gains a share in ProtoTokens. The earlier you
                  join the community, the larger your portfolio of shares will
                  grow!
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="my-[120px] md:my-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />

        <section className="mt-[100px] mx-4 md:mx-6 xl:mx-0">
          <h2
            className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]"
            style={{ fontFamily: "Onest-SemiBold" }}
          >
            Our Teams
          </h2>

          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-2 md:gap-4 mt-[56px]">
            <img src={teamItem01} alt="teamItem01" />
            <img src={teamItem02} alt="teamItem02" />
            <img src={teamItem03} alt="teamItem03" />
            <img src={teamItem04} alt="teamItem04" />
            <img src={teamItem05} alt="teamItem05" />
            <img src={teamItem06} alt="teamItem06" />
            <img src={teamItem07} alt="teamItem07" />
            <img src={teamItem08} alt="teamItem08" />
            <img src={teamItem09} alt="teamItem09" />
            <img src={teamItem10} alt="teamItem10" />
            <img src={teamItem11} alt="teamItem11" />
            <img src={teamItem12} alt="teamItem12" />
          </div>
        </section>

        <div className="my-[120px] md:my-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />

        <section className="mt-[100px] mx-4 md:mx-6 xl:mx-0">
          <h2
            id="joinUs"
            className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]"
            style={{ fontFamily: "Onest-SemiBold" }}
          >
            Join Us
          </h2>
          <p className="mt-4 mb-0 text-white55 text-base md:text-lg md:max-w-[832px]">
            You’re here for a reason. Let’s not waste time and collab! Drop us
            your contact information, we’ll contact you back as soon as we can
            to give you the best experience.
          </p>
          <JoinUs />
        </section>
        <ToastContainer />
      </div>
    </div>
  );
}

export default Community;
