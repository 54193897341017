function ProjectsMetrics() {
  return (
    <>
      <div className="flex flex-1 mt-8 md:mt-0">
        <div className="relative flex flex-1 flex-col mr-3">
          <div className="text-white100 text-[32px] md:text-[48px]">201+</div>
          <div className="mt-1 md:mt-2 text-base text-white40">
            Projects completed
          </div>
        </div>
        <div className="flex flex-1 flex-col mr-3">
          <div className="text-white100 text-[32px] md:text-[48px]">600K+</div>
          <div className="mt-1 md:mt-2 text-base text-white55">
            Unique wallets using our smart contracts
          </div>
        </div>
      </div>
      <div className="flex flex-1 mt-8 md:mt-0">
        <div className="relative flex flex-1 flex-col mr-3">
          <div className="text-white100 text-[32px] md:text-[48px]">35M+</div>
          <div className="mt-1 md:mt-2 text-base text-white55">
            Total transactions count
          </div>
        </div>
        <div className="flex flex-1 flex-col mr-3">
          <div className="text-white100 text-[32px] md:text-[48px]">13M+</div>
          <div className="mt-1 md:mt-2 text-base text-white55">
            Total value locked
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectsMetrics;
