/* eslint-disable react/require-default-props */
/// <reference types="react-scripts" />
import { Link, useLocation } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { items } from "./data";
import { ReactComponent as Protofire } from "../assets/images/protofire.svg";
import { ReactComponent as MenuMobile } from "../assets/images/menu1.svg";
import { ReactComponent as Cancel } from "../assets/images/cancel.svg";
import { ReactComponent as ChevronDown } from "../assets/images/checvronDown.svg";
import { ReactComponent as ChevronUp } from "../assets/images/checvronUpOrange.svg";
import MobileNav from "./mobileMenu";
import Button from "../common/button/Button";
import bookCalendlyCall from "../../utils/bookCalendlyCall";
import { DefaultCallendlyName } from "../../utils/utils";
import { CallUserContext } from "../../store";

type MenuItemProps = {
  to: string;
  isActive: boolean;
  text: string;
  subItems?: {
    link: string;
    text: string;
  }[];
};

type MenuProps = {
  isCallAvailable?: boolean;
};

function MenuItem({ to, isActive, text, subItems }: MenuItemProps) {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const ref = useRef<any>(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsSubmenuOpen(false);
    }
  };
  useEffect(() => {
    if (subItems) {
      document.addEventListener("click", handleClickOutside, true);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ChevronArrow = (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
      onClick={() => setIsSubmenuOpen(!isSubmenuOpen)}
      className="ml-1 w-[26px] px-2 h-[26px] flex items-center hover:opacity-60 transition-opacity"
    >
      {isSubmenuOpen ? (
        <ChevronUp />
      ) : (
        <ChevronDown
          className={`${isActive ? "text-orange100" : "text-white100"}`}
        />
      )}
    </div>
  );
  return (
    <li className="xl:pl-2 text-sm relative" ref={ref}>
      <div className="flex items-center justify-end">
        <Link
          to={to}
          onClick={() => setIsSubmenuOpen(false)}
          className={`block pt-2 pr-4 pl-3 hover:opacity-60 transition-opacity md:p-0 ${
            isActive || isSubmenuOpen ? "text-orange100" : ""
          }`}
        >
          {text}
        </Link>
        {subItems ? ChevronArrow : ""}
      </div>
      {subItems && isSubmenuOpen && (
        <ul className="text-white100 text-sm absolute flex flex-col gap-4 -left-2 top-8 p-4 bg-[#1a1b1b] rounded-lg border border-white10 border-solid ">
          {subItems.map((subItem) => (
            <li
              key={subItem.link}
              className="hover:opacity-60 transition-opacity"
            >
              <Link
                to={`${to}/${subItem.link}`}
                onClick={() => {
                  setIsSubmenuOpen(false);
                }}
              >
                {subItem.text}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
}

function Menu({ isCallAvailable = true }: MenuProps) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false);
  const context = useContext(CallUserContext);

  const handleOpenMobileNav = () => {
    setIsOpen(true);
    setDisplayMobileMenu(true);
  };
  const handleCloseMobileNav = () => {
    setIsOpen(false);
    setDisplayMobileMenu(false);
  };

  const handleBookCallClick = () => {
    context?.setCallUser(DefaultCallendlyName);
    bookCalendlyCall();
  };

  return (
    <div className="max-w-[1152px] mx-auto">
      <nav className=" px-4 py-2 rounded-2xl z-10 navbar ">
        <div className="lg:flex flex-wrap justify-between items-center lg:mx-auto">
          <div
            className=" justify-between items-center w-full flex 
            z-10"
          >
            <Link to="/" className="py-2">
              <Protofire />
            </Link>

            <div className="flex items-center">
              <ul className="sm:hidden lg:flex leading-5 flex-col md:flex-row text-white100 md:space-x-8 md:mt-0 items-center whitespace-nowrap">
                {items.map((item) => (
                  <MenuItem
                    key={item.link}
                    to={item.link}
                    isActive={location.pathname.includes(item.link)}
                    text={item.text}
                    subItems={item.subItems}
                  />
                ))}
              </ul>
              <div className="flex items-center ml-10">
                {isCallAvailable && !isOpen && (
                  <Button
                    className="mr-3 lg:mr-0"
                    onClick={handleBookCallClick}
                  >
                    Book a call
                  </Button>
                )}
                {isOpen ? (
                  <Cancel
                    className="lg:hidden mr-2 cursor-pointer"
                    onClick={handleCloseMobileNav}
                  />
                ) : (
                  <MenuMobile
                    className="lg:hidden cursor-pointer"
                    onClick={handleOpenMobileNav}
                  />
                )}
              </div>
            </div>
          </div>
          {displayMobileMenu && (
            <MobileNav
              onCloseMenu={handleCloseMobileNav}
              isCallAvailable={isCallAvailable}
            />
          )}
        </div>
      </nav>
    </div>
  );
}

export default Menu;
