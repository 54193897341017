/* eslint-disable import/prefer-default-export */
export const items = [
  { link: "about", text: "About" },
  {
    link: "services",
    text: "Solutions & Services",
    subItems: undefined,
    // [
    //   { link: "smart-contract", text: "Smart Contracts" },
    //   { link: "decentralized-apps", text: "Decentralized Applications" },
    //   { link: "developer-tools", text: "Developer Tools" },
    //   { link: "integrations", text: "Integrations" },
    //   { link: "dev-ops", text: "DevOps" },
    //   { link: "safe-deployment", text: "Safe Deployment" },
    //   { link: "tvl-solutions", text: "TVL Solutions" },
    // ],
  },
  { link: "projects", text: "Case Studies & Projects" },
  { link: "careers", text: "Careers" },
  { link: "community", text: "Community" },
  { link: "solhint", text: "Solhint" },
  // { link: "blog", text: "Blog" },
];
