type TextFieldProps = {
  name: string;
  id: string;
  label?: string;
  value?: string;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  className?: string;
  isMultiRow?: boolean;
};

function TextField({
  value,
  onChange,
  label,
  className,
  name,
  id,
  isMultiRow,
}: TextFieldProps) {
  const defInputClassName =
    "border border-white10 bg-white5 text-white100 placeholder:text-white25 rounded-md p-3 text-base focus:outline-none caret-orange100";
  return (
    <div className={`flex flex-col w-full ${className}`}>
      {label && (
        <label className="text-white55 text-sm mb-3" htmlFor={id}>
          {label}
        </label>
      )}
      {isMultiRow ? (
        <textarea
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          className={`${defInputClassName} min-h-[50px] h-[50px]`}
        />
      ) : (
        <input
          id={id}
          name={name}
          type="text"
          value={value}
          onChange={onChange}
          className={defInputClassName}
        />
      )}
    </div>
  );
}

export default TextField;

TextField.defaultProps = {
  label: "",
  value: "",
  onChange: () => {},
  className: "",
  isMultiRow: false,
};
