import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Modal from "react-modal";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";
import TagManager from "react-gtm-module";
import { ReactComponent as Cancel } from "./components/assets/images/cancel.svg";
import { ReactComponent as Sclose } from "./components/assets/images/sclose.svg";
import { ReactComponent as Sopen } from "./components/assets/images/sopen.svg";
import Button from "./components/common/button/Button";

export default function CookiesSetup() {
  const [cookieSettings, setCookieSettings] = useState(false);
  const [performanceCookies, setPerformanceCookies] = useState(true);
  const [analyticCookies, setAnalyticCookies] = useState(true);
  const [marketingCookies, setMarketingCookies] = useState(true);
  const [esentialDesc, setEsentialDesc] = useState(false);
  const [performanceDesc, setPerformanceDesc] = useState(false);
  const [analyticDesc, setAnalyticDesc] = useState(false);
  const [marketingDesc, setMarketingDesc] = useState(false);

  const location = useLocation();

  const [userPreference, setUserPreference] = useState({
    essentialCookies: true,
    analyticCookies: true,
    performanceCookie: true,
    marketingCookies: true,
  });

  const [loadCookies, setLoadCookies] = useState(false);

  // Cookie Accept
  const handleAccept = () => {
    document.cookie = `CookiePreference=${JSON.stringify(userPreference)}`;
    setLoadCookies(true);
  };

  // Cookie Preference
  const handleDecline = () => {
    document.cookie = `CookieConsent=true`;
    setCookieSettings(true);
  };

  const handleCloseCookie = () => {
    document.cookie = `CookieConsent=true`;
    document.cookie = `CookiePreference=${JSON.stringify(userPreference)}`;
    setCookieSettings(!cookieSettings);
    setLoadCookies(true);
  };

  const handleSaveCookies = () => {
    document.cookie = `CookieConsent=true`;
    document.cookie = `CookiePreference=${JSON.stringify(userPreference)}`;
    setCookieSettings(!cookieSettings);
    setLoadCookies(true);
  };

  const handleRejectCookies = () => {
    setUserPreference((prevState) => {
      return {
        ...prevState,
        performanceCookie: false,
        analyticCookies: false,
        marketingCookies: false,
      };
    });
    document.cookie = `CookieConsent=false`;
    document.cookie = `CookiePreference=${JSON.stringify({
      essentialCookies: true,
      analyticCookies: false,
      performanceCookie: false,
      marketingCookies: false,
    })}`;
    setCookieSettings(!cookieSettings);
    setLoadCookies(true);
  };

  const handlePerformanceCookies = () => {
    setPerformanceCookies(!performanceCookies);
    setUserPreference((prevState) => {
      return {
        ...prevState,
        performanceCookie: !prevState.performanceCookie,
      };
    });
  };

  const handleAnalyticCookies = () => {
    setAnalyticCookies(!analyticCookies);
    setUserPreference((prevState) => {
      return {
        ...prevState,
        analyticCookies: !prevState.analyticCookies,
      };
    });
  };

  const handleMarketingCookies = () => {
    setMarketingCookies(!marketingCookies);
    setUserPreference((prevState) => {
      return {
        ...prevState,
        marketingCookies: !prevState.marketingCookies,
      };
    });
  };

  const getCookie = (cname: string) => {
    const cookies = {};
    document.cookie.split(";").forEach((el) => {
      const [key, value] = el.split("=");
      cookies[key.trim()] = value;
    });
    return cookies[cname];
  };

  useEffect(() => {
    const getConsent = getCookie("CookieConsent");
    const getPreference = getCookie("CookiePreference");
    const cookieConsent = getConsent === "true";
    const cookiePreference =
      getPreference !== undefined && JSON.parse(getPreference);

    if (!cookieConsent) return;

    if (cookieConsent && cookiePreference.analyticCookies) {
      const { REACT_APP_HOTJAR_ID, REACT_APP_HOTJAR_SV } = process.env || "";
      const hjid: string = REACT_APP_HOTJAR_ID || "";
      const hjsv: string = REACT_APP_HOTJAR_SV || "";
      hotjar.initialize(parseInt(hjid, 10), parseInt(hjsv, 10));

      const tagManagerArgs = {
        gtmId: `${process.env.REACT_APP_GTM_ID}`,
      };

      TagManager.initialize(tagManagerArgs);

      ReactGA.initialize(`${process.env.REACT_APP_GA_ID}`);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

    // TODO - if there is any performance app should be added here
    // if (cookieConsent && cookiePreference.performanceCookie) {
    //   console.log("load performance");
    // }

    // TODO - if there is any marketing app should be added here
    // if (cookieConsent && cookiePreference.marketingCookies) {
    //   console.log("load marketing");
    // }

    setLoadCookies(false);
  }, [userPreference, loadCookies, location]);

  return (
    <div>
      <CookieConsent
        location="bottom"
        enableDeclineButton
        buttonText="I accept"
        style={{
          background: "#0C152C",
          fontFamily: "Onest-Medium",
          fontSize: "12px",
          color: "#8999C1",
          display: "flex",
          alignItems: "center",
        }}
        declineButtonText="Cookie settings"
        declineButtonClasses="bg-white10 rounded text-white100 py-2 px-4 mx-5 cookiesSetting"
        buttonClasses="btn text-white100 mr-10 px-10 py-2"
        disableButtonStyles
        onDecline={handleDecline}
        onAccept={handleAccept}
        expires={90}
        contentClasses="text-white70"
      >
        We use cookies to offer you a better browsing experience, personalize
        content and ads, provide social media features, and analyze our traffic.
        Read about how we use cookies and how you can control them by clicking
        Cookie Settings. You consent to our cookies if you continue using this
        website.
      </CookieConsent>
      <Modal
        isOpen={cookieSettings}
        className="bg-white10 py-2 smallModal top-[50%] -translate-y-[50%] absolute ml-[50%] -translate-x-[50%] z-50 text-sm min-w-[50%] rounded-xl w-full md:w-auto mx-4"
        overlayClassName="cookiesSettingModalOverlay"
        onRequestClose={() => setCookieSettings(!cookieSettings)}
      >
        <div className="flex text-white70 justify-between py-3 border-b border-white15">
          <div className="pl-5 text-lg text-white100"> Cookie setting</div>
          <div>
            <Cancel
              className="mr-5 cursor-pointer"
              onClick={handleCloseCookie}
            />
          </div>
        </div>
        <div className="py-4 border-b border-white15">
          <div className="flex text-white70 justify-between px-5">
            <div>
              {" "}
              Essential website cookies{" "}
              {!esentialDesc && (
                <Sclose
                  onClick={() => setEsentialDesc(!esentialDesc)}
                  className="inline ml-2 cursor-pointer"
                />
              )}
              {esentialDesc && (
                <Sopen
                  onClick={() => setEsentialDesc(!esentialDesc)}
                  className="inline ml-2 cursor-pointer"
                />
              )}
            </div>
            <div> Always on</div>
          </div>
          {esentialDesc && (
            <div
              className="text-white70 px-5 pt-2 text-xs"
              style={{ fontFamily: "Onest-Medium" }}
            >
              Necessary cookies help make a website usable by enabling basic
              functions like page navigation and access to secure areas of the
              website. The website cannot function properly without these
              cookies.
            </div>
          )}
        </div>
        <div className="py-3 border-b border-white15">
          <div className="flex text-white70 justify-between px-5">
            <div>
              Performance cookies{" "}
              {!performanceDesc && (
                <Sclose
                  onClick={() => setPerformanceDesc(!performanceDesc)}
                  className="inline ml-2 cursor-pointer"
                />
              )}
              {performanceDesc && (
                <Sopen
                  onClick={() => setPerformanceDesc(!performanceDesc)}
                  className="inline ml-2 cursor-pointer"
                />
              )}
            </div>
            <input
              id="privacy"
              type="checkbox"
              checked={performanceCookies}
              onChange={handlePerformanceCookies}
              className="w-6 h-6 text-blue100  rounded-full 
              focus:ring-yellow  cursor-pointer
              focus:ring-2  ring-offset-yellow accent-yellow
              "
            />
          </div>
          {performanceDesc && (
            <div
              className="text-white70 px-5 pt-2 text-xs"
              style={{ fontFamily: "Onest-Medium" }}
            >
              These cookies are used to enhance the performance and
              functionality of our websites but are non-essential to their use.
              For example it stores your preferred language or the region that
              you are in.
            </div>
          )}
        </div>
        <div className="py-4 border-b border-white15">
          <div className="flex text-white70 justify-between px-5">
            <div>
              Analytics cookies{" "}
              {!analyticDesc && (
                <Sclose
                  onClick={() => setAnalyticDesc(!analyticDesc)}
                  className="inline ml-2 cursor-pointer"
                />
              )}
              {analyticDesc && (
                <Sopen
                  onClick={() => setAnalyticDesc(!analyticDesc)}
                  className="inline ml-2 cursor-pointer"
                />
              )}
            </div>
            <input
              id="privacy"
              type="checkbox"
              checked={analyticCookies}
              onChange={handleAnalyticCookies}
              className="w-6 h-6 text-blue100  rounded-full 
              focus:ring-yellow   cursor-pointer
              focus:ring-2  ring-offset-yellow accent-yellow
              "
            />
          </div>
          {analyticDesc && (
            <div
              className="text-white70 px-5 pt-2 text-xs"
              style={{ fontFamily: "Onest-Medium" }}
            >
              We use analytics cookies to help us measure how users interact
              with website content, which helps us customize our websites and
              application for you in order to enhance your experience.
            </div>
          )}
        </div>
        <div className="py-4 border-b border-white15">
          <div className="flex text-white70 justify-between px-5 ">
            <div>
              Marketing cookies{" "}
              {!marketingDesc && (
                <Sclose
                  onClick={() => setMarketingDesc(!marketingDesc)}
                  className="inline ml-2 cursor-pointer"
                />
              )}
              {marketingDesc && (
                <Sopen
                  onClick={() => setMarketingDesc(!marketingDesc)}
                  className="inline ml-2 cursor-pointer"
                />
              )}
            </div>
            <input
              id="privacy"
              type="checkbox"
              checked={marketingCookies}
              onChange={handleMarketingCookies}
              className="w-6 h-6 text-blue100  rounded-full 
              focus:ring-yellow  cursor-pointer
              focus:ring-2  ring-offset-yellow accent-yellow
              "
            />
          </div>
          {marketingDesc && (
            <div
              className="text-white70 px-5 pt-2 text-xs"
              style={{ fontFamily: "Onest-Medium" }}
            >
              These cookies are used to make advertising messages more relevant
              to you and your interests. The intention is to display ads that
              are relevant and engaging for the individual user and thereby more
              valuable for publishers and third party advertisers.
            </div>
          )}
        </div>
        <div className="py-4">
          <div className="flex text-white70 justify-between px-5">
            <Button onClick={handleRejectCookies} variant="outlined">
              Reject Cookies
            </Button>
            <Button onClick={handleSaveCookies}>Save setting</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
