/* eslint-disable no-param-reassign */
import { Routes, Route } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import Home from "./components/home/home";
import Careers from "./components/careers/Careers";
import About from "./components/about/about";
import Projects from "./components/projects/Projects";
import Services from "./components/services/Services";
import ServiceDetails from "./components/services/ServiceDetails";
import SolutionDetails from "./components/solutions/SolutionDetails";
import NotFound from "./components/NotFound404";
import MainLayout from "./layouts/mainLayout/mainLayout";
import SolhintLayout from "./layouts/solhintLayout/solhintLayout";
import Solhint from "./components/solhint/solhint";
import Community from "./components/community/community";
// import Blog from "./components/blog/Blog";
import PrivacyPolicy from "./components/privacyPolicy";
import ProjectDetails from "./components/projects/ProjectDetails";
import { CallUserContext } from "./store";

function App(): JSX.Element {
  const [callUser, setCallUser] = useState<string | null>(null);
  const contextValue = useMemo(
    () => ({ callUser, setCallUser }),
    [callUser, setCallUser]
  );

  const handleCalendlyBook = useCallback(
    (event) => {
      if (event.data.event === "calendly.event_scheduled" && callUser) {
        axios
          .post(
            `${process.env.REACT_APP_WEBHOOK_URL}`,
            JSON.stringify({
              text: `A Calendly meeting has been created with: \n
              ${callUser} \n
              From page ${window.location.href}`,
            }),
            {
              withCredentials: false,
              transformRequest: [
                (data, headers: any) => {
                  delete headers.post["Content-Type"];
                  return data;
                },
              ],
            }
          )
          .finally(() => setCallUser(null));
      }
    },
    [callUser]
  );

  useEffect(() => {
    window.addEventListener("message", handleCalendlyBook);
    return () => {
      window.removeEventListener("message", handleCalendlyBook);
    };
  }, [handleCalendlyBook]);

  return (
    <CallUserContext.Provider value={contextValue}>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="careers" element={<Careers />} />
          <Route path="projects" element={<Projects />} />
          <Route path="projects/:projectId" element={<ProjectDetails />} />
          <Route path="services" element={<Services />} />
          <Route path="services/:serviceId" element={<ServiceDetails />} />
          <Route
            path="services/solution/:solutionId"
            element={<SolutionDetails />}
          />
          <Route path="community" element={<Community />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} />
          <Route element={<NotFound />} />
        </Route>
        <Route element={<SolhintLayout />}>
          <Route path="solhint" element={<Solhint />} />
        </Route>
      </Routes>
    </CallUserContext.Provider>
  );
}

export default App;
