import alejandro from "../assets/team/alejandro.png";

export const otherServicesData = [
  {
    title: "Smart Contract Development",
    link: "smart-contract",
    items: [
      "Ethereum Development",
      "Solana Development",
      "Stablecoin Development",
      "Auditing",
      "Benchmarking",
    ],
  },
  {
    title: "Decentralized Apps",
    link: "decentralized-apps",
    items: ["Apps", "DEXes", "NFTs", "UX & UI Design"],
  },
  {
    title: "Developer Tools",
    link: "developer-tools",
    items: [
      "SDKs",
      "APIs",
      "Toolkits",
      "Web & App Mobile Components",
      "Solhint",
      "Ethereum CLI",
    ],
  },
  {
    title: "Integrations",
    link: "integrations",
    items: [
      "Trusted Data Feeds (Oracles)",
      "Indexing and Subgraphs",
      "Dashboard, Network Monitor, Block Explorer",
    ],
  },
  {
    title: "DevOps",
    link: "https://devops.protofire.io/",
    isOuterLink: true,
    items: [
      "Node Infrastructure Management",
      "Automation in the Cloud",
      // "Staking",
      "RPC Nodes",
      "Dedicated DevOps",
      "Genesis Ark",
    ],
  },
  // {
  //   title: "Safe Deployment",
  //   link: "safe-deployment",
  //   items: [
  //     "Custom integrations of Safe Apps",
  //     "Turn-key deployment",
  //     "Maintenance of custom Safe forks",
  //   ],
  // },
  {
    title: "TVL Solutions",
    link: "tvl-solutions",
    items: [
      "Surveys",
      "TVL readiness assessment",
      "Oracle security",
      "Usage audits",
      "Threat assessment",
      "Infrastructure security audits",
      "Protofire Certification Badges",
    ],
  },
];

export const serviceDetailsMap = {
  "smart-contract": {
    metaTitle: "Smart Contract Development & Auditing Services | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Leverage Protofire's expertise in smart contract development, stablecoin creation, and auditing across Ethereum, Solana, and Cardano. From ideation to deployment, we ensure secure, efficient, and tailored blockchain solutions for your project.",
      },
      {
        name: "keywords",
        content:
          "smart contract development, blockchain solutions, Ethereum, Solana, Cardano, stablecoin development, smart contract audit, DApp development, blockchain consulting, smart contract security, decentralized applications, blockchain ideation, smart contract deployment, blockchain prototyping, cryptocurrency development",
      },
    ],
    title: "Smart Contract Development",
    description: [
      "Protofire develops smart contracts and stablecoins on various blockchains, including Ethereum, Solana, and Cardano. By 2024, our team has successfully deployed more than 170+ projects to many growing and well-established Web3 ecosystems.",
      "Additionally, we provide smart contract auditing services to ensure code quality and benchmarking opportunities, where we build forks to connect solutions to established blockchains.",
    ],
    providers: [
      {
        title: "Ideation",
        description:
          "We conceptualize and ideate your smart contract solution’s architecture, implementation, and productivity.",
      },
      {
        title: "Prototyping",
        description:
          "We build a solution prototype to show what it may look like and make necessary changes.",
      },
      {
        title: "Development",
        description:
          "We develop the solution based on the agreed prototype under your supervision, known schedule, and budget.",
      },
      {
        title: "Quality Assurance",
        description:
          "We perform multi-stage quality assurance procedures to ensure all tools work as expected.",
      },
      {
        title: "Deployment",
        description:
          "We deploy the solution to the designated environment under your control, following the technical requirements.",
      },
      {
        title: "Maintenance & Support",
        description:
          "We maintain the solution, the features, and the related infrastructure and will trigger you if certain decisions are required.",
      },
    ],
    opportunitiesTitle: "DApp Development Opportunities",
    opportunities: [
      "Smart contract development",
      "Auditing of functionality and security",
      "Auditing of architecture and optimization",
      "Benchmarking",
      "Stablecoin development",
      "User interface",
      "DApp blockchain apps",
      "Private blockchain platform",
      "Crypto funds development",
      "Technical consulting and support",
    ],
    tabSubservices: [
      {
        title: "Smart Contracts",
        description: [
          "A smart contract is an agreement between two or more parties stored on a blockchain, such as Ethereum, Solana, or Cardano. The agreement has predefined rules and conditions that are automatically executed when met, guaranteed by the consensus of the entire network. They cannot be altered unless approved by the network.",
        ],
        benefits: [
          "Transaction accuracy",
          "Transparency of processes",
          "Asset security",
          "Performance autonomy",
          "High efficiency and productivity",
          "Continuous storage",
          "Trustless operations",
        ],
      },
      {
        title: "Stablecoins",
        description: [
          "Stablecoins are cryptocurrencies that attempt to peg their market value to some external reference. They tend to be pegged to either a fiat currency such as the U.S. dollar, to commodities such as gold, to digital assets such as tokens, and some are completely uncollateralized – algorithmically defined. They achieve their price stability via collateralization (backing) or through algorithmic mechanisms of buying and selling the reference asset or its derivatives.",
        ],
        benefits: [
          "Tend to be of stable value compared to non-stable coins",
          "Asset-backed currencies",
          "Act as a bridge from fiat to crypto use",
          "Potentially regulated currency",
        ],
      },
      {
        title: "Auditing",
        description: [
          "The auditing process for a smart contract focuses on scrutiny of the code used for underwriting the terms and conditions in the smart contract. With the help of such an audit, smart contract developers can easily identify the vulnerabilities and bugs before the deployment of smart contracts.",
        ],
        benefits: [
          "Increased productivity and efficiency",
          "Reduced risk of security breaching",
          "Reduced risk of vulnerabilities",
          "Architecture and code optimization for the solution",
          "Greater system reliability resulting in higher trust and value",
        ],
      },
      {
        title: "Benchmarking",
        description: [
          "Benchmarking implies the utilization of existing open-source technologies to develop other solutions. In the case of an open-source protocol, the code is freely available to be forked. Blockchain forks are essentially a split in the blockchain network. This means that anyone can propose improvements and/or change the code and adapt it for different purposes.",
        ],
        benefits: [
          "A wide selection of codes to choose from for a solution",
          "The code has already been thoroughly tested and audited",
          "The code has proved to be effective and in high demand",
          "Rapid setup and deployment",
        ],
      },
    ],
    additionalContent: [
      {
        title: "Ethereum Development (Solidity)",
        description: [
          "Protofire has developed more than 100 projects in the Ethereum ecosystem, with dozens more under production. They were developed alongside several of the most respected protocols, such as Maker, Gnosis, Chainlink, The Graph, among other protocols. We have built long-term partnerships with them, and we intend to continue to help them grow their ecosystems.",
        ],
      },
      {
        title: "Solana Development",
        description: [
          "Solana offers a scalable platform for smart contract development, boasting faster and more cost-effective transactions than Ethereum while still maintaining a high level of decentralization. Protofire is equipped to deliver top-notch Solana development services.",
        ],
      },
      {
        title: "Cardano Development",
        description: [
          "Cardano is a science-based, peer-reviewed PoS project that provides unparalleled security and sustainability to decentralized applications and systems. Protofire's team is equipped with the skills and knowledge to deliver top-notch Cardano development services.",
        ],
      },
    ],
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  // ---------------------------------------------------------------------------

  "decentralized-apps": {
    metaTitle:
      "Decentralized Application (DApp) Development Services | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Expert DApp development services from Protofire. We build secure, scalable decentralized applications, DEXs, and NFT platforms. From ideation to deployment, we deliver custom blockchain solutions for your business needs.",
      },
      {
        name: "keywords",
        content:
          "decentralized applications, DApp development, blockchain solutions, DEX development, NFT platforms, smart contracts, decentralized finance, Web3 development, blockchain consulting, DeFi solutions, NFT marketplaces, decentralized exchanges, blockchain UI/UX design, crypto wallet development, blockchain infrastructure",
      },
    ],
    title: "Decentralized Application",
    description: [
      "Protofire develops decentralized applications, decentralized exchanges, non-fungible token platforms and marketplaces, and dashboards for decentralized applications, and provides UI and UX design services for solutions in production as well as to partner networks.",
    ],
    providers: [
      {
        title: "Ideation",
        description:
          "We conceptualize and ideate the architecture, implementation, and productivity of your dApp solution.",
      },
      {
        title: "Prototyping",
        description:
          "We build a solution prototype to show what it may look like and make necessary changes.",
      },
      {
        title: "Development",
        description:
          "We develop the solution based on the agreed prototype under your supervision, known schedule, and budget.",
      },
      {
        title: "Quality Assurance",
        description:
          "We carry out quality assurance procedures to ensure all features of the decentralized application work as intended.",
      },
      {
        title: "Deployment",
        description:
          "We deploy the dApp to the designated environment under your control, following the technical requirements.",
      },
      {
        title: "Maintenance & Support",
        description:
          "We maintain the dApp, its features, and related infrastructure and will trigger you if certain decisions are required.",
      },
    ],
    opportunitiesTitle: "DApp Development Opportunities",
    opportunities: [
      "Decentralized Finance",
      "DEX, Lending, Borrowing",
      "Swaps",
      // "Staking",
      "Trading",
      "Prediction Market",
      "Payments",
      // "Insurance",
      "Bridges",
      "Developer Tools",
      "NTFs",
      "Wallets",
      "Marketplace",
      "UX & UI Design",
      "Web & Mobile",
      "Dashboard",
      "Network Monito",
      "Block Explorer",
    ],
    tabSubservices: [
      {
        title: "DApps",
        description: [
          // "Decentralized applications, also known as dApps, are digital applications or programs that run on a blockchain or a peer-to-peer (P2P) network of computers instead of a single computer. Since they are decentralized, their controlling authorities are the algorithms defined on the underlying code. Decentralized applications can be developed for multiple purposes, such as gaming, finance, and social media. The areas of dApp use are widespread and include but are not limited to exchange, games, finance, development, storage, high-risk management, wallets, governance management, property, identity management, media, security, energy, insurance, and health.",
          "Decentralized applications, also known as dApps, are digital applications or programs that run on a blockchain or a peer-to-peer (P2P) network of computers instead of a single computer. Since they are decentralized, their controlling authorities are the algorithms defined on the underlying code. Decentralized applications can be developed for multiple purposes, such as gaming, finance, and social media. The areas of dApp use are widespread and include but are not limited to exchange, games, finance, development, storage, high-risk management, wallets, governance management, property, identity management, media, security, energy, and health.",
        ],
        useCases: [
          "Decentralized exchanges",
          "Financial transactions",
          "Microtransactions",
          "Decentralized marketplaces",
          "Clearing and settlement",
          "Supply chain tracking",
          "Property registers",
          "Digital rights",
          "NFTs (Collectibles)",
          "Voting",
          "Personal record",
          "Storage",
        ],
        benefits: [
          "Open-source technology at the core",
          "Decentralized environment",
          "Protocol-based backend",
          "High security of assets",
          "Fast transactions",
          "Anonymity opportunities",
          "Reliability of data records",
        ],
      },
      {
        title: "DEXes",
        description: [
          "A decentralized exchange, or DEX, is a cryptocurrency exchange that allows for direct peer-to-peer cryptocurrency transactions without an intermediary. Some examples of DEXes are Uniswap, Curve, and PancakeSwap. There are also centralized exchanges, or CEXes, which have an intermediary company to custody user funds, such as Binance, Coinbase, and Huobi.",
        ],
        useCases: [
          "Decentralized exchanges",
          "Asset management",
          "Analytics and risk management",
          "Compliance and KYC",
          "Decentralized organizations",
          "Derivatives and synthetic assets",
          // "Insurance",
          "Borrowing and lending",
          "Payment solutions",
          "Decentralized marketplaces",
        ],
        benefits: [
          "No counterparty risk",
          "An opportunity to trade thousands of tokens",
          "Finances are managed by individuals",
          "Portfolio tracking tools",
          "User and transaction anonymity opportunities",
        ],
      },
      {
        title: "NFTs",
        description: [
          "Non-fungible tokens, also known as NFTs, are financial securities that are digital data stored in a blockchain. NFTs are digital-only objects. The ownership over an NFT is recorded in a blockchain and can be transferred by the owner to another party, allowing NFTs to be traded.",
        ],
        useCases: [
          "Artists and content creators in general",
          "Medical records and identity verification",
          "Intellectual property and patents",
          "Academic credentials",
          "Supply chain tracking",
          "Artwork tracking",
          "Gaming industry",
          "Ticketing",
          "Voting",
        ],
        benefits: [
          "Decentralized marketplace for creators",
          "Unique items (Collectibles)",
          "Authenticity of items",
          "Ownership of items",
          "Transferability of items",
          "Immutability of information",
          "Copyright for content creators",
          "Security of the technology",
          "Reselling items for money-making purposes",
        ],
      },
      {
        title: "UX & UI Design",
        description: [
          "The goal of User Experience and User Interface design is to improve and simplify the usage of an application. In UX & UI design, we identify and solve issues that users may face in an app. UX design defines the user journey and experience in the product. UI design comes after UX design and involves the creation of user-friendly, aesthetically pleasing interactive interfaces.",
        ],
        benefits: [
          "Reduced development costs",
          "Optimized delivery time",
          "Improved customer acquisition",
          "Boosted customer retention and satisfaction",
          "Greater app productivity",
          "User engagement opportunities",
        ],
      },
      {
        title: "Dashboards",
        description: [
          "Dashboards are visual representations of data that provide critical information on network performance and security. Network monitors identify slow or failing components before they cause problems, while block explorers show the details of all transactions on a blockchain network. Keeping a close eye on these metrics helps users to make informed decisions and keep the network safe.",
        ],
        benefits: [
          "Maintain full network visibility",
          "Effectively allocate resources",
          "Identify security threats",
          "Deploy new technology",
          "Generate network performance reports",
          "Find performance issues",
        ],
      },
    ],
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  // ---------------------------------------------------------------------------

  "developer-tools": {
    metaTitle:
      "Blockchain Developer Tools | SDKs, APIs & Launch Kits | Protofire | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Accelerate your blockchain development with Protofire's custom SDKs, APIs, and launch kits. Our developer tools boost integration speed, enhance efficiency, and enable rapid deployment of Web3 projects.",
      },
      {
        name: "keywords",
        content:
          "blockchain developer tools, SDKs, APIs, launch kits, Web3 development, blockchain integration, Ethereum CLI, Solhint, decentralized exchange tools, blockchain APIs, smart contract development tools, DApp development, blockchain SDKs, Web3 APIs, blockchain prototyping",
      },
    ],
    title: "Developer Tools",
    description: [
      "Protofire builds SDKs, APIs, launch kits, web and mobile components, and other developer tools, which help to speed up app integration, increase development efficiency, and allow for faster deployment of better products. With these tools, ecosystems have greater control and reduced risks during the development process.",
    ],
    providers: [
      {
        title: "Ideation",
        description:
          "We conceptualize and ideate your development tool’s architecture, implementation, and productivity.",
      },
      {
        title: "Prototyping",
        description:
          "We build a tool prototype to show what it may look like and make necessary changes.",
      },
      {
        title: "Development",
        description:
          "We develop the tool based on the agreed prototype under your supervision, known schedule, and budget.",
      },
      {
        title: "Quality Assurance",
        description:
          "We carry out quality assurance procedures to ensure all features of the solution work as intended.",
      },
      {
        title: "Deployment",
        description:
          "We deploy your developer tool to the designated environment under your control, following the technical requirements.",
      },
      {
        title: "Maintenance & Support",
        description:
          "We maintain the solution, the features, and the related infrastructure and will trigger you if certain decisions are required.",
      },
    ],
    opportunitiesTitle: "Dev Tooling Development Opportunities",
    opportunities: [
      "SDKs",
      "APIs",
      "Launch Kits",
      "Web & App Mobile Components",
      "Solhint",
      "Ethereum CLI",
    ],
    tabSubservices: [
      {
        title: "SDKs",
        description: [
          "Software development kits (SDKs) are collections of software development tools compiled in one installable package. They facilitate the creation of applications by having a compiler, debugger, and software framework. SDKs are usually specific to a hardware platform and operating system combination.",
        ],
        benefits: [
          "Rapid integration",
          "Fast and cost-effective development",
          "High quality end products",
          "Full control over your application",
          "Reduced risk of failure",
        ],
      },
      {
        title: "DEXes",
        description: [
          "A decentralized exchange, or DEX, is a type of cryptocurrency exchange that allows for direct peer-to-peer cryptocurrency transactions without an intermediary. Some examples of DEXes are Uniswap, Curve, and PancakeSwap. There are also centralized exchanges, or CEXes, which happen to have an intermediary company to custody user funds, such as Binance, Coinbase, and Huobi.",
        ],
        benefits: [
          "No counterparty risk",
          "An opportunity to trade thousands of tokens",
          "Finances are managed by individuals",
          "Portfolio tracking tools",
          "User and transaction anonymity opportunities",
        ],
      },
      {
        title: "APIs",
        description: [
          "API is the acronym for Application Programming Interface, which is a software intermediary that allows two applications to talk to each other. Each time you use an app like Facebook, send an instant message or check the weather on your phone, you’re using an API.",
        ],
        benefits: [
          "Increased productivity",
          "Cost reduction",
          "Improved connectivity",
          "Innovation encouragement",
          "Enhanced customer experience",
          "Data collection for intelligence analytics",
          "Promotion of new product capabilities",
        ],
      },
      {
        title: "Toolkits",
        description: [
          "Toolkits, also known as Launch Kits, are software development frameworks that make writing-specific applications easy to use. A toolkit is a set of tools that makes it easier to build, launch, and manage applications. Toolkits encompass learning tools, ready-made app files, sample applications, workplace source code, and documentation.",
        ],
        benefits: [
          "Easy to use for rapid development",
          "Reduced development cost",
          "High quality of code",
          "High quality of implementation",
        ],
      },
      {
        title: "App Components",
        description: [
          "Application components are essential for building blocks of an app. Each component is an entry point through which the system or a user can enter an application. Some components are dependent on other components.",
        ],
        benefits: [
          "Improved app usability and user experience",
          "Higher responsiveness and performance ",
          "Automated processes",
          "Opportunities for task management",
          "Customer self-service",
          "Data collection, aggregation, and analysis",
        ],
      },
      {
        title: "Solhint",
        description: [
          "Developed by Protofire with Ethereum Foundation grants, Solhint is an advanced linter for detecting syntax-related security vulnerabilities in the Solidity-based code underlying smart contracts in Ethereum. This open-source solution provides security and style guide validation under a set of rules.",
          "Solhint is integrated seamlessly into most mainstream integrated development environments or IDEs. The solution is flexibly configurable and extendable while staying unobtrusive. Solhint’s user experience is similar to any other language linters. Solhint is an active project of Protofire, it is maintained and regularly updated with new features by the development team.",
        ],
        benefits: [
          "Style guide validation tools",
          "Shareable configuration",
          "Custom plugins",
          "Seamless integration in most IDEs",
          "Autofix support",
          "Open-source solution",
        ],
      },
      {
        title: "Ethereum CLI",
        description: [
          "Eth-cli is a collection of CLI tools and commands that help users to develop dApps for Ethereum and interact with contracts deployed in any blockchain network. Eth-cli helps users to learn the under-the-hood mechanisms of Ethereum and start developing right away. Eth-cli allows users to fetch data from the blockchain, start an interactive REPL connected to a node, and call methods on deployed contracts, all through a command line. This toolset enables users to interact with contracts in any network regardless of how they were initially deployed. The solution is under continuous development and feature list expansion.",
        ],
        benefits: [
          "An easy way to learn Ethereum development",
          "Easy to use and flexible ",
          "Can be applied to any contract on any blockchain",
        ],
      },
    ],
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  // ---------------------------------------------------------------------------

  integrations: {
    metaTitle: "Blockchain Integrations | Oracles & Subgraphs | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Seamlessly integrate your dApps with blockchain ecosystems using Protofire's expert oracle and subgraph services. We offer end-to-end solutions from ideation to deployment, ensuring robust connections between on-chain and off-chain data.",
      },
      {
        name: "keywords",
        content:
          "blockchain integrations, oracle services, subgraph development, dApp integration, Chainlink integration, The Graph Network, decentralized data feeds, smart contract connectivity, blockchain oracles, data indexing, Web3 integrations, DeFi protocol integration, cross-chain solutions, blockchain data bridges, decentralized infrastructure",
      },
    ],
    title: "Integrations",
    description: [
      "Since its foundation, Protofire has been collaborating with decentralized projects to help them land on any from the multitude of blockchains and ecosystems. We connect decentralized applications to protocols and DeFi networks through oracles and subgraphs.",
    ],
    providers: [
      {
        title: "Ideation",
        description:
          "We conceptualize and advise on the integration architecture, implementation, and productivity.",
      },
      {
        title: "Prototyping",
        description:
          "We create an integration prototype to show what it may look like and make necessary changes.",
      },
      {
        title: "Development",
        description:
          "We develop tools required for the project and set up the environment to fit them under your supervision, known schedule, and budget.",
      },
      {
        title: "Quality Assurance",
        description:
          "We carry out quality assurance procedures to ensure all features of an integration work as intended.",
      },
      {
        title: "Deployment",
        description:
          "We deploy the integration to the designated environment under your control, following technical requirements.",
      },
      {
        title: "Maintenance & Support",
        description:
          "We maintain the integration, its features, tools, and related infrastructure and will trigger you if certain decisions are required.",
      },
    ],
    opportunitiesTitle: " Integration Opportunities",
    opportunities: [
      "Trusted Data Feeds (Oracles)",
      "Indexing and Subgraphs",
      "Chainlink integration",
      "Gnosis Safe Apps - DEX integration to dashboard",
      "External integration that is added to the main protocol later",
      "Protocols in new chains",
    ],
    tabSubservices: [
      {
        title: "Oracles",
        description: [
          "Oracles are bridges between a blockchain and the outside world. Oracles provide a way for a decentralized ecosystem to access existing data sources, legacy systems, and advanced computations. They act as on-chain APIs you can query to get information into your smart contracts. This could be anything from price information to weather reports.",
        ],
        useCaseDescription: "Connect smart contracts to:",
        useCases: [
          "Other blockchains",
          "Off-chain computation",
          "Payment providers",
          "Price feeds",
          "Commodities data",
          "Weather data",
          "Medical data",
        ],
        benefits: [
          "Connect blockchain data with outside sources",
          "Provide data feeds",
          "End-to-end decentralization",
          "High-quality data",
          "Crypto-economic security",
          "Off-chain computation",
        ],
      },
      {
        title: "Subgraphs",
        description: [
          "Indexers are node operators in The Graph Network that stake The Graph tokens (GRT) to provide indexing and query processing services. Indexers earn query fees and indexing rewards for their services. Subgraphs define which data The Graph will index from Ethereum protocols, and how they will store them. Once subgraphs are deployed, they form a part of a global graph of blockchain data.",
        ],
        benefits: [
          "No downtime by relying on a network of Indexers to serve data",
          "A reduced central point of failure in infrastructure and service provider",
          "Innovate faster by building on open-source APIs",
          "Paying query fees only for data that you consume",
          "Ensure your dApp is built on a fully decentralized infrastructure",
          "Save on engineering costs, less redundant back-end and indexing work",
        ],
      },
    ],
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  // ---------------------------------------------------------------------------

  "dev-ops": {
    metaTitle: "Blockchain DevOps & Integration Services | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Expert blockchain DevOps, RPC node hosting, and integration services for Web3 projects. Optimize your blockchain infrastructure with Protofire's tailored solutions.",
      },
      {
        name: "keywords",
        content:
          "Blockchain DevOps, RPC node hosting, Web3 infrastructure, Blockchain integration",
      },
    ],
    title: "DevOps",
    description: [
      "The DevOps team of Protofire continuously supports development processes for Protofire and associates on all stages. The team has extensive experience in setup, configuration, automation, and maintenance of infrastructure in almost any known environment.",
    ],
    providers: [
      {
        title: "Designing infrastructure architecture",
        description:
          "We outline the idea, scope and requirements for your project and advise on effective adjustments.",
      },
      {
        title: "Prototyping",
        description:
          "We create a prototype of your solution or share a ready-made similar solution with you to try it and make necessary changes.",
      },
      {
        title: "Development (CI/CD pipelines and containerization)",
        description:
          "We develop the solution and set up its infrastructure under your supervision, known schedule, and budget.",
      },
      {
        title: "Network & Protocol bootstrapping",
        description:
          "We carry out quality assurance procedures to make sure all features of the solution work as intended.",
      },
      {
        title: "Monitoring configuration",
        description:
          "We deploy the solution to the designated environment under your control, following technical requirements.",
      },
      {
        title: "Maintenance & Support",
        description:
          "We maintain the solution and the infrastructure and will trigger you if certain decisions are required.",
      },
    ],
    opportunitiesTitle: "DevOps opportunities",
    opportunities: [
      "Automating the deployment process",
      "Improving and stabilizing infrastructure",
      "Automating the deployment",
      "Participating in the development",
      "Explaining how things should be implemented",
    ],
    tabSubservices: [
      {
        title: "Node Infrastructure Management",
        description: [
          "Nodes are critical aspects of blockchain security. A cryptocurrency node is a participant in a blockchain network. Node service providers run distributed node clients behind the scenes for you, so you don't have to. Some services offer you to run a dedicated node of your own, while others use load balancers to distribute activity across nodes. A node service must be easy to integrate with, involving one-line changes in your code to swap out your self-hosted node, or even switch between the services.",
        ],
        benefits: [
          "Ensuring faster deployment",
          "Stabilizing the work environment",
          "Significant improvement in product quality",
          "Automation in repetitive tasks",
          "Continuous delivery of software",
          "Fast and reliable problem-solving techniques",
        ],
      },
      {
        title: "Automation in the Cloud",
        description: [
          "Cloud automation refers to the implementation of tools and processes in order to reduce the manual intervention associated with provisioning, configuring, and managing public, private, and hybrid cloud environments.",
        ],
        benefits: [
          "Agility and business scalability",
          "Simplified processes",
          "Faster deployment and easy cloud management",
          "Reduced IT infrastructure costs",
          "Streamlined IT focus",
          "Improved network security and resilience",
        ],
      },
    ],
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  // ---------------------------------------------------------------------------

  "tvl-solutions": {
    metaTitle: "TVL Growth Solutions for DeFi Protocols | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Boost your DeFi protocol's Total Value Locked with Protofire's expert solutions. We offer security audits, risk assessments, and custom strategies to enhance TVL growth.",
      },
      {
        name: "keywords",
        content:
          "TVL growth, DeFi security, Liquidity provider solutions, Blockchain risk assessment, Smart contract audits",
      },
    ],
    title: "TVL Growth Solutions",
    description: [
      "TVL, or Total Value Locked, is a DeFi-vital metric used to measure the overall health of a DeFi protocol. Its calculation and growth are the key characteristics identifying the success of an ecosystem.",
      "To help protocols increase their TVL, Protofire develops solutions that positively influence the project’s growth.",
    ],
    descriptionExtra: [
      {
        title: "The problem behind TVL",
        description: [
          "High-net-worth individuals, funds, and big whales go for DeFi solutions that guarantee their funds and yields. At the same time, many investors are willing to invest $1M+. Most liquidity providers can increase their deposits by 2-10x.",
          "To address this challenge, your solution should demonstrate transparency, risk-mitigation measures, and risk-transfer options to evaporate the fear of high risk and uncertainty from the liquidity providers' minds.",
        ],
        points: {
          title: "Major risks in the DeFi space:",
          items: [
            "Smart contract logic errors",
            "Reentrancy attacks",
            "Oracle manipulation",
            "Web2/Web3 frontend exploits",
            "Rugpull risk",
            "Stablecoin depeg",
          ],
        },
      },
    ],
    providers: [
      {
        title: "Ideation",
        description:
          "We research the best TVL growth opportunities for your project and suggest architecture and deployment plans for your solution.",
      },
      {
        title: "Prototyping",
        description:
          "We create a solution prototype to show what it may look like and make necessary changes.",
      },
      {
        title: "Development",
        description:
          "We develop the TVL solution under your supervision, known schedule, and budget.",
      },
      {
        title: "Quality Assurance",
        description:
          "We carry out quality assurance procedures to ensure all features of the solution work as intended.",
      },
      {
        title: "Deployment",
        description:
          "We deploy the solution to the designated environment under your control, following technical requirements.",
      },
      {
        title: "Maintenance & Support",
        description:
          "We maintain the solution and the infrastructure and will trigger you if certain decisions are required.",
      },
    ],
    opportunitiesTitle: "TVL growth solution opportunities",
    opportunitiesDescription: [
      "Protofire can be your strategic partner to boost your TVL's exponential growth by improving security and providing a safer environment for liquidity providers. Surveys + TVL readiness assessment will help you to understand how to unlock TVL growth. Oracle security implementation and usage audits allow you to assess economic exploit threats and technical aspects of implementation. Comprehensive dApp infrastructure security audits for Dapp Infrastructure assess Web2/Web3 website frontend, network, and cloud infrastructure to protect your project from the most common attacks and threats. Protofire Certification Badges are given to projects upon audit completion in the form of NFTs (ERC-721) with the description of verification services provided.",
    ],
    opportunities: [
      "Surveys",
      "TVL readiness assessment",
      "Oracle security",
      "Usage audits",
      "Threat assessment",
      "Infrastructure security audits",
      "Protofire Certification Badges",
    ],
    tabSubservices: [
      {
        title: "Research of LP Behavior",
        description: [
          "Researching the behavior of liquidity providers, such as their unique needs, wants, pains, and fears can unlock the next wave of TVL growth. Your DAO core-Dev, Product, and Marketing teams will receive recommendations on how you can attract HNWI and institutions and increase deposits per LP along with TVL.",
        ],
        benefits: [
          "Develop and execute a survey",
          "Design and handle a rewards program for participants",
          "Support users in the process",
          "Conduct interviews",
          "Provide the Report Analysis including User profiles, Empathy Map, Recommendations",
        ],
      },
      {
        title: "Infra Audits & Certifications",
        description: ["The scope of the audit covers:"],
        items: [
          "Source code analysis of Web2/Web3 frontend (web pages and mobile apps)",
          "Analysis against OWASP top 10 vulnerabilitie",
          "Analysis of deployment scripts and repository structure",
          `Review of Dapp architecture, including integration between frontend 
          (web2/web3) and backend (blockchain)`,
          "Analysis of wallet integration methods",
          "Review of hosting infrastructure architecture (on-premises or cloud) and best practices usage:",
          "Review of network architecture and configuration",
          "Review of infrastructure deployment automation (IaC) scripts and scalability configuration",
        ],
        extraSubservice: {
          title: "Oracle Security Audit",
          description: ["The scope of the audit covers:"],
          items: [
            "Review of Oracle selection based on application requirements (functional and non-functional) and cost constraints",
            "Economic risk analysis: a) Availability impact (downtime, DDoS, redundancy, multiple sources) b) Timeliness impact (time to turn around, precision, dynamic fees) c) Reliability impact (trustworthiness of data, resilience to outliers) d) Social threats (front running, flash loans, Sybil attacks, etc.) e) Profit-from-Corruption (PfC) versus Cost-of-Corruption (CoC)",
            "Analysis of reliability and trustworthiness of data sources",
            "Analysis of DAO governance rules regarding Oracles: a) Consensus mechanism b) Oracle fees policy (static/dynamic, barriers to front running) c) Oracle whitelisting model d) Dynamic model (rounds, disputes, resolution, Commit/Reveal scheme, etc.)",
            "Optional analysis of source code and centralized infrastructure (technical analysis)",
          ],
        },
      },
      {
        // title: "Insurance Pool & Safety Module",
        title: "Safety Module",
        description: [
          "Most DeFi users are concerned about exposure to the risks of first-party smart contract hacks, stablecoin depeg, or third-party attacks against smart contracts, oracle and bridge attacks, etc.",
          // "Protofire can enable insured deposits for users where Premiums are automatically calculated, based on the market price (% of the deposits to be insured, and % of the amount for payment). Deposits will be insured by the underwriting capital of liquidity providers of At_REPLACE_omica DAO, an algorithmic protocol built for DeFi protocols and investors to launch their protection cover markets, offer protection to their users, or earn a yield on their capital.",
          "Protofire can enable insured deposits for users where Premiums are automatically calculated, based on the market price (% of the deposits to be insured, and % of the amount for payment).",
          "Protofire is ready to create multiple risk pools to protect all kinds of markets, where liquidity providers can provide liquidity, earn incentives/yields, and guarantee the markets.",
          // "Protofire can enable insured deposits for users. Premiums are automatically calculated, based on the market price (% of the deposits to be insured, and % of the amount for payment). Deposits will be insured by the underwriting capital of liquidity providers of At_REPLACE_omica DAO, an algorithmic protocol built for DeFi protocols and investors to launch their protection cover markets, offer protection to their users, or earn yield on their capital. We can create multiple risk pools to protect all kinds of markets, where LP can provide liquidity, earn incentives/yields, and guarantee those markets.",
          "Protofire can enable insured deposits for users. Premiums are automatically calculated, based on the market price (% of the deposits to be insured, and % of the amount for payment).",
        ],
        benefits: [],
      },
    ],
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },
};
