type CardProps = {
  title: string;
  text: string;
};

function Card({ title, text }: CardProps) {
  return (
    <div
      className="p-6 rounded-lg xl:w-full"
      style={{
        background:
          "linear-gradient(90deg, rgba(241, 248, 255, 0.05) 0%, rgba(145, 149, 153, 0) 100%)",
      }}
    >
      <p
        className="m-0 text-2lg xl:text-[32px] xl:leading-[40px] text-white100"
        style={{
          fontFamily: "Onest-Bold",
        }}
      >
        {title}
      </p>
      <p className="mt-0.5 mb-0 text-base md:text-lg xl:text-2lg text-white55">
        {text}
      </p>
    </div>
  );
}

function Metrics() {
  return (
    <div className="mt-[56px] grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-2 md:gap-4 xl:gap-8">
      <Card title="201+" text="Projects" />
      <Card title="60+" text="Networks" />
      <Card title="85+" text="Engineers" />
      <Card title="17+" text="Countries" />
      <Card title="4 000+" text="Github Stars" />
    </div>
  );
}

export default Metrics;
