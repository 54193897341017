import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import { projectDetailsMap } from "./data";
import { ReactComponent as Lamp } from "../assets/projects/lamp.svg";
import { ReactComponent as PaperClip } from "../assets/projects/paperClip.svg";
import BookCallWith from "../common/bookCallWith";
import SliderCarousel from "../common/sliderCarousel";
import projectDetailsBg1 from "../assets/projects/details/bgMain1Gen.png";
import projectDetailsBg1Tablet from "../assets/projects/details/bgMain1GenTablet.png";
import projectDetailsBg2 from "../assets/projects/details/bgSecondary1.png";
import projectDetailsBg3 from "../assets/projects/details/bgMain2Gen.png";
import { ReactComponent as ChevronRightDouble } from "../assets/services/chevronRightDoubleOrange.svg";

function ProjectDetails() {
  const { projectId } = useParams();
  const projectDetails = projectDetailsMap[projectId as string];
  const isMobile = useMediaQuery({ query: "(max-width: 833px)" });
  const isXLScreen = useMediaQuery({ query: "(min-width: 1440px)" });
  return (
    <div
      className="pb-[150px] bg-no-repeat bg-right-top bg-[length:auto_1100px]"
      style={{
        backgroundImage: isMobile
          ? "none"
          : `url(${isXLScreen ? projectDetailsBg1 : projectDetailsBg1Tablet})`,
      }}
    >
      <Helmet>
        {projectDetails.metaTitle && <title>{projectDetails.metaTitle}</title>}
        {projectDetails.meta?.map((metaItem) => (
          <meta
            name={metaItem.name}
            key={metaItem.content}
            content={metaItem.content}
          />
        ))}
      </Helmet>
      {projectDetails ? (
        <div>
          <div
            className="bg-no-repeat bg-top bg-auto xl:bg-[length:100%_650px]"
            style={{ backgroundImage: `url(${projectDetailsBg2})` }}
          >
            <div className="max-w-[1120px] mx-auto pt-[80px]">
              <section className="mx-4 md:mx-6 xl:mx-0">
                <div className="pt-[100px] md:pt-[120px] text-white100 flex flex-col xl:flex-row gap-[56px] xl:gap-[80px]">
                  <div className="flex-1 md:max-w-[544px]">
                    {projectDetails.tagList && (
                      <div className="flex gap-2 mb-[56px] flex-wrap">
                        {projectDetails.tagList.map((tag: string) => (
                          <div
                            key={tag}
                            className="bg-white15 text-sm rounded-full px-4 py-2.5"
                            style={{ backdropFilter: "blur(56px)" }}
                          >
                            {tag}
                          </div>
                        ))}
                      </div>
                    )}
                    <h1
                      className="text-[32px] leading-[40px] md:text-5xl md:leading-[56px] xl:text-[64px] xl:leading-[72px]"
                      style={{ fontFamily: "Onest-SemiBold" }}
                    >
                      {projectDetails.title}
                    </h1>
                    {projectDetails.description.map((desc: string) => (
                      <p
                        key={desc}
                        style={{ fontFamily: "Onest-Light" }}
                        className="mt-6 mb-0 text-white100 text-base md:text-lg"
                      >
                        {desc}
                      </p>
                    ))}
                  </div>
                  {projectDetails.mainImage && (
                    <div
                      className="flex-1 bg-no-repeat bg-left bg-cover min-h-[400px] h-[400px] md:h-[516px] rounded-l-lg rounded-r-none md:rounded-r-lg xl:rounded-r-none"
                      style={{
                        backgroundImage: `url(${projectDetails.mainImage})`,
                      }}
                    />
                  )}
                </div>
              </section>

              <div className="my-[120px] md:my-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />
            </div>
          </div>
          <div className="max-w-[1120px] mx-auto">
            <section className="mx-4 md:mx-6 xl:mx-0 xl:max-w-[832px]">
              <h2
                style={{ fontFamily: "Onest-SemiBold" }}
                className="text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px] text-white100"
              >
                About the Project
              </h2>
              {projectDetails.about?.map((aboutItem: string) => (
                <p
                  key={aboutItem}
                  className="mt-4 mb-0 text-white55 text-base xl:text-lg"
                  dangerouslySetInnerHTML={{ __html: aboutItem }}
                />
              ))}
              {projectDetails.aboutItems ? (
                <ul className="mt-4">
                  {projectDetails.aboutItems.map((item) => (
                    <li
                      className="text-base mt-2 flex items-center text-white55"
                      key={item}
                    >
                      <ChevronRightDouble className="mr-3 min-w-[24px]" />
                      <span dangerouslySetInnerHTML={{ __html: item }} />
                    </li>
                  ))}
                </ul>
              ) : (
                ""
              )}
              {projectDetails.aboutExtra ? (
                <div className="mt-8">
                  {projectDetails.aboutExtra.about?.map((aboutItem: string) => (
                    <p
                      key={aboutItem}
                      className="mt-4 mb-0 text-white55 text-base xl:text-lg"
                    >
                      {aboutItem}
                    </p>
                  ))}
                  <ul className="mt-4">
                    {projectDetails.aboutExtra.aboutItems?.map((item) => (
                      <li
                        className="text-base mt-2 flex items-center text-white55"
                        key={item}
                      >
                        <ChevronRightDouble className="mr-3 min-w-[24px]" />
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                ""
              )}
            </section>
            {projectDetails.metrics && (
              <div className="text-white100 gap-4 md:gap-8 grid grid-cols-1 md:grid-cols-4 my-[120px] md:my-[150px] min-w-full mx-4 md:mx-6 xl:mx-0">
                {projectDetails.metrics.map((metric) => (
                  <div key={metric.value} className="w-full">
                    <h4 className="text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]">
                      {metric.value}
                    </h4>
                    <p className="text-white55">{metric.text}</p>
                  </div>
                ))}
              </div>
            )}
          </div>

          {projectDetails.images ? (
            <section className="mx-4 md:mx-6 xl:mx-0 my-[50px]">
              <SliderCarousel images={projectDetails.images} />
            </section>
          ) : (
            ""
          )}

          <div className="max-w-[1120px] mx-auto">
            <div className="my-[120px] md:my-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />

            <section className="mx-4 md:mx-6 xl:mx-0">
              {projectDetails.additionalContent?.map((item, index) => (
                <div
                  className={`flex flex-col md:flex-row gap-6 ${
                    index !== projectDetails.additionalContent.length - 1
                      ? "border-b border-dashed border-white15 pb-10"
                      : ""
                  } ${index > 0 ? "pt-10" : ""}`}
                  key={item.title}
                >
                  <div className="text-lg text-white100 flex-[3]">
                    {item.title}
                  </div>
                  <div className="flex-[5] flex flex-col gap-8">
                    {item.description?.length ? (
                      <>
                        {item.description.map((descrItem) => (
                          <p
                            key={descrItem}
                            className="py-0 text-white55 text-base"
                            dangerouslySetInnerHTML={{ __html: descrItem }}
                          />
                        ))}
                      </>
                    ) : (
                      ""
                    )}

                    {item.items?.length ? (
                      <ul className={item.description?.length ? "-mt-4" : ""}>
                        {item.items.map((listItem) => (
                          <li
                            className="text-base flex items-center text-white55 mt-2"
                            key={listItem.items ? listItem.title : listItem}
                          >
                            {listItem.items ? (
                              <div>
                                <div className="flex items-center">
                                  <ChevronRightDouble className="mr-3 min-w-[24px]" />
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: listItem.title,
                                    }}
                                  />
                                </div>
                                <ul>
                                  {listItem.items.map((subItem) => (
                                    <li
                                      key={`${listItem.title}-${listItem.title}`}
                                      className="text-base flex items-center text-white55 mt-2 pl-8"
                                    >
                                      {subItem.items ? (
                                        <div>
                                          <div className="flex items-center">
                                            <ChevronRightDouble className="mr-3 min-w-[24px]" />
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: subItem.title,
                                              }}
                                            />
                                          </div>
                                          <ul>
                                            {subItem.items.map((subSubItem) => (
                                              <li
                                                key={`${listItem.title}-${listItem.title}-${listItem.title}`}
                                                className="text-base flex items-center text-white55 mt-2 pl-8"
                                              >
                                                <ChevronRightDouble className="mr-3 min-w-[24px]" />
                                                <span
                                                  dangerouslySetInnerHTML={{
                                                    __html: subSubItem,
                                                  }}
                                                />
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      ) : (
                                        <>
                                          <ChevronRightDouble className="mr-3 min-w-[24px]" />
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: subItem,
                                            }}
                                          />
                                        </>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ) : (
                              <>
                                <ChevronRightDouble className="mr-3 min-w-[24px]" />
                                <span
                                  dangerouslySetInnerHTML={{ __html: listItem }}
                                />
                              </>
                            )}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      ""
                    )}
                    {item.descriptionExtra && (
                      <>
                        {item.descriptionExtra.text.map((descrItem) => (
                          <p
                            key={descrItem}
                            className="py-0 text-white55 text-base"
                            dangerouslySetInnerHTML={{ __html: descrItem }}
                          />
                        ))}
                        {item.descriptionExtra.items?.length ? (
                          <ul
                            className={
                              item.descriptionExtra.text?.length ? "-mt-4" : ""
                            }
                          >
                            {item.descriptionExtra.items.map((listItem) => (
                              <li
                                className="text-base flex items-center text-white55 mt-2"
                                key={listItem}
                              >
                                <ChevronRightDouble className="mr-3 min-w-[24px]" />
                                <span>{listItem}</span>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}

              {projectDetails.extraProjects?.map(
                (extraProject, extraProjectIndex) => (
                  <div
                    className={`mt-[56px] ${
                      extraProjectIndex !==
                      projectDetails.extraProjects.length - 1
                        ? "border-b border-dashed border-white15 pb-10 mb-16"
                        : ""
                    } ${extraProjectIndex > 0 ? "pt-10" : ""}`}
                  >
                    {extraProject.title && (
                      <h3
                        style={{ fontFamily: "Onest-SemiBold" }}
                        className="text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px] text-white100 mb-[100px]"
                      >
                        {extraProject.title}
                      </h3>
                    )}
                    {extraProject.items?.map((item, index) => (
                      <div
                        className={`flex flex-col md:flex-row gap-6 mt-[56px] ${
                          index !== extraProject.items.length - 1
                            ? "border-b border-dashed border-white15 pb-16"
                            : ""
                        } ${index > 0 ? "xl:pt-10" : ""}`}
                        key={item.title}
                      >
                        <div className="text-lg text-white100 flex-[3]">
                          {item.title}
                        </div>
                        <div className="flex-[5] flex flex-col gap-8">
                          {item.description?.length ? (
                            <>
                              {item.description.map((descrItem) => (
                                <p
                                  key={descrItem}
                                  className="py-0 text-white55 text-base"
                                  dangerouslySetInnerHTML={{
                                    __html: descrItem,
                                  }}
                                />
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                          {item.items?.length ? (
                            <ul
                              className={
                                item.description?.length ? "-mt-4" : ""
                              }
                            >
                              {item.items.map((listItem) => (
                                <li
                                  className="text-base flex items-center text-white55 mt-2"
                                  key={listItem}
                                >
                                  <ChevronRightDouble className="mr-3 min-w-[24px]" />
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: listItem,
                                    }}
                                  />
                                </li>
                              ))}
                            </ul>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )
              )}
            </section>

            {projectDetails.technologyStack && (
              <>
                <div className="my-[120px] md:my-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />

                <section className="mx-4 md:mx-6 xl:mx-0">
                  <h2
                    style={{ fontFamily: "Onest-SemiBold" }}
                    className="text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px] text-white100"
                  >
                    Technology Stack
                  </h2>
                  {projectDetails.technologyStack.description?.map((item) => (
                    <p
                      className="mt-4 py-0 text-base xl:text-lg text-white55 xl:max-w-[832px]"
                      key={item}
                    >
                      {item}
                    </p>
                  ))}

                  <div className="mt-[100px] flex flex-col md:flex-row gap-8">
                    {projectDetails.technologyStack.items.map(
                      (techItem, outerIndex) => (
                        <div key={techItem.title} className="flex-1">
                          {techItem.title ? (
                            <h4 className="text-lg text-white100">
                              {techItem.title}
                            </h4>
                          ) : (
                            ""
                          )}
                          <div className="flex flex-col gap-4 mt-8">
                            {techItem.items.map((stackItem, innerIndex) => (
                              <div
                                key={stackItem}
                                className="text-white100 text-base p-4 rounded-full flex items-center gap-4"
                                style={{
                                  background:
                                    (outerIndex + innerIndex) % 2 === 0
                                      ? "linear-gradient(90deg, rgba(255, 255, 255, 0.16) 0%, rgba(153, 153, 153, 0) 100%)"
                                      : "linear-gradient(90deg, rgba(153, 153, 153, 0) 0%, rgba(255, 255, 255, 0.16) 100%)",
                                }}
                              >
                                <Lamp className="px-1.5 w-8 h-8 -mt-0.5" />
                                <span>{stackItem}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </section>
              </>
            )}
          </div>
          <div
            className="bg-no-repeat bg-left-top bg-contain"
            style={{
              backgroundImage:
                projectDetails.mediaData || projectDetails.codeData
                  ? `url(${projectDetailsBg3})`
                  : "none",
            }}
          >
            <div className="max-w-[1120px] mx-auto">
              {(projectDetails.mediaData || projectDetails.codeData) && (
                <div className="mt-[120px] md:mt-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />
              )}

              <section className="mx-4 md:mx-6 xl:mx-0">
                {projectDetails.codeData && (
                  <div className="mt-[120px] md:mt-[150px]">
                    <h2
                      style={{ fontFamily: "Onest-SemiBold" }}
                      className="text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px] text-white100"
                    >
                      Code
                    </h2>
                    {projectDetails.codeData?.description?.map((item) => (
                      <p
                        className="mt-4 py-0 text-base xl:text-lg text-white55 xl:max-w-[832px]"
                        key={item}
                      >
                        {item}
                      </p>
                    ))}
                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 mt-[100px]">
                      {projectDetails.codeData.items.map((item) => (
                        <div
                          key={item.title}
                          className="text-base text-white100 flex items-center gap-4"
                        >
                          <PaperClip className="px-1.5 w-[32px] h-[32px] min-w-[32px] min-h-[32px]" />
                          <a href={item.link} target="_blank" rel="noreferrer">
                            <span className="underline underline-offset-1">
                              {item.title}
                            </span>
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {projectDetails.mediaData && (
                  <div className="mt-[120px] md:mt-[150px]">
                    <h2
                      style={{ fontFamily: "Onest-SemiBold" }}
                      className="text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px] text-white100"
                    >
                      Success Stories & Media
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 mt-[100px]">
                      {projectDetails.mediaData.items.map((item) => (
                        <div
                          key={item.title}
                          className="text-base text-white100 flex items-center gap-4"
                        >
                          <PaperClip className="px-1.5 w-[32px] h-[32px] min-w-[32px] min-h-[32px]" />
                          <a href={item.link} target="_blank" rel="noreferrer">
                            <span className="underline underline-offset-1">
                              {item.title}
                            </span>
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </section>

              <div className="my-[120px] md:my-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />

              {projectDetails.contact ? (
                <section className="mx-4 md:mx-6 xl:mx-0">
                  {projectDetails.bottomSlogan ? (
                    <div className="xl:max-w-[832px]">
                      <h2
                        style={{ fontFamily: "Onest-SemiBold" }}
                        className="text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px] text-white100"
                      >
                        {projectDetails.bottomSlogan}
                      </h2>
                      <p className="mt-4 py-0 text-base xl:text-lg text-white55">
                        Get in touch with us so that we can discuss your ideas.
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  <BookCallWith
                    className="mt-[100px]"
                    imgUrl={projectDetails.contact.image}
                    name={projectDetails.contact.name}
                    lnUrl={projectDetails.contact.lnUrl}
                    calendlyCallPostfix={
                      projectDetails.contact.calendlyCallPostfix
                    }
                    text={projectDetails.contact.text || undefined}
                  />
                </section>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="max-w-[1120px] mx-auto pt-[80px]">
          <h1 className="pt-[100px] md:pt-[120px] text-white100 text-5xl">
            No project found
          </h1>
        </div>
      )}
    </div>
  );
}

export default ProjectDetails;
