import { useContext } from "react";
import Button from "../button/Button";
import { CallUserContext } from "../../../store";
import { ReactComponent as InIcon } from "../../assets/images/inIcon.svg";
import bookCalendlyCall from "../../../utils/bookCalendlyCall";

/* eslint-disable react/require-default-props */
type BookCallWithProps = {
  className?: string;
  imgUrl: string;
  lnUrl: string;
  name: string;
  calendlyCallPostfix?: string;
  text?: string;
};

function BookCallWith({
  className,
  imgUrl,
  lnUrl,
  name,
  calendlyCallPostfix,
  text = "Schedule a call with our CTO to receive practical recommendations and a prompt proposal for upgrading your solution.",
}: BookCallWithProps) {
  const context = useContext(CallUserContext);
  const handleBookCallClick = () => {
    context?.setCallUser(name);
    bookCalendlyCall(calendlyCallPostfix);
  };

  return (
    <div
      className={`flex flex-col md:flex-row gap-8 p-8 rounded-xl ${className}`}
      style={{
        background:
          "linear-gradient(180deg, rgba(153, 153, 153, 0) 0%, rgba(255, 255, 255, 0.08) 100%)",
      }}
    >
      <div
        className="min-h-[184px] min-w-[184px] max-h-[184px] max-w-[184px] bg-center bg-no-repeat bg-cover rounded-full"
        style={{
          backgroundImage: `url(${imgUrl})`,
        }}
      />
      <div>
        <h4 className="text-white100 text-2xl md:text-[32px] md:leading-[40px]">
          Book a call with <span className="text-orange100">{name}</span>
        </h4>
        <p className="text-base md:text-lg text-white55 p-0 mt-3">{text}</p>
        <div className="mt-6 flex flex-col md:flex-row gap-2">
          <Button size="lg" onClick={handleBookCallClick}>
            Book a call
          </Button>
          <a
            href={`${lnUrl}`}
            target="_blank"
            rel="noreferrer"
            className="flex"
          >
            <Button
              variant="outlined"
              className="bg-white5 flex justify-center items-center min-w-[96px] min-h-[56px] w-full md:w-auto"
            >
              <InIcon className="h-4" />
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default BookCallWith;
