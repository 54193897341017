import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="bg-not-found-bg-xs md:bg-not-found-bg-md xl:bg-not-found-bg-xl bg-no-repeat bg-cover">
      <Helmet>
        <title>404 Page not found </title>
      </Helmet>
      <main className="relative z-1 max-w-[1120px] mx-auto pt-[80px]">
        <section
          className="mx-4 md:mx-6 xl:mx-0 text-white100"
          style={{ fontFamily: "Onest-SemiBold" }}
        >
          <div className="md:max-w-[832px] xl:max-w-[754px] pt-[100px] md:pt-[120px] text-[40px] leading-[48px] md:text-[64px] md:leading-[72px] flex flex-col md:flex-row">
            <h1 className="mr-4">404</h1>
            <h1>Page not found</h1>
          </div>
          <p
            className="mt-6 mb-0 p-0 md:max-w-[832px] text-base md:text-lg"
            style={{ fontFamily: "Onest-Light" }}
          >
            It seems you&apos;re lost or there is some internal mistake. If
            you&apos;re lost, please feel free to visit our{" "}
            <Link
              to="/"
              className="underline underline-offset-2 text-orange100"
            >
              homepage
            </Link>
            , lurk around our{" "}
            <Link
              to="/services"
              className="underline underline-offset-2 text-orange100"
            >
              services
            </Link>{" "}
            or join our{" "}
            <Link
              to="/community"
              className="underline underline-offset-2 text-orange100"
            >
              community
            </Link>
          </p>
          <p
            className="py-20 p-0 md:max-w-[832px] text-base md:text-lg"
            style={{ fontFamily: "Onest-Light" }}
          >
            If you think we&apos;re hiding the information you were looking for,
            we can fix this in seconds. Just send us an email at{" "}
            <a
              href="mailto:contact@protofire.io"
              className="underline underline-offset-2 text-orange100"
            >
              contact@protofire.io
            </a>
          </p>
        </section>
        <div className="pt-[120px] md:pt-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />
      </main>
    </div>
  );
}

export default NotFound;
