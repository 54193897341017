import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import { solutionDetailsMap } from "./data";
import { ReactComponent as Lamp } from "../assets/projects/lamp.svg";
import { ReactComponent as PaperClip } from "../assets/projects/paperClip.svg";
import BookCallWith from "../common/bookCallWith";
import solutionBg1 from "../assets/services/bgMain1Gen.png";
import solutionBg2 from "../assets/services/bgMain2.png";
import solutionBg3 from "../assets/services/bgSolutionMain2.png";
import solutionBg4 from "../assets/services/bgSolutionMain3.png";
import { ReactComponent as ChevronRightDouble } from "../assets/services/chevronRightDoubleOrange.svg";

function SolutionDetails() {
  const { solutionId } = useParams();
  const solutionDetails = solutionDetailsMap[solutionId as string];
  const isXLScreen = useMediaQuery({ query: "(min-width: 1440px)" });
  return (
    <div
      className="pb-[150px] bg-no-repeat bg-[length:auto_1100px]"
      style={{
        backgroundImage: `url(${solutionBg1})`,
      }}
    >
      <Helmet>
        {solutionDetails.metaTitle && (
          <title>{solutionDetails.metaTitle}</title>
        )}
        {solutionDetails?.meta?.map((metaItem) => (
          <meta
            name={metaItem.name}
            key={metaItem.content}
            content={metaItem.content}
          />
        ))}
      </Helmet>
      {solutionDetails ? (
        <div>
          <div
            className="bg-no-repeat bg-auto bg-right-top min-h-[460px]"
            style={{
              backgroundImage: isXLScreen ? `url(${solutionBg2})` : "none",
            }}
          >
            <div className="max-w-[1120px] mx-auto pt-[80px]">
              <section className="mx-4 md:mx-6 xl:mx-0">
                <div className="pt-[100px] md:pt-[120px] text-white100 md:max-w-[544px]">
                  <h1
                    className="text-[32px] leading-[40px] md:text-5xl md:leading-[56px] xl:text-[64px] xl:leading-[72px]"
                    style={{ fontFamily: "Onest-SemiBold" }}
                  >
                    {solutionDetails.title}
                  </h1>
                  {solutionDetails.description.map((desc: string) => (
                    <p
                      key={desc}
                      style={{ fontFamily: "Onest-Light" }}
                      className="mt-6 mb-0 text-white100 text-base md:text-lg"
                    >
                      {desc}
                    </p>
                  ))}
                </div>
              </section>

              <section className="mx-4 md:mx-6 xl:mx-0">
                {solutionDetails.customers ? (
                  <div className="pt-[120px] md:pt-[150px] mb-4 text-white55 text-base">
                    <h4
                      className="text-lg text-white100"
                      style={{ fontFamily: "Onest-SemiBold" }}
                    >
                      Customers
                    </h4>
                    {solutionDetails.customers.join(", ")}
                  </div>
                ) : (
                  ""
                )}
                <div className="flex flex-col md:flex-row md:gap-8 text-white55 text-base mt-[56px]">
                  <div className="flex-1 md:flex-[2]">
                    <p
                      className="mt-0 mb-4 text-lg text-white100"
                      style={{ fontFamily: "Onest-SemiBold" }}
                    >
                      Node:
                    </p>
                    <p>{solutionDetails.node}</p>
                  </div>
                  {solutionDetails.learnMore && (
                    <div className="mt-[56px] md:mt-0 flex-1 md:flex-[5]">
                      <p
                        className="mb-4 text-lg text-white100"
                        style={{ fontFamily: "Onest-SemiBold" }}
                      >
                        Learn more
                      </p>
                      <p>
                        <span>Learn more about </span>
                        <a
                          href={solutionDetails.learnMore.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="underline underline-offset-1 text-white100">
                            {solutionDetails.learnMore.text}
                          </span>
                        </a>
                        {solutionDetails.learnMoreAdditional && (
                          <p>
                            <span>Learn more about </span>
                            <a
                              href={solutionDetails.learnMoreAdditional.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span className="underline underline-offset-1 text-white100">
                                {solutionDetails.learnMoreAdditional.text}
                              </span>
                            </a>
                          </p>
                        )}
                      </p>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>

          <div
            className="bg-no-repeat bg-right-top bg-[length:auto_900px]"
            style={{
              backgroundImage: `url(${solutionBg3})`,
            }}
          >
            <div className="max-w-[1120px] mx-auto">
              <div className="my-[120px] md:my-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />
              <section className="mx-4 md:mx-6 xl:mx-0">
                {solutionDetails.additionalContent?.map((item, index) => (
                  <div
                    className={`flex flex-col md:flex-row gap-6 ${
                      index !== solutionDetails.additionalContent.length - 1
                        ? "border-b border-dashed border-white15 pb-10"
                        : ""
                    } ${index > 0 ? "pt-10" : ""}`}
                    key={item.title}
                  >
                    <div className="text-lg text-white100 flex-[3]">
                      {item.title}
                    </div>
                    <div className="flex-[5] flex flex-col gap-8">
                      {item.description?.map((descrItem) => (
                        <p
                          key={descrItem}
                          className="py-0 text-white55 text-base"
                          dangerouslySetInnerHTML={{ __html: descrItem }}
                        />
                      ))}
                      {item.items?.length ? (
                        <ul className={item.description?.length ? "-mt-4" : ""}>
                          {item.items.map((listItem) =>
                            listItem.subItems ? (
                              <>
                                <li
                                  className="text-base flex items-center text-white55 mt-2"
                                  key={listItem.title}
                                >
                                  <ChevronRightDouble className="mr-3 min-w-[24px]" />
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: listItem.title,
                                    }}
                                  />
                                </li>
                                <ul className="mb-4">
                                  {listItem.subItems.map((subListItem) => (
                                    <li
                                      className="text-base flex items-center text-white55 mt-2 pl-8"
                                      key={subListItem}
                                    >
                                      <ChevronRightDouble className="mr-3 min-w-[24px]" />
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: subListItem,
                                        }}
                                      />
                                    </li>
                                  ))}
                                </ul>
                              </>
                            ) : (
                              <li
                                className="text-base flex items-center text-white55 mt-2"
                                key={listItem}
                              >
                                <ChevronRightDouble className="mr-3 min-w-[24px]" />
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: listItem,
                                  }}
                                />
                              </li>
                            )
                          )}
                        </ul>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ))}
              </section>
              <div className="mt-[120px] md:mt-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />
            </div>
          </div>

          <div
            className="bg-no-repeat bg-left bg-[length:auto_1000px]"
            style={{
              backgroundImage:
                solutionDetails.mediaData || solutionDetails.codeData
                  ? `url(${solutionBg4})`
                  : "none",
            }}
          >
            <div className="max-w-[1120px] mx-auto">
              <section className="mx-4 md:mx-6 xl:mx-0">
                {solutionDetails.technologyStack ? (
                  <>
                    <h2
                      style={{ fontFamily: "Onest-SemiBold" }}
                      className="mt-[120px] md:mt-[150px]  text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px] text-white100"
                    >
                      Technology stack
                    </h2>

                    <div
                      className={`mt-[100px] grid grid-cols-1 ${
                        solutionDetails.technologyStack?.items.length > 2
                          ? "md:grid-cols-3 xl:flex"
                          : "md:flex"
                      } gap-8`}
                    >
                      {solutionDetails.technologyStack.items.map(
                        (techItem, outerIndex) => (
                          <div key={techItem.title} className="flex-1">
                            <h4 className="text-lg text-white100">
                              {techItem.title}
                            </h4>
                            <div className="flex flex-col gap-4 mt-8">
                              {techItem.items.map((stackItem, innerIndex) => (
                                <div
                                  key={stackItem}
                                  className="text-white100 text-base p-4 rounded-full flex items-center gap-4"
                                  style={{
                                    background:
                                      (outerIndex + innerIndex) % 2 === 0
                                        ? "linear-gradient(90deg, rgba(255, 255, 255, 0.16) 0%, rgba(153, 153, 153, 0) 100%)"
                                        : "linear-gradient(90deg, rgba(153, 153, 153, 0) 0%, rgba(255, 255, 255, 0.16) 100%)",
                                  }}
                                >
                                  <Lamp className="px-1.5 w-8 h-8 -mt-0.5" />
                                  <span>{stackItem}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}

                {solutionDetails.codeData && (
                  <div className="pt-[120px] md:pt-[150px]">
                    {solutionDetails.technologyStack && (
                      <div className="pb-[120px] md:pb-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />
                    )}
                    <h2
                      style={{ fontFamily: "Onest-SemiBold" }}
                      className="text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px] text-white100"
                    >
                      Code
                    </h2>
                    <div className="grid grid-cols-1 md:flex md:flex-wrap xl:grid xl:grid-cols-4 gap-8 mt-[100px]">
                      {solutionDetails.codeData.items.map((item) => (
                        <div
                          key={item.title}
                          className="text-base text-white100 flex items-center gap-4"
                        >
                          <PaperClip className="px-1.5 w-[32px] h-[32px] min-w-[32px] min-h-[32px]" />
                          <a href={item.link} target="_blank" rel="noreferrer">
                            <span className="underline underline-offset-1">
                              {item.title}
                            </span>
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {solutionDetails.mediaData && (
                  <div className="pt-[120px] md:pt-[150px]">
                    {solutionDetails.technologyStack && (
                      <div className="pb-[120px] md:pb-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />
                    )}
                    <h2
                      style={{ fontFamily: "Onest-SemiBold" }}
                      className="text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px] text-white100"
                    >
                      Success Stories & Media
                    </h2>
                    <div className="grid grid-cols-1 md:flex md:flex-wrap xl:grid xl:grid-cols-4 gap-8 mt-[100px]">
                      {solutionDetails.mediaData.items.map((item) => (
                        <div
                          key={item.title}
                          className="text-base text-white100 flex items-center gap-4"
                        >
                          <PaperClip className="px-1.5 w-[32px] h-[32px] min-w-[32px] min-h-[32px]" />
                          <a href={item.link} target="_blank" rel="noreferrer">
                            <span className="underline underline-offset-1">
                              {item.title}
                            </span>
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </section>
            </div>
            <div className="max-w-[1120px] mx-auto">
              {(solutionDetails.mediaData || solutionDetails.codeData) && (
                <div className="mt-[120px] md:mt-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />
              )}

              <section className="mx-4 md:mx-6 xl:mx-0">
                <BookCallWith
                  className="mt-[100px]"
                  imgUrl={solutionDetails.contact.image}
                  name={solutionDetails.contact.name}
                  lnUrl={solutionDetails.contact.lnUrl}
                  calendlyCallPostfix={
                    solutionDetails.contact.calendlyCallPostfix
                  }
                  text={solutionDetails.contact.text || undefined}
                />
              </section>
            </div>
          </div>
        </div>
      ) : (
        <div className="max-w-[1120px] mx-auto pt-[80px]">
          <h1 className="pt-[100px] md:pt-[120px] text-white100 text-5xl">
            No solution found
          </h1>
        </div>
      )}
    </div>
  );
}

export default SolutionDetails;
