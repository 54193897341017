import { useState } from "react";
import "./historySlider.css";
import historySlide2016 from "../../assets/about/historySlide2016.png";
import historySlide2017 from "../../assets/about/historySlide2017.png";
import historySlide2019 from "../../assets/about/historySlide2019.png";
import historySlide2022 from "../../assets/about/historySlide2022.png";
import historySlide2023 from "../../assets/about/historySlide2023.png";
import historySlide2024 from "../../assets/about/historySlide2024.png";

const historyItems = [
  {
    id: "2016",
    content: (
      <div
        className="text-white100 h-full w-full flex flex-col items-start justify-end bg-center md:bg-right-top bg-no-repeat pb-[240px] md:pb-[116px]"
        style={{ backgroundImage: `url(${historySlide2016})` }}
      >
        <h1
          className="text-[40px] leading-[48px] md:text-5xl md:leading-[56px] xl:text-[64px] xl:leading-[72px]"
          style={{ fontFamily: "Onest-SemiBold" }}
        >
          2016
        </h1>
        <p
          className="text-sm md:text-lg xl:text-2xl mt-10 mb-0 max-w-[832px]"
          style={{ fontFamily: "Onest-Light" }}
        >
          Protofire was born as a Blockchain Spin-off of Altoros, a new Web3
          company of developers focused on helping ETH ecosytem projects with
          Front and Backend work.
        </p>
      </div>
    ),
  },
  {
    id: "2017",
    content: (
      <div
        className="text-white100 h-full w-full flex flex-col items-start md:items-end justify-end md:justify-start bg-center md:bg-left-top bg-no-repeat pb-[240px] md:pb-[116px]"
        style={{
          backgroundImage: `url(${historySlide2017})`,
        }}
      >
        <h1
          className="text-[40px] leading-[48px] md:text-5xl md:leading-[56px] xl:text-[64px] xl:leading-[72px] mt-[100px] max-w-[544px] w-full"
          style={{ fontFamily: "Onest-SemiBold" }}
        >
          2017
        </h1>
        <p
          className="text-sm md:text-lg xl:text-2xl mt-10 mb-0 max-w-[544px]"
          style={{ fontFamily: "Onest-Light" }}
        >
          First grants are received marking the start of Protofire’s first
          long-term partnerships with key web3 players such as Gnosis,
          Chainlink, Filecoin, and The Graph.
        </p>
      </div>
    ),
  },
  {
    id: "2019",
    content: (
      <div
        className="text-white100 h-full w-full flex flex-col items-start justify-end bg-center md:bg-right-top bg-no-repeat pb-[240px] md:pb-[116px]"
        style={{
          backgroundImage: `url(${historySlide2019})`,
        }}
      >
        <h1
          className="text-[40px] leading-[48px] md:text-5xl md:leading-[56px] xl:text-[64px] xl:leading-[72px]"
          style={{ fontFamily: "Onest-SemiBold" }}
        >
          2019
        </h1>
        <p
          className="text-sm md:text-lg xl:text-2xl mt-10 mb-0 max-w-[544px]"
          style={{ fontFamily: "Onest-Light" }}
        >
          Transition to DAO begins. Protofire realizes the importance of
          decentralization for contribution to Top-Tier projects in
          EVM-networks.
        </p>
        <p
          className="pb-0 pt-2 text-sm md:text-lg xl:text-2xl max-w-[544px]"
          style={{ fontFamily: "Onest-Light" }}
        >
          A new collaboration model is introduced helping Protofire a steady
          growth while gaining more and more expertise in Infrastructure (RPC,
          Indexing, Safe Multisig, Oracles) in blockchain development (SC &
          dapps, Protocol incubation) in DeFi (AMMs, Governance, CDPs,
          {/* Perpetuals, Insurance). */}
          Perpetuals).
        </p>
      </div>
    ),
  },
  {
    id: "2022",
    content: (
      <div
        className="text-white100 h-full w-full flex flex-col items-start justify-end md:items-end bg-center md:bg-left-top bg-no-repeat pb-[240px] md:pb-[116px]"
        style={{
          backgroundImage: `url(${historySlide2022})`,
        }}
      >
        <h1
          className="text-[40px] leading-[48px] md:text-5xl md:leading-[56px] xl:text-[64px] xl:leading-[72px] mt-[100px] max-w-[544px] w-full"
          style={{ fontFamily: "Onest-SemiBold" }}
        >
          2022
        </h1>
        <p
          className="text-sm md:text-lg xl:text-2xl mt-10 mb-0 max-w-[544px]"
          style={{ fontFamily: "Onest-Light" }}
        >
          Protofire starts its remarkable projects for Balancer (DeFi), API3
          (Infrastructure) and Simplestate (RWA).
        </p>
        <p
          className="pb-0 pt-2 text-sm md:text-lg xl:text-2xl max-w-[544px]"
          style={{ fontFamily: "Onest-Light" }}
        >
          New offers are shaped for DeFi environment to address the biggest
          challenges in the industry: TVL Growth, Compatible Oracles and Smart
          Contract Audits.
        </p>
      </div>
    ),
  },
  {
    id: "2023",
    content: (
      <div
        className="text-white100 h-full w-full flex flex-col md:items-end justify-end bg-center md:bg-left-top bg-no-repeat pb-[240px] md:pb-[116px]"
        style={{
          backgroundImage: `url(${historySlide2023})`,
        }}
      >
        <h1
          className="text-[40px] leading-[48px] md:text-5xl md:leading-[56px] xl:text-[64px] xl:leading-[72px] mt-[100px] max-w-[544px] w-full"
          style={{ fontFamily: "Onest-SemiBold" }}
        >
          2023
        </h1>
        <p
          className="text-sm md:text-lg xl:text-2xl mt-10 mb-0 max-w-[544px]"
          style={{ fontFamily: "Onest-Light" }}
        >
          Protofire receives grants from Ethereum Foundation and Optimism to
          build open-source Dev Tooling, now a must-have toolkit for developers.
        </p>
        <p
          className="pb-0 pt-2 text-sm md:text-lg xl:text-2xl max-w-[544px]"
          style={{ fontFamily: "Onest-Light" }}
        >
          Supporting Balancer to build ve8020 Governance Model Launchpad.
        </p>
        <p
          className="pb-0 pt-2 text-sm md:text-lg xl:text-2xl max-w-[544px]"
          style={{ fontFamily: "Onest-Light" }}
        >
          Grants received from Aave to build the Credit Delegation Marketplace.
        </p>
        {/* <p
          className="pb-0 pt-2 text-sm md:text-lg xl:text-2xl max-w-[544px]"
          style={{ fontFamily: "Onest-Light" }}
        >
          At_REPLACE_omica, a DeFi chain insurance protocol, is successfully launched by
          a group of Protofire team members and Web3 investors, first customers
          are signed.
        </p> */}
      </div>
    ),
  },
  {
    id: "2024",
    content: (
      <div
        className="text-white100 h-full w-full flex flex-col items-start justify-end bg-center md:bg-right-top bg-no-repeat pb-[240px] md:pb-[116px]"
        style={{
          backgroundImage: `url(${historySlide2024})`,
        }}
      >
        <h1
          className="text-[40px] leading-[48px] md:text-5xl md:leading-[56px] xl:text-[64px] xl:leading-[72px]"
          style={{ fontFamily: "Onest-SemiBold" }}
        >
          2024
        </h1>
        <p
          className="text-sm md:text-lg xl:text-2xl mt-10 mb-0 max-w-[544px]"
          style={{ fontFamily: "Onest-Light" }}
        >
          Back to growth: hiring 20+ people every quarter.
        </p>
        {/* <p
          className="pb-0 pt-2 text-sm md:text-lg xl:text-2xl max-w-[544px]"
          style={{ fontFamily: "Onest-Light" }}
        >
          At_REPLACE_omica reaches 40M+ TVL protected and provides first credit
          delegation lending.
        </p> */}
        <p
          className="pb-0 pt-2 text-sm md:text-lg xl:text-2xl max-w-[544px]"
          style={{ fontFamily: "Onest-Light" }}
        >
          New offers are rolled out tp cater for DeFi customers: DeFi protocol
          forks, {/* Staking Modules, */}Credit Lending Markets.
        </p>
        <p
          className="pb-0 pt-2 text-sm md:text-lg xl:text-2xl max-w-[544px]"
          style={{ fontFamily: "Onest-Light" }}
        >
          New customers are signed: Zeebu, Aethir, Truflation, and a couple
          dozen more under NDA.
        </p>
      </div>
    ),
  },
];

function HistorySlider() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToSlide = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="relative md:pt-8">
      <div className="overflow-hidden h-[762px] w-full relative">
        {historyItems.map((item, index) => (
          <div
            key={item.id}
            className={`slider-image ${index === currentIndex ? "active" : ""}`}
          >
            {item.content}
          </div>
        ))}
      </div>

      <div className="absolute bottom-0 w-full grid grid-cols-3 md:flex gap-6">
        {historyItems.map((item, index) => {
          const activeAddClass = index === currentIndex ? "active" : "";
          const preActiveAddClass =
            index === currentIndex - 1 ? "pre-active" : "";
          return (
            <div
              className="flex-1 hover:opacity-60 transition-opacity duration-500"
              onKeyDown={() => {}}
              tabIndex={0}
              onClick={() => goToSlide(index)}
              role="button"
              key={`${item.id}-btn`}
            >
              <p
                className={`text-sm text-white55 slider-button-state mt-0 mb-0.5 ${activeAddClass}`}
              >
                Selected now
              </p>
              <div
                className={`slider-button-year text-white100 text-2xl ${activeAddClass}`}
              >
                {item.id}
              </div>
              <div className="h-4 mt-4 flex items-center">
                <div
                  className={`border-4 rounded-full border-white40 slider-button-indicator ${activeAddClass}`}
                />
                <div
                  className={`slider-button-line-wrapper relative w-full ml-2 ${activeAddClass} ${preActiveAddClass}`}
                  // style={{
                  //   background:
                  //     index < currentIndex
                  //       ? "linear-gradient(90deg, rgba(241, 248, 255, 0.4) 0%, #FF8800 100%)"
                  //       : "linear-gradient(90deg, #FF8800 0%, rgba(241, 248, 255, 0.4) 100%)",
                  // }}
                >
                  <div
                    className={`slider-button-line ${activeAddClass} ${preActiveAddClass}`}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default HistorySlider;
