/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
import { useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import axios from "axios";

import dividerLine from "../assets/home/divider-line.svg";
import OfferPane from "../common/offersSelectorControl/OfferPane";
import {
  firstTriggerItems,
  offerItems,
  secondTriggerItems,
} from "../common/offersSelectorControl/data";
import ToggleTag from "../common/offersSelectorControl/ToggleTag";
import Button from "../common/button/Button";
import bookCalendlyCall from "../../utils/bookCalendlyCall";
import { DefaultCallendlyName } from "../../utils/utils";

function OffersSelector({
  isFullyVisible = false,
  firstTrigger = "",
}: {
  isFullyVisible?: boolean;
  firstTrigger?: string;
}) {
  const isMDScreen = useMediaQuery({
    query: "(max-width: 1439px) and (min-width: 834px)",
  });
  const [selectedFirstTrigger, setSelectedFirstTrigger] =
    useState<string>(firstTrigger);
  const [selectedSecondTriggerItems, setSelectedSecondTriggerItems] = useState<
    string[]
  >([]);
  const sliceSize = isMDScreen ? 6 : 8;

  useEffect(() => {
    setSelectedFirstTrigger(firstTrigger);
  }, [firstTrigger]);

  const [selectedOffers, setSelectedOffers] = useState<string[]>([]);

  const toggleArrayItems = (
    array: string[],
    setter: React.Dispatch<React.SetStateAction<string[]>>,
    item: string
  ) => {
    if (array.includes(item)) {
      array.splice(array.indexOf(item), 1);
    } else {
      array.push(item);
    }
    setter(array);
  };

  const handleCalendlyBook = useCallback(
    (event) => {
      if (event.data.event === "calendly.event_scheduled") {
        const message = JSON.stringify(
          selectedOffers.length
            ? {
                text: `A Calendly meeting has been created from Filtering: \n
                Solutions: ${selectedOffers.join(", ")} \n
                ${DefaultCallendlyName} \n
                From page ${window.location.href}`,
              }
            : {
                text: `A Calendly meeting has been created with: \n
                ${DefaultCallendlyName} \n
                From page ${window.location.href}`,
              }
        );
        axios.post(`${process.env.REACT_APP_WEBHOOK_URL}`, message, {
          withCredentials: false,
          transformRequest: [
            (data, headers: any) => {
              delete headers.post["Content-Type"];
              return data;
            },
          ],
        });
      }
    },
    [selectedOffers]
  );
  useEffect(() => {
    window.addEventListener("message", handleCalendlyBook);
    return () => {
      window.removeEventListener("message", handleCalendlyBook);
    };
  }, [handleCalendlyBook]);

  return (
    <div
      className="bg-white5 rounded-xl p-4 md:p-6 xl:p-8 mt-[56px]"
      style={{ backdropFilter: "blur(56px)" }}
    >
      <div className="flex flex-col xl:flex-row">
        <div className="flex-1 xl:mr-6">
          <p
            className="my-0 text-base text-white100"
            style={{ fontFamily: "Onest-SemiBold" }}
          >
            I am:{" "}
            <span
              className="text-sm text-white55"
              style={{ fontFamily: "Onest" }}
            >
              (Choose one option)
            </span>
          </p>
          <div>
            {firstTriggerItems.map((item) => (
              <ToggleTag
                clasName="mr-2 mt-2"
                key={item}
                onClick={() => {
                  if (item !== selectedFirstTrigger) {
                    setSelectedFirstTrigger(item);
                  }
                }}
                checked={item === selectedFirstTrigger}
                text={item}
              />
            ))}
          </div>
        </div>

        <div className="flex-1 mt-8 xl:mt-0">
          <p
            className={`my-0 text-base ${
              !selectedFirstTrigger ? "text-white10" : "text-white100"
            }`}
            style={{ fontFamily: "Onest-SemiBold" }}
          >
            My ultimate business outcome:
          </p>
          <div>
            {secondTriggerItems.map((item) => (
              <ToggleTag
                clasName="mr-2 mt-2"
                key={item}
                onClick={() => {
                  toggleArrayItems(
                    [...selectedSecondTriggerItems],
                    setSelectedSecondTriggerItems,
                    item
                  );
                }}
                checked={selectedSecondTriggerItems.includes(item)}
                text={item}
                disabled={!selectedFirstTrigger}
              />
            ))}
          </div>
        </div>
      </div>

      <div
        className="h-[1px] mt-8"
        style={{
          background: `url(${dividerLine})`,
        }}
      />
      <h3
        className="mt-8 text-2xl text-white100"
        style={{ fontFamily: "Onest-SemiBold" }}
      >
        Solutions to match you and your goals:
      </h3>
      <p className="mt-2 mb-0 text-base text-white55">
        Feel free either to explore them through “Learn more” links or select
        and book a free consultation call.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4 w-full mt-8">
        {(selectedFirstTrigger
          ? offerItems.filter((item) => {
              if (selectedSecondTriggerItems.length) {
                return item.triggerItemsStep2?.[selectedFirstTrigger]?.some(
                  (selectedItem) =>
                    selectedSecondTriggerItems.includes(selectedItem)
                );
              }
              return item.triggerItemsStep1?.includes(selectedFirstTrigger);
            })
          : offerItems
        )
          .slice(0, isFullyVisible ? offerItems.length : sliceSize)
          .map((item) => (
            <OfferPane
              key={item.title}
              onSelect={() => {
                toggleArrayItems(
                  [...selectedOffers],
                  setSelectedOffers,
                  item.title
                );
              }}
              checked={selectedOffers.includes(item.title)}
              title={item.title}
              link={item.link}
              description={item.description}
            />
          ))}
      </div>
      <div className="flex flex-col-reverse md:flex-row mt-8 md:items-center">
        <Button size="lg" onClick={() => bookCalendlyCall()}>
          Book a call
        </Button>
        <p className="my-0 py-0 mb-4 md:mb-0 ml-0 md:ml-8 text-white55 text-sm">
          Web3 is growing fast, book a free consultation call right away!
        </p>
      </div>
    </div>
  );
}

export default OffersSelector;
