import { ReactComponent as Github } from "../../assets/images/github.svg";
import { ReactComponent as Discord } from "../../assets/images/discord.svg";

function SocialMedia() {
  return (
    <>
      <a
        href="https://github.com/protofire/solhint"
        target="_blank"
        className="text-blue100 py-2 sm:px-2 lg:px-3 hover:scale-150 "
        rel="noreferrer"
      >
        <Github />
      </a>
      <a
        href="https://discord.com/invite/4TYGq3zpjs"
        target="_blank"
        rel="noreferrer"
        className="text-blue100 py-2 sm:px-2 lg:px-3 hover:scale-150"
      >
        <Discord />
      </a>
    </>
  );
}

export default SocialMedia;
