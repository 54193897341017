/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../assets/home/arrow-right.svg";
import Button from "../button/Button";
import "./cards.css";

type StudyProjectCardProps = {
  imgSrc: string;
  title: string;
  text: string;
  tagList: string[];
  link: string;
  className?: string;
};

function TagLabel({ children }) {
  return (
    <div className="text-[10px] leading-[16px] px-3 py-2 text-white100 bg-black40 rounded-2xl mr-2 mt-1.5">
      {children}
    </div>
  );
}

function StudyProjectCard({
  imgSrc,
  title,
  text,
  tagList,
  className,
  link,
}: StudyProjectCardProps) {
  return (
    <div
      className={`flex flex-col rounded-lg bg-white5 w-full ${className}`}
      style={{ backdropFilter: "blur(160px)" }}
    >
      <div
        style={{ backgroundImage: `url(${imgSrc})` }}
        className="h-[200px] w-full flex items-end bg-cover bg-center bg-no-repeat rounded-t-lg px-6 pb-2 flex-wrap"
      >
        <div className="flex flex-wrap-reverse">
          {tagList.slice(0, 3).map((item) => (
            <TagLabel key={item}>{item}</TagLabel>
          ))}
        </div>
      </div>
      <div className="p-6 pt-4 flex flex-col justify-between flex-1">
        <h3 className="text-white100 text-[32px] leading-[40px]">{title}</h3>
        <div>
          <p className="text-white55 text-sm mt-2 mb-0 overflow-hidden stydy-project-card-text">
            {text}
          </p>
          <Link to={link} className="inline-block mt-6">
            <Button
              variant="outlined"
              className="w-full xl:w-auto py-[11px] px-[28px] flex items-center justify-center"
            >
              <ArrowRight />
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default StudyProjectCard;
