import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  return (
    <div className="max-w-[1120px] mx-auto pt-[80px]">
      <Helmet>
        <title>Privacy Policy | Protofire</title>
        <meta
          name="description"
          content="Learn how Protofire safeguards your personal information. Expert blockchain development, DeFi solutions, and smart contract integration. Secure, scalable, and innovative."
        />
        <meta
          name="keywords"
          content="DeFi development services, Web3 integration solutions, Custom blockchain applications, Smart contract engineering, Decentralized protocol design"
        />
      </Helmet>
      <section className="mx-4 md:mx-6 xl:mx-0 text-white100 md:max-w-[832px]">
        <div className="xl:max-w-[754px] pt-[100px] md:pt-[120px]">
          <h1
            className="text-[32px] leading-[40px] md:text-5xl md:leading-[56px] xl:text-[64px] xl:leading-[72px]"
            style={{ fontFamily: "Onest-SemiBold" }}
          >
            Privacy Policy
          </h1>
        </div>
        <div className="mt-[100px] md:mt-[120px] xl:mt-[200px]">
          <p className="my-0 text-lg">
            Welcome to Protofire (“Company”, “we”, “our”, “us”). We respect your
            privacy and are committed to protecting the personal information you
            provide to us. This Privacy Policy explains how we collect, use, and
            share your information when you visit our website protofire.io and
            use our services, including our cryptocurrency-related services (the
            “Services”).
          </p>
          <p className="mt-8">
            By accessing or using our Services, you agree to this Privacy
            Policy. If you do not agree with this Privacy Policy, please do not
            use our Services.
          </p>
        </div>
      </section>

      <section className="mx-4 md:mx-6 xl:mx-0 text-white100 md:max-w-[832px]">
        <h2 className="mt-[100px] text-2xl">1. Information We Collect</h2>
        <p className="mt-10 mb-0 text-lg">
          We collect the following types of information when you use our
          Services:
        </p>

        <h3 className="mt-[100px] text-lg">1.1. Personal Information</h3>
        <p className="mt-10">
          Personal Information refers to information that can be used to
          identify you, such as:
        </p>
        <ul className="mt-10 text-base text-white55 pl-6">
          <li className="list-disc">Name</li>
          <li className="list-disc">Email address</li>
          <li className="list-disc">Wallet addresses</li>
          <li className="list-disc">IP address</li>
          <li className="list-disc">Payment information (if applicable)</li>
        </ul>
        <p className="mt-10">We collect Personal Information when you:</p>
        <ul className="mt-10 text-base text-white55 pl-6">
          <li className="list-disc">Register for an account</li>
          <li className="list-disc">Use our Services</li>
          <li className="list-disc">Contact us for support</li>
        </ul>

        <h3 className="mt-[100px] text-lg">1.2. Non-Personal Information</h3>
        <p className="mt-10">
          We may also collect non-personal information, which does not directly
          identify you, such as:
        </p>
        <ul className="mt-10 text-base text-white55 pl-6">
          <li className="list-disc">Browser type</li>
          <li className="list-disc">Operating system</li>
          <li className="list-disc">
            Usage data, including the time spent on our website
          </li>
        </ul>
      </section>

      <section className="mx-4 md:mx-6 xl:mx-0 text-white100 md:max-w-[832px]">
        <h2 className="mt-[100px] text-2xl">2. How We Use Your Information</h2>
        <p className="mt-10 mb-0 text-lg">
          We collect the following types of information when you use our
          Services:
        </p>
        <ul className="mt-10 text-base text-white55 pl-6">
          <li className="list-disc">Provide and maintain our Services</li>
          <li className="list-disc">Process transactions securely</li>
          <li className="list-disc">Improve and personalize user experience</li>
          <li className="list-disc">
            Communicate with you regarding updates, promotions, and security
            alerts
          </li>
          <li className="list-disc">Comply with legal obligations</li>
        </ul>
      </section>

      <section className="mx-4 md:mx-6 xl:mx-0 text-white100 md:max-w-[832px]">
        <h2 className="mt-[100px] text-2xl">3. Sharing Your Information</h2>
        <p className="mt-10 mb-0 text-lg">
          We do not share your Personal Information with third parties, except
          in the following circumstances:
        </p>
        <ul className="mt-10 text-base pl-6">
          <li className="list-disc">
            With service providers:{" "}
            <span className="text-white55">
              We may share your information with trusted third-party service
              providers who assist us in operating our website and Services.
            </span>
          </li>
          <li className="list-disc">
            For legal reasons:{" "}
            <span className="text-white55">
              We may disclose your information if required to do so by law or in
              response to legal requests or orders.
            </span>
          </li>
        </ul>
      </section>

      <section className="mx-4 md:mx-6 xl:mx-0 text-white100 md:max-w-[832px]">
        <h2 className="mt-[100px] text-2xl">4. Security of Your Information</h2>
        <p className="mt-10 mb-0 text-lg">
          We take appropriate measures to protect your Personal Information from
          unauthorized access, disclosure, or destruction. However, please note
          that no method of transmitting or storing data is completely secure,
          and we cannot guarantee absolute security.
        </p>
      </section>

      <section className="mx-4 md:mx-6 xl:mx-0 text-white100 md:max-w-[832px]">
        <h2 className="mt-[100px] text-2xl">5. Your Rights</h2>
        <p className="mt-10 mb-0 text-lg">
          Depending on your location, you may have the following rights
          regarding your Personal Information:
        </p>
        <ul className="mt-10 text-base text-white55 pl-6">
          <li className="list-disc">
            The right to access and receive a copy of your Personal Information.
          </li>
          <li className="list-disc">
            The right to request correction or deletion of your Personal
            Information.
          </li>
          <li className="list-disc">
            The right to object to or restrict certain types of data processing.
          </li>
          <li className="list-disc">
            The right to withdraw consent at any time if we rely on your consent
            to process your Personal Information.
          </li>
        </ul>
      </section>

      <section className="mx-4 md:mx-6 xl:mx-0 text-white100 md:max-w-[832px]">
        <h2 className="mt-[100px] text-2xl">6. Data Retention</h2>
        <p className="mt-10 mb-0 text-lg">
          We will retain your Personal Information for as long as it is
          necessary to fulfill the purposes outlined in this Privacy Policy,
          unless a longer retention period is required or permitted by law.
        </p>
      </section>

      <section className="mx-4 md:mx-6 xl:mx-0 text-white100 md:max-w-[832px]">
        <h2 className="mt-[100px] text-2xl">7. Cookies</h2>
        <p className="mt-10 mb-0 text-lg">
          We use cookies and similar tracking technologies to collect and store
          information when you interact with our website. You can manage cookie
          preferences through your browser settings.
        </p>
      </section>

      <section className="mx-4 md:mx-6 xl:mx-0 text-white100 md:max-w-[832px]">
        <h2 className="mt-[100px] text-2xl">8. Children’s Privacy</h2>
        <p className="mt-10 mb-0 text-lg">
          Our Services are not intended for children under the age of 18, and we
          do not knowingly collect Personal Information from children.
        </p>
      </section>

      <section className="mx-4 md:mx-6 xl:mx-0 text-white100 md:max-w-[832px]">
        <h2 className="mt-[100px] text-2xl">
          9. Changes to This Privacy Policy
        </h2>
        <p className="mt-10 mb-0 text-lg">
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page, and the “Last updated” date will be revised.
          We encourage you to review this Privacy Policy periodically.
        </p>
      </section>

      <section className="mx-4 md:mx-6 xl:mx-0 text-white100 md:max-w-[832px] mb-[200px]">
        <h2 className="mt-[100px] text-2xl">10. Contact Us</h2>
        <p className="mt-10 mb-0 text-lg">
          If you have any questions about this Privacy Policy or our data
          practices, please contact us at:
        </p>
        <ul className="mt-10 text-base pl-6 xl:max-w-">
          <li className="list-disc">
            Email:{" "}
            <a
              href="mailto:dpo@altoros.com"
              className="underline underline-offset-1"
            >
              {" "}
              dpo@altoros.com
            </a>
          </li>
          <li className="list-disc">
            Address: Altoros LLC, 1607 Ponce de Leon Avenue, GM-06, San Juan,
            PR, 00909
          </li>
        </ul>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
