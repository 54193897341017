import prot1 from "../../assets/images/ourTeam/prot1.jpeg";
import prot2 from "../../assets/images/ourTeam/prot2.jpg";
import prot3 from "../../assets/images/ourTeam/prot3.jpg";
import prot4 from "../../assets/images/ourTeam/prot4.jpg";
import prot5 from "../../assets/images/ourTeam/prot5.jpg";
import prot6 from "../../assets/images/ourTeam/prot6.jpg";
import prot7 from "../../assets/images/ourTeam/prot7.jpg";
import prot8 from "../../assets/images/ourTeam/prot8.jpg";
import prot9 from "../../assets/images/ourTeam/prot9.jpg";
import prot10 from "../../assets/images/ourTeam/prot10.jpg";
import prot11 from "../../assets/images/ourTeam/prot11.jpg";
import prot12 from "../../assets/images/ourTeam/prot12.jpg";
import prot13 from "../../assets/images/ourTeam/prot13.jpg";
import prot14 from "../../assets/images/ourTeam/prot14.jpg";
import prot15 from "../../assets/images/ourTeam/prot15.jpg";
import prot16 from "../../assets/images/ourTeam/prot16.jpg";
import prot17 from "../../assets/images/ourTeam/prot17.jpg";
import prot18 from "../../assets/images/ourTeam/prot18.jpg";
import prot19 from "../../assets/images/ourTeam/prot19.jpg";
import prot20 from "../../assets/images/ourTeam/prot20.jpg";
import prot21 from "../../assets/images/ourTeam/prot21.jpg";
import prot22 from "../../assets/images/ourTeam/prot22.jpg";

// eslint-disable-next-line import/prefer-default-export
export const content = [
  {
    image: prot1,
    imageAlt: "prot1",
    key: 1,
  },
  { image: prot2, imageAlt: "prot2", key: 2 },
  { image: prot3, imageAlt: "prot3", key: 3 },
  { image: prot4, imageAlt: "prot4", key: 4 },
  { image: prot5, imageAlt: "prot5", key: 5 },
  { image: prot6, imageAlt: "prot6", key: 6 },
  { image: prot7, imageAlt: "prot7", key: 7 },
  { image: prot8, imageAlt: "prot8", key: 8 },
  { image: prot9, imageAlt: "prot9", key: 9 },
  { image: prot10, imageAlt: "prot10", key: 10 },
  { image: prot11, imageAlt: "prot11", key: 11 },
  { image: prot12, imageAlt: "prot12", key: 12 },
  { image: prot13, imageAlt: "prot13", key: 13 },
  { image: prot14, imageAlt: "prot14", key: 14 },
  { image: prot15, imageAlt: "prot15", key: 15 },
  { image: prot16, imageAlt: "prot16", key: 16 },
  { image: prot17, imageAlt: "prot17", key: 17 },
  { image: prot18, imageAlt: "prot18", key: 18 },
  { image: prot19, imageAlt: "prot19", key: 19 },
  { image: prot20, imageAlt: "prot20", key: 20 },
  { image: prot21, imageAlt: "prot21", key: 21 },
  { image: prot22, imageAlt: "prot22", key: 22 },
];
