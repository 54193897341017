import { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as ChevronRight } from "../../assets/images/chevronRight.svg";
import { content } from "./content";
import Button from "../button/Button";

const getItemXlGridColSpan = (index: number): string => {
  switch (index % 6) {
    case 0:
    case 2:
      return "xl:col-span-3";
    case 1:
      return "xl:col-span-6";
    default:
      return "xl:col-span-4";
  }
};

function ProtofireCarousel() {
  const isMobile = useMediaQuery({ query: "(max-width: 833px)" });

  const sizePerPage = useMemo(() => {
    return isMobile ? 2 : 6;
  }, [isMobile]);

  const [currentPage, setCurrentPage] = useState(0);
  const isNextPageExists = (currentPage + 1) * sizePerPage < content.length;
  const isPrevPageExists = currentPage > 0;

  const handleNextPage = () => {
    if (isNextPageExists) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (isPrevPageExists) {
      setCurrentPage(currentPage - 1);
    }
  };

  const contentItems = useMemo(() => {
    return content.slice(
      currentPage * sizePerPage,
      (currentPage + 1) * sizePerPage
    );
  }, [currentPage, sizePerPage]);

  useEffect(() => {
    if (
      content.length &&
      !content[currentPage * sizePerPage] &&
      currentPage > 0
    ) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, sizePerPage]);

  return (
    <div className="mt-[100px] m-auto mb-10 xl:block md:block">
      <div className="flex md:grid md:grid-rows-2 grid-cols-12 gap-2">
        {contentItems.map((item, index) => (
          <div
            key={item.imageAlt}
            style={{
              backgroundImage: `url(${item.image})`,
            }}
            className={`max-w-[calc(50%-8px)] bg-no-repeat bg-cover bg-center w-full md:max-w-full h-[240px] md:h-[320px] col-span-4 rounded-lg ${getItemXlGridColSpan(
              index
            )} `}
          />
        ))}
      </div>
      <div className="flex justify-center gap-2 mt-[50px]">
        <Button
          onClick={handlePrevPage}
          disabled={!isPrevPageExists}
          variant="outlined"
          className="h-[40px] w-[40px] flex justify-center items-center"
        >
          <ChevronRight className="rotate-180" />
        </Button>
        <Button
          onClick={handleNextPage}
          disabled={!isNextPageExists}
          variant="outlined"
          className="h-[40px] w-[40px] flex justify-center items-center"
        >
          <ChevronRight />
        </Button>
      </div>
    </div>
  );
}

export default ProtofireCarousel;
