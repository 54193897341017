import renat from "../../assets/team/renat.png";
import andrei from "../../assets/team/andrei.png";
import cristian from "../../assets/team/cristian.png";
import diego from "../../assets/team/diego.png";
import ivan from "../../assets/team/ivan.png";
import luis from "../../assets/team/luis.png";
import arsenii from "../../assets/team/arsenii.png";
import alejandro from "../../assets/team/alejandro.png";
import anastasiia from "../../assets/team/anastasiia.png";
import anderson from "../../assets/team/anderson.png";
import kirill from "../../assets/team/kirill.png";
import matt from "../../assets/team/matt.png";
import yulia from "../../assets/team/yuliya.png";

// eslint-disable-next-line import/prefer-default-export
export const content = [
  {
    name: "Renat Khasanshyn",
    role: "Chief Executive Officer",
    linkedIn: "https://www.linkedin.com/in/renatco/",
    image: renat,
    imageAlt: "Renat Khasanshyn, Co-Founder of Protofire",
    key: 1,
  },
  {
    name: "Andrei Yurkevich",
    role: "Founding Member",
    linkedIn: "https://www.linkedin.com/in/andreiyurkevich/",
    image: andrei,
    imageAlt: "Andrei Yurkevich, Co-Founder of Protofire",
    key: 2,
  },
  {
    name: "Cristian Malfesi",
    role: "Director of Engineering",
    linkedIn: "https://www.linkedin.com/in/cristian-malfesi/",
    image: cristian,
    imageAlt: "Cristian Malfesi, Director of Engineering at Protofire",
    key: 3,
  },
  {
    name: "Alejandro Losa",
    role: "Blockchain Solution Architect",
    linkedIn: "https://www.linkedin.com/in/alejandrolosa/",
    image: alejandro,
    imageAlt: "Alejandro Losa",
    key: 4,
  },
  {
    name: "Ivan Bandaryk",
    role: "Field CTO",
    linkedIn: "https://www.linkedin.com/in/ivan-bandaryk/",
    image: ivan,
    imageAlt: "Ivan Bandaryk, Field CTO at Protofire",
    key: 5,
  },
  {
    name: "Arsenii Petrovich",
    role: "Field CTO",
    linkedIn: "https://www.linkedin.com/in/arsenii-petrovich/",
    image: arsenii,
    imageAlt: "Arsenii Petrovich, Field CTO at Protofire",
    key: 6,
  },
  {
    name: "Luis Medeiros",
    role: "Field CTO",
    linkedIn: "https://www.linkedin.com/in/lvgmedeiros/",
    image: luis,
    imageAlt: "Luis Medeiros, Field CTO at Protofire",
    key: 7,
  },
  {
    name: "Diego Torres",
    role: "Field CTO",
    linkedIn: "https://www.linkedin.com/in/diego-torres-borda-94b70912/",
    image: diego,
    imageAlt: "Diego Torres, Project Manager at Protofire",
    key: 8,
  },
  {
    name: "Anastasiia Osypova",
    role: "Head of People",
    linkedIn: "https://www.linkedin.com/in/anastasiia-osypova-43567448/ ",
    image: anastasiia,
    imageAlt: "Anastasiia Osypova",
    key: 9,
  },
  {
    name: "Anderson Da Silva",
    role: "Head of Engineering",
    linkedIn: "https://www.linkedin.com/in/andersonsilva78/",
    image: anderson,
    imageAlt: "Anderson Da Silva",
    key: 10,
  },
  {
    name: "Kirill Artsymenia",
    role: "Head of Marketing",
    linkedIn: "https://www.linkedin.com/in/kirillartsymenia/ ",
    image: kirill,
    imageAlt: "Kirill Artsymenia",
    key: 11,
  },
  {
    name: "Matt Radominski",
    role: "Sales Representative",
    linkedIn: "https://www.linkedin.com/in/mradominski/ ",
    image: matt,
    imageAlt: "Matt Radominski",
    key: 12,
  },
  {
    name: "Yuliya Murashko",
    role: "Finance Director",
    linkedIn: "",
    image: yulia,
    imageAlt: "Yuliya Murashko",
    key: 13,
  },
];
