import { ReactComponent as Global } from "../assets/career/global.svg";
import { ReactComponent as Growth } from "../assets/career/career_growth.svg";
import { ReactComponent as Education } from "../assets/career/education.svg";
import { ReactComponent as Feedback } from "../assets/career/feedback.svg";
import { ReactComponent as Profit } from "../assets/career/profitShares.svg";
import { ReactComponent as Home } from "../assets/career/home.svg";
import { ReactComponent as PaidTime } from "../assets/career/paidTime.svg";
import { ReactComponent as Flexible } from "../assets/career/flexible.svg";
import { ReactComponent as Gift } from "../assets/career/gift.svg";
import { ReactComponent as Referral } from "../assets/career/referral.svg";
import { ReactComponent as Language } from "../assets/career/language.svg";
import { ReactComponent as Physical } from "../assets/career/physical.svg";

// eslint-disable-next-line import/prefer-default-export
export const content = [
  {
    icon: Global,
    desc: "Global team and startup environment",
  },
  {
    icon: Flexible,
    desc: "Flexible remote-first policy",
  },
  {
    icon: Education,
    desc: "Various projects for you to make an impact",
  },
  {
    icon: Feedback,
    desc: "Regular feedback",
  },
  {
    icon: Profit,
    desc: "Profit shares to each team member",
  },
  {
    icon: Home,
    desc: "Home office stipend to suit your needs",
  },
  {
    icon: PaidTime,
    desc: "30 days of paid time off",
  },
  {
    icon: Physical,
    desc: "Physical and mental health support program",
  },
  {
    icon: Growth,
    desc: "Career development and learning opportunities",
  },
  {
    icon: Referral,
    desc: "Referral program",
  },
  {
    icon: Language,
    desc: "Language classes for everyone",
  },
  {
    icon: Gift,
    desc: "Protofire branded gifts",
  },
];
